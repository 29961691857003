import React, { Component } from 'react';
import AuthenticationService from '../general/AuthenticationService.js';

class UsersComponent extends Component {
    constructor(props) {
        console.log("UsersComponent Constructor")
        super(props)

        this.state = {
            ...props,
        }

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("READ_USUARIOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        this.props.refreshElements();
    }


    render() {

        let counter = 0;

        return (
            <div id="main">
                {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                <div className="panel">
                    <div className="titles">
                        <div className="tool-btn-agregar">
                            <button className="btn btn-agregar" onClick={this.props.addElementClicked}><span className="icon-add-solid"></span> <span className="icon-user"></span></button>
                        </div>
                        <h1>Usuarios</h1>
                    </div>

                    <div className="container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th >Nombre Usuario</th>
                                    <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                    <th className="icoSize"><span className="icon-trash"></span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.elements && this.state.elements.map(
                                        element =>
                                            <tr key={element.id}>
                                                <td>{++counter}</td>
                                                <td>{element.userName}</td>
                                                <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.userName)}><span className="icon-edit-pencil"></span>    </button></td>
                                                <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.userName)}><span className="icon-trash"></span></button></td>
                                            </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default UsersComponent