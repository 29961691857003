import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'
import { Link } from 'react-router-dom'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'

const PREFIX_URL = 'http://localhost:3000/';

const images = [
    {
        original: PREFIX_URL + 'img/uno.jpg',
        thumbnail: PREFIX_URL + 'img/mini/uno.jpg'
    },
    {
        original: PREFIX_URL + 'img/dos.jpg',
        thumbnail: PREFIX_URL + 'img/mini/dos.jpg'
    },
    {
        original: PREFIX_URL + 'img/tres.jpg',
        thumbnail: PREFIX_URL + 'img/mini/tres.jpg'
    },
    {
        original: PREFIX_URL + 'img/cuatro.jpg',
        thumbnail: PREFIX_URL + 'img/mini/cuatro.jpg'
    },
]


class GaleriaDomicilioComponent extends Component {
    constructor(props) {
        console.log("GaleriaDomicilioComponent Constructor")
        super(props)

        this.state = {
            id: this.props.match.params.id,
            portadaFile: [],
            portadaData: '',
            fotografiasGalleryFileArray:[],
            fotografiasGalleryDataArray:[],
            loading:false,
            imageCount:'',
            images:[]
        }

    }

    componentDidMount() {
        console.log('GaleriaDomicilioComponent componentDidMount')
        console.log(this.state)
        // this.props.refreshElements();

        ElementsDataService.retrieveElement("domicilios/galeria", this.state.id)
        .then(response => {

            console.log(response);

            let imageCount = response.data.imageCount;

            this.setState({    
                imageCount            
            })

            if(imageCount > 0){
                for(let i = 0; i < imageCount; i++){
                    ElementsDataService.getImageFile(`?fileName=Domicilios/domicilio_${this.state.id}/galeria/${i}.jpg`)
                    .then(response =>{

                        //console.log("image " + i + "received" + response);

                        let images = this.state.images; 


                        images[i]= {
                            original:URL.createObjectURL(response),
                            thumbnail:URL.createObjectURL(response)
                        }
                
                        this.setState({
                            images
                        })
                    
                    })
                }
            }


        } )  
    }
    
    render() {

        console.log("render DomicilioComponent", this.state)


        return (
            <div id="main">
                <div className="panel"> 

                    
                    <div className="titles">
                        <div className="tool-btn-agregar">
                                <button className="btn btn-agregar" onClick={() =>  this.props.history.push(`/domicilios/galeria/editar/1`)}><span className="icon-add-solid"> </span><span className="icon-photo"></span></button>
                        </div>
                        <h1>Domicilio</h1>
                    </div>
                       
                   <ImageGallery items={this.state.images} />
                
                </div>
                
            </div>
        )
    }
}

export default GaleriaDomicilioComponent