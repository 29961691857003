import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import AuthenticationService from '../general/AuthenticationService.js'



class RevisionPlazaMensual extends Component {



    constructor(props) {
        super(props)


        this.state = {
            id: this.props.match.params.id,
            revisionDtoArray: [],
            allSociedades: [],
            allClientes: [],
            clientes: [],
            cliente: '',
            loading: false,
            declaraciones: [],
            responsablesContabilidadArray: [],
            auxiliaresContablesArray: [],
            responsablesContabilidad: [],
            auxiliaresContables: [],
            mostrarVendidasNoRevision: false,
            mostrarSolamenteSociedadesConPendientes: false,
            ejercicio: new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear(),//año
            periodo: this.arrayMeses[new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1]//mes

        }

        this.getDeclaracionesByPeriodoEjercicio = this.getDeclaracionesByPeriodoEjercicio.bind(this)


    }

    arrayMeses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];

    getDeclaracionesByPeriodoEjercicio(clientes, ejercicio, periodo,
        auxiliaresContables, responsablesContabilidad, sociedades) {


        let clientesIds = []

        clientes && clientes.forEach(cliente => {
            clientesIds.push(cliente.id)
        })

        console.log("clientesIds", clientesIds)

        let sociedadesIds = []

        sociedades && sociedades.forEach(sociedad => {
            sociedadesIds.push(sociedad.id)
        })

        console.log("sociedadesIds", sociedadesIds)

        let auxiliaresContablesCodes = []

        auxiliaresContables.forEach(auxiliarContable => {
            auxiliaresContablesCodes.push(auxiliarContable.codigo)
        })

        console.log("auxiliaresContablesCodes", auxiliaresContablesCodes)

        let responsablesContabilidadCodes = []

        responsablesContabilidad.forEach(responsableContabilidad => {
            responsablesContabilidadCodes.push(responsableContabilidad.codigo)
        })

        console.log("responsablesContabilidadCodes", responsablesContabilidadCodes)

        if (clientesIds.length === 0 && //No se ha seleccionado ningun filtro, no hacer nada
            sociedadesIds.length === 0 &&
            auxiliaresContablesCodes.length === 0 &&
            responsablesContabilidadCodes.length === 0
        ) {
            this.setState({
                declaraciones: [], loading: false, clientes, ejercicio, periodo,
                auxiliaresContables, responsablesContabilidad, sociedades
            })
            return
        }


        const formData = new FormData();
        formData.append('clientesIds', clientesIds)
        formData.append('sociedadesIds', sociedadesIds)
        formData.append('auxiliaresContablesCodes', auxiliaresContablesCodes)
        formData.append('responsablesContabilidadCodes', responsablesContabilidadCodes)

        this.setState({ loading: true })

        ElementsDataService.retrieveElementPost(`cfdis/declaraciones/${ejercicio}/${periodo}`, formData)
            .then(response => {

                console.log("DECLARACIONES")

                console.log(response)

                this.setState({
                    declaraciones: response.data, loading: false, clientes, ejercicio, periodo,
                    auxiliaresContables, responsablesContabilidad, sociedades
                })
            })


    }


    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("READ_CONTABILIDAD")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }



        ElementsDataService.retrieveAllElements("clientes")
            .then(
                response => {
                    console.log("retrieveAllClientesResponse", response.data);
                    this.setState({ allClientes: [{ id: 0, nombreCliente: "TODOS" }, ...response.data] })
                })

        ElementsDataService.retrieveAllElements("auxiliaresContables")
            .then(
                response => {
                    console.log("retrieveAllAuxiliaresContablesResponse", response.data);
                    this.setState({ auxiliaresContablesArray: response.data })
                })

        ElementsDataService.retrieveAllElements("responsablesContabilidad")
            .then(
                response => {
                    console.log("retrieveAllResponsablesContabilidadResponse", response.data);
                    this.setState({ responsablesContabilidadArray: response.data })
                })

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    // console.log("retrieveAllSociedadesResponse",response.data);
                    this.setState({ allSociedades: response.data })
                })




    }



    render() {

        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {


            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }


            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };

        function datediff(first, second) {
            // Take the difference between the dates and divide by milliseconds per day.
            // Round to nearest whole number to deal with DST.
            return Math.round((first - second) / (1000 * 60 * 60 * 24));
        }






        console.log("render RevisionPlazaMensual")
        console.log("RevisionPlazaMensual state", this.state)
        let {
            revisionDtoArray,
            allSociedades,
            allClientes,
            loading,
            declaraciones,
            ejercicio,
            periodo,
            clientes,
            responsablesContabilidadArray,
            auxiliaresContablesArray,
            sociedades,
            responsablesContabilidad,
            auxiliaresContables,
            mostrarVendidasNoRevision,
            mostrarSolamenteSociedadesConPendientes,
        } = this.state

        let periodoNumber = this.arrayMeses.indexOf(periodo);


        let currentDeclaracionesDate = new Date(ejercicio, periodoNumber, new Date().getDate());

        console.log("currentDeclaracionesDate: ", currentDeclaracionesDate);

        let variant = "standard";//'filled' | 'outlined' | 'standard'  



        //Encabezados csv
        let csvStr = `N°,PROYECTO ACTIVO,RAZON SOCIAL,RFC,PROPIETARIO,RESPONSABLE CONTABILIDAD,AUXILIAR CONTABLE,FECHA PRESENTACION PPM,ULTIMA DECLARACION PRESENTADA,FECHAS PRESENTACIONES COMPLEMENTARIAS,CFDI'S TIPO I METADATA CON IVA,ACUMULADO CFDI'S TIPO I METADATA,CFDI'S TIPO N METADATA,ACUMULADO CFDI'S TIPO N METADATA,INGRESOS DECLARACION SAT,INGRESOS ACUM SAT,ISR PERSONA MORAL,RECARGOS,TOTAL A PAGAR,ISR SUELDOS Y SALARIOS,RECARGOS,SUBSIDIO APLICADO,TOTAL A PAGAR,ISR IAS,RECARGOS,SUBSIDIO APLICADO,TOTAL A PAGAR,ISR RETENCIONES SERVICIOS PROFESIONALES,RECARGOS,TOTAL A PAGAR,ISR RETENCIONES ARRENDAMIENTO,RECARGOS,TOTAL A PAGAR,IVA A CARGO ,IVA A FAVOR,RECARGOS,TOTAL A PAGAR,IVA A CARGO RFN ,IVA A FAVOR RFN,RECARGOS,TOTAL A PAGAR,IVA RETENCIONES,RECARGOS,TOTAL A PAGAR\n`



        let finalTableContent = []

        let sociedadesConsecutivo = 1;



        declaraciones.forEach(cliente => {
            //console.log("Cliente ", cliente.nombreCliente);

            cliente.sociedadesdeclaraciones.forEach(sociedad => {

                if (!mostrarVendidasNoRevision &&
                    (sociedad.responsableContabilidad === "VENDIDA" ||
                        sociedad.responsableContabilidad === "NO REVISION" ||
                        sociedad.statusContabilidad === "VENDIDA" ||
                        sociedad.statusContabilidad === "NO REVISION" ||
                        sociedad.statusContabilidad === "LIQUIDADA")) {
                    //Skip
                    return
                }




                //console.log("Sociedad ", sociedad.razonSocial);
                //console.log("Sociedad ", sociedad);

                let rowData = [];


                rowData.push(<td>{sociedadesConsecutivo}</td>);
                rowData.push(<td>{sociedad.mensajes && sociedad.mensajes.map(mensaje => <p>{mensaje}</p>)}</td>);
                rowData.push(<td><Link to={`/clientes/reportes/${cliente.clienteId}`} >{cliente.nombreCliente}</Link></td>);
                rowData.push(<td><Link to={`/sociedades/reportes/${sociedad.id}`} >{sociedad.razonSocial}</Link></td>);
                rowData.push(<td><Link to={`/sociedades/reportesContables/${sociedad.rfc}`} >{sociedad.rfc}</Link></td>);
                rowData.push(<td>{cliente.propietario}</td>);
                rowData.push(<td>{sociedad.responsableContabilidad}</td>);
                rowData.push(<td>{sociedad.auxiliarContable}</td>);



                sociedad.declaracionesPeriodoEjercicio.forEach(declaracion => {


                    //console.log("Declaracion RFC ", declaracion&&declaracion.rfc);
                    //console.log("declaracion: " + declaracion)


                    let inlineOldDeclaracionesStyle = { textAlign: "right" };



                    if (declaracion) {

                        let ultimadeclaracionPeriodoNumber = this.arrayMeses.indexOf(declaracion.periodo);


                        let declaracionDate = new Date(declaracion.ejercicio, ultimadeclaracionPeriodoNumber, 22);

                        // console.log("declaracionDate", declaracionDate)

                        let diasDiferencia = datediff(currentDeclaracionesDate, declaracionDate)

                        // console.log("Dias de diferencia: ", diasDiferencia)

                        if (!(sociedad.responsableContabilidad === "VENDIDA" ||
                            sociedad.responsableContabilidad === "NO REVISION" ||
                            sociedad.statusContabilidad === "VENDIDA" ||
                            sociedad.statusContabilidad === "NO REVISION" ||
                            sociedad.statusContabilidad === "LIQUIDADA" ||
                            sociedad.statusContabilidad === "SUSPENDIDA")) {

                            if (diasDiferencia > 90) {

                                //inlineOldDeclaracionesStyle ={ backgroundColor: 'yellow', textAlign:"right"}
                                sociedad.mensajes.push("90+ dias ultima declaracion")

                            } else if (diasDiferencia > 60) {

                                // inlineOldDeclaracionesStyle ={ backgroundColor: 'orange', textAlign:"right"}
                                sociedad.mensajes.push("60+ dias ultima declaracion")

                            }
                        }

                        if (mostrarSolamenteSociedadesConPendientes && sociedad.mensajes && !(sociedad.mensajes.length > 0)) {
                            return;
                        }



                    } else {//No hay declaracion alguna, entonces super rojo!

                        //sociedad.mensajes.push("No hay Declaracion ") 
                        //inlineOldDeclaracionesStyle ={ backgroundColor: '#ff0000', textAlign:"right"}
                    }

                    if (sociedad.mensajes && sociedad.mensajes.length > 0) {
                        // sociedad.mensajes.push("No hay Declaracion ") 

                        if (!(sociedad.responsableContabilidad === "VENDIDA" ||
                            sociedad.responsableContabilidad === "NO REVISION" ||
                            sociedad.statusContabilidad === "VENDIDA" ||
                            sociedad.statusContabilidad === "NO REVISION" ||
                            sociedad.statusContabilidad === "LIQUIDADA" ||
                            sociedad.statusContabilidad === "SUSPENDIDA")) {

                            inlineOldDeclaracionesStyle = { backgroundColor: 'yellow', textAlign: "right" }
                        }
                    }


                    rowData.push(<td>{declaracion ? declaracion.fechaPresentacionPPM : "-"}</td>);
                    rowData.push(<td>{declaracion && declaracion.periodo + "/" + declaracion.ejercicio}<br />
                        {declaracion ? declaracion.fechaUltimaPresentacionPeriodoEjercicio : "-"}
                    </td>);

                    let fechasPresentacionesComplementarias = [];

                    declaracion && declaracion.declaracionesProvisonalesDelPeriodoEjercicio.forEach(declaracionProvisional => {
                        // console.log(declaracionProvisional)
                        fechasPresentacionesComplementarias.push(<p>{declaracionProvisional.tipoDeclaracion}</p>)
                        fechasPresentacionesComplementarias.push(<p>{declaracionProvisional.fechaHora}</p>)
                        fechasPresentacionesComplementarias.push(<p>{declaracionProvisional.documentFileUrl
                            ? <button type="button" className="btn btn-success" onClick={() => {
                                console.log("Descargando Archivo")

                                ElementsDataService.downloadS3File(`?fileName=${declaracionProvisional.documentFileUrl}`,
                                    `${sociedad.rfc}_DeclaracionProvisional_${declaracionProvisional.tipoDeclaracion +
                                    "_" + declaracion.periodo + "_" + declaracion.ejercicio}.pdf`)

                            }}><span className="icon-download"></span> Descargar</button>
                            : "No hay un archivo cargado todavía"}</p>)



                    })

                    rowData.push(<td>{fechasPresentacionesComplementarias}</td>)

                    if (declaracion) {

                        //INFORMACION GENERAL CONTABLE
                        if (declaracion.periodo === periodo) {

                            //Data Metadata
                            rowData.push(<td>{formatMoney(declaracion.cfdisIngresosMetadata)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.cfdisIngresosAcumuladosMetadata)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.cfdisNominaMetadata)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.cfdisNominaAcumuladaMetadata)}</td>);
                            //Data declaraciones
                            rowData.push(<td>{formatMoney(declaracion.totalIngresosNominales)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.ingresosNominalesMesDeclarado)}</td>);

                            //R1 ISR
                            rowData.push(<td>{formatMoney(declaracion.r1Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r1Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r1CantidadAPagar)}</td>);

                            //R12 Sueldos y Salarios
                            rowData.push(<td>{formatMoney(declaracion.r12Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r12Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r12Subsidio)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r12CantidadAPagar)}</td>);


                            //R13 ISR IAS (ISR RETENCIONES POR ASIMILADOS A SALARIOS)
                            rowData.push(<td>{formatMoney(declaracion.r13Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r13Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r13Subsidio)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r13CantidadAPagar)}</td>);


                            //R14 ISR RETENCIONES POR SERVICIOS PROFESIONALES
                            rowData.push(<td>{formatMoney(declaracion.r14Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r14Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r14CantidadAPagar)}</td>);


                            //R15 ISR POR PAGOS POR CUENTA DE TERCEROS O RETENCIONES POR ARRENDAMIENTO DE INMUEBLES
                            rowData.push(<td>{formatMoney(declaracion.r15Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r15Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r15CantidadAPagar)}</td>);


                            //R21 IMPUESTO AL VALOR AGREGADO
                            rowData.push(<td>{formatMoney(declaracion.r21Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r21Afavor)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r21Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r21CantidadAPagar)}</td>);


                            //R21 IMPUESTO AL VALOR AGREGADO (RFN)
                            rowData.push(<td>{formatMoney(declaracion.r21AcargoRFN)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r21AfavorRFN)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r21RecargosRFN)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r21CantidadAPagarRFN)}</td>);

                            //R24 IVA RETENCIONES
                            rowData.push(<td>{formatMoney(declaracion.r24Acargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r24Recargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.r24CantidadAPagar)}</td>);

                            //IVA SIMPLIFICADO DE CONFIANZA
                            rowData.push(<td>{formatMoney(declaracion.ivaSimplificadoConfianzaAcargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.ivaSimplificadoConfianzaAfavor)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.ivaSimplificadoConfianzaRecargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.ivaSimplificadoConfianzaCantidadAPagar)}</td>);

                            //IVA SIMPLIFICADO DE CONFIANZA
                            rowData.push(<td>{formatMoney(declaracion.isrSimplificadoConfianzaAcargo)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.isrSimplificadoConfianzaRecargos)}</td>);
                            rowData.push(<td>{formatMoney(declaracion.isrSimplificadoConfianzaCantidadAPagar)}</td>);




                        }


                    }


                    finalTableContent.push(<tr>{rowData}</tr>)



                    csvStr += sociedadesConsecutivo + ",";
                    csvStr += cliente.nombreCliente + ",";
                    csvStr += sociedad.razonSocial + ",";
                    csvStr += sociedad.rfc + ",";
                    csvStr += cliente.propietario + ",";
                    csvStr += sociedad.responsableContabilidad + ",";
                    csvStr += sociedad.auxiliarContable + ",";
                    csvStr += declaracion && declaracion.fechaPresentacionPPM + ",";

                    csvStr += declaracion && declaracion.periodo + "/" + declaracion.ejercicio + " " +
                        declaracion.fechaUltimaPresentacionPeriodoEjercicio + ",";


                    let fechasPresentacionesComplementariasStr = "";

                    declaracion && declaracion.declaracionesProvisonalesDelPeriodoEjercicio.forEach(declaracionProvisional => {
                        fechasPresentacionesComplementariasStr += declaracionProvisional.tipoDeclaracion + " ";
                        fechasPresentacionesComplementariasStr += declaracionProvisional.fechaHora + " ";

                    })

                    csvStr += fechasPresentacionesComplementariasStr + ",";

                    if (declaracion) {

                        //INFORMACION GENERAL CONTABLE
                        if (declaracion.periodo === periodo) {

                            //Data Metadata
                            csvStr += declaracion.cfdisIngresosMetadata + ",";
                            csvStr += declaracion.cfdisIngresosAcumuladosMetadata + ",";
                            csvStr += declaracion.cfdisNominaMetadata + ",";
                            csvStr += declaracion.cfdisNominaAcumuladaMetadata + ",";
                            //Data SAT
                            csvStr += declaracion.ingresosNominalesMesDeclarado + ",";
                            csvStr += declaracion.totalIngresosNominalesPeriodosAnteriores + ",";


                            //R1 ISR
                            csvStr += declaracion.r1Acargo + ",";
                            csvStr += declaracion.r1Recargos + ",";
                            csvStr += declaracion.r1CantidadAPagar + ",";

                            //R12 Sueldos y Salarios
                            csvStr += declaracion.r12Acargo + ",";
                            csvStr += declaracion.r12Recargos + ",";
                            csvStr += declaracion.r12Subsidio + ",";
                            csvStr += declaracion.r12CantidadAPagar + ",";


                            //R13 ISR IAS (ISR RETENCIONES POR ASIMILADOS A SALARIOS
                            csvStr += declaracion.r13Acargo + ",";
                            csvStr += declaracion.r13Recargos + ",";
                            csvStr += declaracion.r13Subsidio + ",";
                            csvStr += declaracion.r13CantidadAPagar + ",";


                            //R14 ISR RETENCIONES POR SERVICIOS PROFESIONALES
                            csvStr += declaracion.r14Acargo + ",";
                            csvStr += declaracion.r14Recargos + ",";
                            csvStr += declaracion.r14CantidadAPagar + ",";


                            //R15 ISR POR PAGOS POR CUENTA DE TERCEROS O RETENCIONES POR ARRENDAMIENTO DE INMUEBLES
                            csvStr += declaracion.r15Acargo + ",";
                            csvStr += declaracion.r15Recargos + ",";
                            csvStr += declaracion.r15CantidadAPagar + ",";


                            //R21 IMPUESTO AL VALOR AGREGADO
                            csvStr += declaracion.r21Acargo + ",";
                            csvStr += declaracion.r21Afavor + ",";
                            csvStr += declaracion.r21Recargos + ",";
                            csvStr += declaracion.r21CantidadAPagar + ",";


                            //R21 IMPUESTO AL VALOR AGREGADO (RFN
                            csvStr += declaracion.r21AcargoRFN + ",";
                            csvStr += declaracion.r21AfavorRFN + ",";
                            csvStr += declaracion.r21RecargosRFN + ",";
                            csvStr += declaracion.r21CantidadAPagarRFN + ",";

                            //R24 IVA RETENCIONES
                            csvStr += declaracion.r24Acargo + ",";
                            csvStr += declaracion.r24Recargos + ",";
                            csvStr += declaracion.r24CantidadAPagar;

                        }
                    }
                    csvStr += "\n";



                    sociedadesConsecutivo++;

                })
            })





        })




        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button type="button" className="btn btn-success" onClick={() => {
                                    console.log("Descargando Archivo")
                                    const element = document.createElement("a");
                                    const file = new Blob([csvStr], { type: 'text/plain' });
                                    element.href = URL.createObjectURL(file);
                                    var d = new Date();
                                    element.download = "Reporte" + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                            </div>
                            <h1>Revisión Proyectos Mensual</h1>


                        </div>

                        <div className="cols">

                            <div className="col3">
                                <Autocomplete
                                    multiple
                                    id="clientes"
                                    options={allClientes}
                                    getOptionLabel={(option) => option.nombreCliente}

                                    value={clientes}
                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        // this.setState({clientes:value})
                                        console.log("Cliente: ", value);
                                        this.getDeclaracionesByPeriodoEjercicio(value, ejercicio, periodo,
                                            auxiliaresContables, responsablesContabilidad, sociedades);
                                    }}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Proyectos"
                                            placeholder=""
                                            name="clientes"
                                        />
                                    )}
                                /> </div>
                            <div className="col3">
                                <FormControl className="form-control">
                                    <label htmlFor=""><span className="icon-calendar"></span> Ejercicio:</label>
                                    <input type="number" min="1900" max="2099" step="1" value={this.state.ejercicio}
                                        onChange={event => {
                                            const { value } = event.target;

                                            this.getDeclaracionesByPeriodoEjercicio(clientes, value, periodo,
                                                auxiliaresContables, responsablesContabilidad, sociedades);

                                        }}
                                    />
                                </FormControl>

                            </div>
                            &nbsp;
                            <div className="col3">
                                <FormControl className="form-control">
                                    <InputLabel>Periodo</InputLabel>
                                    <Select

                                        value={periodo}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.getDeclaracionesByPeriodoEjercicio(clientes, ejercicio, value,
                                                auxiliaresContables, responsablesContabilidad, sociedades);
                                        }}

                                    >
                                        <option aria-label="None" value="" />
                                        <option value={"Enero"}>Enero</option>
                                        <option value={"Febrero"}>Febrero</option>
                                        <option value={"Marzo"}>Marzo</option>
                                        <option value={"Abril"}>Abril</option>
                                        <option value={"Mayo"}>Mayo</option>
                                        <option value={"Junio"}>Junio</option>
                                        <option value={"Julio"}>Julio</option>
                                        <option value={"Agosto"}>Agosto</option>
                                        <option value={"Septiembre"}>Septiembre</option>
                                        <option value={"Octubre"}>Octubre</option>
                                        <option value={"Noviembre"}>Noviembre</option>
                                        <option value={"Diciembre"}>Diciembre</option>

                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="cols">

                            <div className="col3">
                                <Autocomplete
                                    multiple
                                    id="sociedadesSelector"
                                    options={allSociedades}
                                    getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        this.getDeclaracionesByPeriodoEjercicio(clientes, ejercicio, periodo,
                                            auxiliaresContables, responsablesContabilidad, value);
                                    }}
                                    getOptionSelected={(option, value) => option.rfc === value.rfc}
                                    //value={this.getSelectedItem()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Sociedades"
                                            placeholder=""
                                            name="sociedades"
                                        />
                                    )}
                                />

                            </div>
                            <div className="col3">
                                <Autocomplete
                                    multiple
                                    id="responsablesContabilidad"
                                    options={responsablesContabilidadArray}
                                    getOptionLabel={(option) => option.codigo}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        this.getDeclaracionesByPeriodoEjercicio(clientes, ejercicio, periodo,
                                            auxiliaresContables, value, sociedades);

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Responsables Contabilidad"
                                            placeholder=""
                                            name="responsablesContabilidad"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col3">
                                <Autocomplete
                                    multiple
                                    id="auxiliaresContables"
                                    options={auxiliaresContablesArray}
                                    getOptionLabel={(option) => option.codigo}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        this.getDeclaracionesByPeriodoEjercicio(clientes, ejercicio, periodo,
                                            value, responsablesContabilidad, sociedades);
                                    }}
                                    //value={this.getSelectedItem()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Auxiliares Contables"
                                            placeholder=""
                                            name="auxiliaresContables"
                                        />
                                    )}
                                />

                            </div>


                        </div>

                        <div className="form-group">
                            <label>Mostrar Sociedades Vendidas/No revision/Liquidadas</label>
                            <Checkbox
                                checked={mostrarVendidasNoRevision}
                                color="primary"
                                onChange={event => {
                                    this.setState({ mostrarVendidasNoRevision: event.target.checked });
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label> Mostrar solamente sociedades con pendientes</label>
                            <Checkbox
                                checked={mostrarSolamenteSociedadesConPendientes}
                                color="primary"
                                onChange={event => {
                                    this.setState({ mostrarSolamenteSociedadesConPendientes: event.target.checked });
                                }}
                            />
                        </div>

                        <div className="container">

                            <div className="scrolled">

                                <table className="table big-table">
                                    <thead>
                                        <tr>
                                            <th colspan="8" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>INFORMACION GENERAL</th>
                                            <th colspan="3" style={{ backgroundColor: "#3abbff", textAlign: "center" }}>FECHAS</th>
                                            <th colspan="6" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>INFORMACION GENERAL CONTABLE  {periodo}-{ejercicio}</th>
                                            <th colspan="3" style={{ backgroundColor: "#3abbff", textAlign: "center" }}>R1 ISR</th>
                                            <th colspan="4" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>R12 ISR SUELDOS Y SALARIOS</th>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff", textAlign: "center" }}>R13 ISR IAS</th>
                                            <th colspan="3" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>R14 ISR RETENCIONES SERVICIOS PROFESIONALES</th>
                                            <th colspan="3" style={{ backgroundColor: "#3abbff", textAlign: "center" }}>R15 ISR RETENCIONES ARRENDAMIENTO</th>
                                            <th colspan="4" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>R21 IVA</th>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff", textAlign: "center" }}>R21 IVA RFN</th>
                                            <th colspan="3" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>R24 IVA RETENCIONES</th>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff", textAlign: "center" }}>IVA SIMPLIFICADO DE CONFIANZA</th>
                                            <th colspan="3" style={{ backgroundColor: "#3aaaff", textAlign: "center" }}>ISR SIMPLIFICADO DE CONFIANZA</th>
                                        </tr>
                                        <tr>
                                            <th>N°</th>
                                            <th>MENSAJES</th>
                                            <th>PROYECTO ACTIVO</th>
                                            <th>RAZON SOCIAL</th>
                                            <th>RFC</th>
                                            <th>PROPIETARIO</th>
                                            <th>RESPONSABLE CONTABILIDAD</th>
                                            <th>AUXILIAR CONTABLE</th>
                                            <th>FECHA PRESENTACION PPM</th>
                                            <th>ULTIMA DECLARACION PRESENTADA</th>
                                            <th>FECHAS PRESENTACIONES COMPLEMENTARIAS</th>
                                            <th>CFDI'S TIPO "I"  METADATA CON IVA {periodo}</th>
                                            <th>ACUMULADO CFDI'S TIPO "I" METADATA {ejercicio}</th>
                                            <th>CFDI'S TIPO "N"  METADATA {periodo}</th>
                                            <th>ACUMULADO CFDI'S TIPO "N" METADATA {ejercicio}</th>
                                            <th>INGRESOS DECLARACION SAT {periodo}</th>
                                            <th>INGRESOS ACUM SAT {ejercicio}</th>
                                            <th>ISR PERSONA MORAL</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR SUELDOS Y SALARIOS</th>
                                            <th>RECARGOS</th>
                                            <th>SUBSIDIO APLICADO</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR IAS</th>
                                            <th>RECARGOS</th>
                                            <th>SUBSIDIO APLICADO</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR RETENCIONES SERVICIOS PROFESIONALES</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR RETENCIONES ARRENDAMIENTO</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA A CARGO </th>
                                            <th>IVA A FAVOR</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA A CARGO RFN </th>
                                            <th>IVA A FAVOR RFN</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA RETENCIONES</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA SIMPLIFICADO A CARGO </th>
                                            <th>IVA A FAVOR</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR SIMPLIFICADO</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {finalTableContent}

                                    </tbody>
                                </table >
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>

        )
    }
}

export default RevisionPlazaMensual