import React from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import CfdiComponent from '../entidades/CfdiComponent.jsx'
import ClienteComponent from '../entidades/ClienteComponent.jsx'
import SociedadComponent from '../entidades/SociedadComponent.jsx'
import DocumentoComponent from '../entidades/DocumentoComponent.jsx'
import DomicilioComponent from '../entidades/DomicilioComponent.jsx'
import PersonaFisicaComponent from '../entidades/PersonaFisicaComponent.jsx'
import UsersComponent from '../entidades/UsersComponent.jsx'




class ListElementsComponent extends React.Component {
    constructor(props) {
        console.log('ListElementsComponent constructor', props)
        super(props)
        this.state = {
            elements: [],
            message: null,
            busqueda: '',
            loading: true
        }
        console.log('props', this.props)

        this.elementComponentReference = React.createRef();
        this.deleteElementClicked = this.deleteElementClicked.bind(this)
        this.updateElementClicked = this.updateElementClicked.bind(this)
        this.addElementClicked = this.addElementClicked.bind(this)
        this.refreshElements = this.refreshElements.bind(this)
    }

    componentWillUnmount() {
        console.log('ListElementsComponent componentWillUnmount')
    }


    componentDidMount() {
        console.log('ListElementsComponent componentDidMount')

    }

    refreshElements() {
        console.log("refreshElements")
        ElementsDataService.retrieveAllElements(this.props.ruta)
            .then(
                response => {
                    console.log(response);
                    this.setState({ elements: response.data })
                    this.elementComponentReference.current.setState({ elements: response.data, loading: false })
                }
            )
    }

    deleteElementClicked(id) {

        if (window.confirm("¿Eliminar Elemento?")) {
            ElementsDataService.deleteElement(this.props.ruta, id)
                .then(
                    response => {
                        // this.setState({ message: `Delete of Element ${id} Successful` })
                        this.refreshElements()
                    }
                )
        }


    }

    addElementClicked() {
        this.props.history.push(`/${this.props.ruta}/-1`)
    }

    updateElementClicked(id) {
        console.log('update ' + id)
        this.props.history.push(`/${this.props.ruta}/${id}`)

    }


    render() {
        console.log('render ListElements')
        const ruta = this.props.ruta;
        console.log('ruta: ' + ruta)
        console.log("this.state", this.state)

        const functionsSet = {
            updateElementClicked: this.updateElementClicked,
            addElementClicked: this.addElementClicked,
            deleteElementClicked: this.deleteElementClicked,
            refreshElements: this.refreshElements
        }

        return (
            <div>
                {(ruta === "cfdis") ?
                    <CfdiComponent {...this.state}
                        {...functionsSet}
                        {...this.props}
                        ref={this.elementComponentReference} /> : null
                }

                {(ruta === "clientes") ?
                    <ClienteComponent {...this.state}
                        {...functionsSet}
                        ref={this.elementComponentReference} /> : null
                }

                {(ruta === "documentos") ?
                    <DocumentoComponent {...this.state}
                        {...functionsSet}
                        ref={this.elementComponentReference} /> : null
                }

                {(ruta === "domicilios") ?
                    <DomicilioComponent {...this.state}
                        {...functionsSet}
                        ref={this.elementComponentReference} /> : null
                }

                {(ruta === "personas-fisicas") ?
                    <PersonaFisicaComponent {...this.state}
                        {...functionsSet}
                        {...this.props}
                        ref={this.elementComponentReference} /> : null
                }

                {(ruta === "sociedades") ?
                    <SociedadComponent {...this.state}
                        {...functionsSet}
                        ref={this.elementComponentReference} /> : null
                }

                {(ruta === "users") ?
                    <UsersComponent {...this.state}
                        {...functionsSet}
                        ref={this.elementComponentReference} /> : null
                }

            </div>
        )
    }
}

export default ListElementsComponent