import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import { Link } from 'react-router-dom'
import Grafica from "../../charts/Grafica";
import Chart from "../../charts/Chart";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { ImPrinter } from 'react-icons/im'
import AuthenticationService from '../general/AuthenticationService.js'


class SociedadReporteContableComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: '',
            correo: '',
            password: '',
            generacion: '',
            domicilios: [],
            opinionCumplimiento: '',
            pendientes: '',
            razonSocial: '',
            revisionFiscal: '',
            status: '',
            statusContabilidad: '',
            situacion: '',
            tipo: '',
            rfc: this.props.match.params.rfc,
            clientes: [],
            primerAño: 0,
            ultimoAño: 0,
            totalIngresosFacturados: 0,
            totalNominaTimbrada: 0,
            ingresosPerYear: [],
            ingresosPerYearMonth: [],
            nominaPerYear: [],
            nominaPerYearMonth: [],
            softDelete: '',
            yerMonthGraph: [],
            yearMonthNominaGraph: [],
            historialSociedadDomicilios: [],
            loading: true,

        }

        this.print = this.print.bind(this)

    }

    print() {
        window.print();
    }




    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_CONTABILIDAD")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)




        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("contabilidad", this.state.rfc)
            .then(response => {


                let infoContable = response.data;

                console.log("infoContable", infoContable)

                if (infoContable.primerAño == 0 || infoContable.totalIngresosFacturados == null) {
                    alert("No hay CFDIs registrados para este RFC");
                    this.props.history.push("/Sociedades");
                }




                this.setState(
                    {
                        primerAño: infoContable.primerAño,
                        ultimoAño: infoContable.ultimoAño,
                        totalIngresosFacturados: infoContable.totalIngresosFacturados,
                        totalNominaTimbrada: infoContable.totalNominaTimbrada,
                        ingresosPerYear: infoContable.ingresosPerYear,
                        ingresosPerYearMonth: infoContable.ingresosPerYearMonth,
                        nominaPerYear: infoContable.nominaPerYear,
                        nominaPerYearMonth: infoContable.nominaPerYearMonth

                    }
                )

                let yerMonthGraph = [];

                let yerMonthGraphData = [];

                let yearMonthNominaGraph = [];

                let yearMonthNominaGraphData = [];

                for (let año = this.state.primerAño; año <= this.state.ultimoAño; año++) {

                    for (let mes = 1; mes <= 12; mes++) {

                        let añoMes = año.toString() + mes.toString();

                        //{mes : "Enero", year : 2018 , ingresos: 1456.02},

                        let row = {
                            mes: meses[mes - 1],
                            year: año,
                            ingresos: this.state.ingresosPerYearMonth[añoMes]
                        }

                        yerMonthGraphData.push(row);

                        let rowNomina = {
                            mes: meses[mes - 1],
                            year: año,
                            ingresos: this.state.nominaPerYearMonth[añoMes]
                        }

                        yearMonthNominaGraphData.push(rowNomina);

                    }

                }

                console.log("Sociedad Reporte yerMonthGraphData", yerMonthGraphData)

                yerMonthGraph.push(<Chart ingresosPerMonthYear={yerMonthGraphData} divId="#ingresosChart" />)
                yearMonthNominaGraph.push(<Chart ingresosPerMonthYear={yearMonthNominaGraphData} divId="#nominasChart" />)

                this.setState({ yerMonthGraph })
                this.setState({ yearMonthNominaGraph })
                this.setState({ loading: false })


            })

        ElementsDataService.retrieveElement("sociedades/rfc", this.state.rfc)
            .then(response => {
                this.setState({
                    id: response.data.id,
                    correo: response.data.correo,
                    password: response.data.password,
                    generacion: response.data.generacion,
                    domicilios: response.data.domicilios,
                    opinionCumplimiento: response.data.opinionCumplimiento,
                    pendientes: response.data.pendientes,
                    razonSocial: response.data.razonSocial,
                    revisionFiscal: response.data.revisionFiscal,
                    status: response.data.status,
                    statusContabilidad: response.data.statusContabilidad,
                    situacion: response.data.situacion,
                    tipo: response.data.tipo,
                    rfc: response.data.rfc,
                    clientes: response.data.clientes
                })

                ElementsDataService.retrieveElement("sociedades", response.data.id)
                    .then(response => {
                        console.log(response)
                        this.setState({
                            historialSociedadDomicilios: response.data.historialSociedadDomicilios,
                        })
                    })

            })

        console.log("state", this.state);

    }



    render() {

        console.log("render SociedadReporteContableComponent")
        console.log("SociedadReporteContableComponent state", this.state)



        let {
            id,
            correo,
            password,
            generacion,
            opinionCumplimiento,
            pendientes,
            razonSocial,
            revisionFiscal,
            status,
            statusContabilidad,
            tipo,
            situacion,
            rfc,
            domicilios,
            clientes,
            primerAño,
            ultimoAño,
            totalIngresosFacturados,
            totalNominaTimbrada,
            ingresosPerYear,
            ingresosPerYearMonth,
            nominaPerYear,
            nominaPerYearMonth,
            yerMonthGraph,
            yearMonthNominaGraph,
            historialSociedadDomicilios,
            loading
        } = this.state

        const listaClientes = clientes ? clientes.map((cliente) => <li key={cliente.id}>
            <Link to={`/clientes/reportes/${cliente.id}`}>
                {cliente.nombreCliente}
            </Link>
        </li>) : "";

        console.log("domicilios", domicilios)

        const listaDomiciliosOrdenados = domicilios ? domicilios
            .sort((a, b) => {

                let fechaInicioA = 0, fechaInicioB = 0

                historialSociedadDomicilios
                    .filter(historial => historial.domicilioId == a.id)
                    .forEach(historial => {
                        fechaInicioA = Date.parse(historial.fechaInicio)

                        if (isNaN(fechaInicioA)) {
                            fechaInicioA = 0
                        }

                    })

                historialSociedadDomicilios
                    .filter(historial => historial.domicilioId == b.id)
                    .forEach(historial => {
                        fechaInicioB = Date.parse(historial.fechaInicio)

                        if (isNaN(fechaInicioB)) {
                            fechaInicioB = 0
                        }

                    })

                return fechaInicioB - fechaInicioA
            }) : []

        // console.log("listaDomiciliosOrdenados", listaDomiciliosOrdenados)

        const ultimoDomicilio = listaDomiciliosOrdenados ? listaDomiciliosOrdenados[0] : [];


        let tablePerYearHeader = [];
        let ingresosPerYearValues = [];
        let nominaPerYearValues = [];


        for (let año = primerAño; año <= ultimoAño; año++) {

            tablePerYearHeader.push(<th className="right">{año}</th>)
            ingresosPerYearValues.push(<td className="right">{formatMoney(ingresosPerYear[año])}</td>);
            nominaPerYearValues.push(<td className="right">{formatMoney(nominaPerYear[año])}</td>);



        }

        const tablesYearMonth = (añoInicial, añoFinal) => {

            let tables = [];

            for (let año = añoInicial; año <= añoFinal; año++) {
                tables.push(tablaYearMonth(año));
            }

            return tables;
        }


        const tablaYearMonth = (year) => {


            let ingresosMonthEneroJunio = [];
            let ingresosMonthJulioDiciembre = [];
            let nominaMonthEneroJunio = [];
            let nominaMonthJulioDiciembre = [];



            for (let mes = 1; mes <= 6; mes++) {

                let añoMes = year.toString() + mes.toString();

                ingresosMonthEneroJunio.push(<td className="right">{formatMoney(ingresosPerYearMonth[añoMes])}</td>);
                nominaMonthEneroJunio.push(<td className="right">{formatMoney(nominaPerYearMonth[añoMes])}</td>);

                let mesPlus6 = mes + 6;

                añoMes = year.toString() + mesPlus6.toString();

                ingresosMonthJulioDiciembre.push(<td className="right">{formatMoney(ingresosPerYearMonth[añoMes])}</td>);
                nominaMonthJulioDiciembre.push(<td className="right">{formatMoney(nominaPerYearMonth[añoMes])}</td>);

            }

            return (
                <div>



                    <table className="table simple year">
                        <thead>
                            <tr>
                                <th>Ejercicio de operacion {year}</th><th>Enero</th><th>Febrero</th><th>Marzo</th><th>Abril</th><th>Mayo</th><th>Junio</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>Total Ingresos Facturados</td>{ingresosMonthEneroJunio}
                        </tr>
                        <tr>
                            <td>Total Nomina Timbrada</td>{nominaMonthEneroJunio}
                        </tr>
                        <thead>

                            <tr>
                                <th>Ejercicio de operacion {year}</th><th>Julio</th><th>Agosto</th><th>Septiembre</th><th>Octubre</th><th>Noviembre</th><th>Diciembre</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>Total Ingresos Facturados</td>{ingresosMonthJulioDiciembre}
                        </tr>
                        <tr>
                            <td>Total Nomina Timbrada</td>{nominaMonthJulioDiciembre}
                        </tr>
                    </table>
                    <h3></h3>
                </div>

            )

        }



        return (
            <LoadingMask loading={loading} text={"loading..."}>


                <div id="main">

                    <div className="panel">
                        <div className="titles">

                            <button className="no-print btn btn-imprimir" onClick={this.print}> <ImPrinter />  Imprimir</button>
                        </div>

                        <h1>Reporte General Contable </h1>
                        <h2 className="title">{rfc}</h2>

                        <div className="container">
                            <table className="table simple" id="tabla1">
                                <tr>
                                    <td>Razon Social</td><td><Link to={`/sociedades/reportes/${id}`} >{razonSocial}</Link></td>
                                </tr>
                                <tr>
                                    <td>RFC</td><td>{rfc}</td>
                                </tr>
                                <tr>
                                    <td>Tipo de sociedad</td><td>{tipo}</td>
                                </tr>
                                <tr>
                                    <td>Estatus Legal</td><td>{status && status.toLocaleUpperCase()}</td>
                                </tr>
                                <tr>
                                    <td>Estatus Contable</td><td>{statusContabilidad}</td>
                                </tr>
                                <tr>
                                    <td>Clientes</td><td>{listaClientes}</td>
                                </tr>
                            </table>
                            <h3 className="title">
                                Ultimo Domicilio Registrado
                            </h3>
                            <div className="cols">
                                <div className="col2">

                                    <table className="table simple">

                                        <tr>
                                            <td>Calle</td><td>{ultimoDomicilio && ultimoDomicilio.calle}</td>
                                        </tr>
                                        <tr>
                                            <td>Numero Exterior</td><td>{ultimoDomicilio && ultimoDomicilio.numeroExt}</td>
                                        </tr>
                                        <tr>
                                            <td>Numero Interior</td><td>{ultimoDomicilio && ultimoDomicilio.numeroInterior}</td>
                                        </tr>
                                        <tr>
                                            <td>Colonia</td><td>{ultimoDomicilio && ultimoDomicilio.colonia}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col2">
                                    <table className="table simple">
                                        <tr>
                                            <td>Codigo Postal</td><td>{ultimoDomicilio && ultimoDomicilio.cp}</td>
                                        </tr>
                                        <tr>
                                            <td>Municipio</td><td>{ultimoDomicilio && ultimoDomicilio.municipio}</td>
                                        </tr>
                                        <tr>
                                            <td>Estado</td><td>{ultimoDomicilio && ultimoDomicilio.estado}</td>
                                        </tr>
                                        <tr>
                                            <td>Telefono</td><td>{ultimoDomicilio && ultimoDomicilio.telefono}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div id="tablesContainer">

                                <div className="tableContainer">
                                    <table className="table simple year">
                                        <thead>
                                            <tr>
                                                <th>Resumen General CFDI's Timbrados </th><th className="right">Total general</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Total Ingresos Facturados</td><td className="right">{formatMoney(totalIngresosFacturados)}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Nomina Timbrada</td><td className="right">{formatMoney(totalNominaTimbrada)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tableContainer">

                                    <table className="table simple">
                                        <thead>

                                            <tr>
                                                <th>Ejercicios Facturados</th>{tablePerYearHeader}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>Total Ingresos Facturados</td>{ingresosPerYearValues}
                                            </tr>
                                            <tr>
                                                <td>Total Nomina Timbrada</td>{nominaPerYearValues}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tableContainer">
                                    {tablesYearMonth(primerAño, ultimoAño)}
                                </div>
                            </div>
                        </div>



                        <div className="no-break">
                            <h3 className="title">Gráfica Ingresos</h3>
                            <div className="scrolled">

                                <div id="ingresosChart">
                                </div>

                                {yerMonthGraph}
                            </div>

                        </div>

                        <div className="no-break">
                            <h3 className="title">Gráfica Nomina</h3>
                            <div className="scrolled">
                                <div id="nominasChart">
                                </div>

                                {yearMonthNominaGraph}
                            </div>
                        </div>



                    </div>

                </div>


            </LoadingMask>

        )
    }

}


const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]


function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {

    if (isNaN(amount)) {//Caso en que no es un numero!!!
        return "-";
    }

    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return "" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

export default SociedadReporteContableComponent