import React, { Component } from 'react'
import AuthenticationService from './AuthenticationService.js'
import HeaderComponent from './HeaderComponent.jsx'


class LoginComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            hasLoginFailed: false,
            showSuccessMessage: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
    }

    handleChange(event) {

        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }


    loginClicked() {

        AuthenticationService
            .executeJwtAuthenticationService(this.state.username, this.state.password)
            .then((response) => {
                AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, response.data.token, response.data.authorities)
                this.props.history.push(`/welcome/${this.state.username}`)
            }).catch(() => {
                this.setState({ showSuccessMessage: false })
                this.setState({ hasLoginFailed: true })
            })

    }

    render() {
        return (
            <div id="main" className="center">

                <div className="panel form small ">
                    <div className="icons-title">

                        <div className="big-icon"><span className="icon-user"></span>    </div>
                        <h1>Acceder</h1>
                    </div>
                    <div className="container">

                        {this.state.hasLoginFailed && <div className="alert alert-warning">Invalid Credentials</div>}
                        {this.state.showSuccessMessage && <div>Login Sucessful</div>}
                        <label htmlFor="username"><span className="icon-user"></span> Usuario:</label><input autoComplete="off" type="text" name="username" value={this.state.username} onChange={this.handleChange} />
                        <label htmlFor="password"><span className="icon-key"></span> Contraseña:</label><input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                        <div className="buttons">

                            <button className="btn btn-success" onClick={this.loginClicked} >Ingresar  <span className="icon-arrow-right"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginComponent