import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import { Link } from 'react-router-dom'
import { Document, Page } from 'react-pdf';
import AuthenticationService from '../general/AuthenticationService.js'



class PersonaFisicaReporteComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            apellidoMaterno: '',
            apellidoPaterno: '',
            curp: '',
            rfc: '',
            nombre: '',
            documentos: [],
            sociedades: [],
            domicilios: [],
            entidadesRolesComentarios: [],
            documentosSociedadesRolesComentarios: [],
            curpFileUrl: '',
            cifFileUrl: '',
            ineFileUrl: '',
            vigenciaIne: '',
            ineFile: '',
            ineFileData: '',
            impresionPantallaIneFileUrl: '',
            vigenciaImpresionPantallaIne: '',
        }


    }


    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_PERSONAS_FISICAS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }



        ElementsDataService.retrieveElement("personas-fisicas", this.state.id)
            .then(response => {

                this.setState({
                    apellidoMaterno: response.data.apellidoMaterno,
                    apellidoPaterno: response.data.apellidoPaterno,
                    curp: response.data.curp,
                    rfc: response.data.rfc,
                    nombre: response.data.nombre,
                    domicilios: response.data.domicilios,
                    documentos: response.data.documentos,
                    entidadesRolesComentarios: response.data.entidadesRolesComentarios,
                    curpFileUrl: response.data.curpFileUrl,
                    cifFileUrl: response.data.cifFileUrl,
                    ineFileUrl: response.data.ineFileUrl,
                    vigenciaIne: response.data.vigenciaIne,
                    impresionPantallaIneFileUrl: response.data.impresionPantallaIneFileUrl,
                    vigenciaImpresionPantallaIne: response.data.vigenciaImpresionPantallaIne,
                })

                console.log("response", response)

                let sociedadesRolesComentarios = [];




                this.setState({
                    documentosSociedadesRolesComentarios: sociedadesRolesComentarios
                })

                /*
                
                this.state.ineFileUrl && ElementsDataService.getPDFFile(`?fileName=${this.state.ineFileUrl}`)
                .then(response =>{

                    console.log("PDFContent");
        
                    console.log(response);

                    var blob = new Blob([response], {type: 'application/pdf'});
                    var url = URL.createObjectURL(blob);
                    console.log(url);
                    this.setState({ineFileData:url})
                    

                  var reader = new FileReader();

                    reader.readAsDataURL(blob);
                  
                    reader.onloadend = () => {
                        var base64data = reader.result;                
                        console.log(base64data);
                        var blob = new Blob([response], {type: 'application/pdf'});
                        var url = URL.createObjectURL(blob);
                        console.log(url);
                            
                        this.setState({ineFileData:url})
                        return;
                    }
                   
                
                })
                */


                console.log("PersonaFisicaReporteComponent state", this.state)


            })

    }


    render() {

        console.log("render PersonaFisicaReporteComponent")
        console.log("PersonaFisicaReporteComponent state", this.state)
        let { id,
            apellidoMaterno,
            apellidoPaterno,
            curp,
            rfc,
            nombre,
            documentos,
            sociedades,
            entidadesRolesComentarios,
            domicilios,
            documentosSociedadesRolesComentarios,
            curpFileUrl,
            cifFileUrl,
            ineFileUrl,
            vigenciaIne,
            impresionPantallaIneFileUrl,
            vigenciaImpresionPantallaIne

        } = this.state


        const listaDomicilios = domicilios ? domicilios.map((domicilio) => <li key={domicilio.id}>
            <Link to={`/domicilios/reportes/${domicilio.id}`}>
                {domicilio.calle + " " + domicilio.numeroExt}
            </Link>
        </li>) : "";

        let listaSociedades = [];


        /* listaSociedades= documentosSociedadesRolesComentarios.map((elemento, i) =>
         <table key={i}>
             <tr><th colspan="2">Sociedades</th></tr>
         <tr>    
                 <td colspan="2"> 
                 {elemento.sociedades.map(function (sociedad){
                    return ( <p>   
                        <Link  to={`/sociedades/reportes/${sociedad.id}`}> {sociedad.razonSocial }  </Link>
                        </p>                                   
                    );
 
                 })} 
                 </td>
         </tr>
         <tr><th>Rol</th><th>Comentario</th></tr>
                 {elemento.rolesComentarios.map(function (elemento){
                    return (
                     <tr>
                     <td>{elemento.roles}</td>
                     <td>{elemento.comentarios}</td>
                     </tr>
                    );
 
                 })}
         
              </table> );
 */



        let listaDocumentos = null;

        listaDocumentos = documentos ? documentos.map((documento) => <tr key={documento.id}>
            <td> {documento.sociedades.map(function (sociedad) {
                return (<div>
                    <Link to={`/sociedades/reportes/${sociedad.id}`}> {sociedad.razonSocial}  </Link> <br />
                </div>
                );

            })}
            </td>
            <td><Link to={`/documentos/reportes/${documento.id}`}>{documento.asuntos ? documento.asuntos : documento.tipoDocumento}</Link></td>
            <td>
                {entidadesRolesComentarios.map(function (roles) {

                    return (documento.id == roles.documentoId ? <p>
                        {roles.rol}
                    </p> : ''
                    );
                })}
            </td>

        </tr>) : "";






        return (
            <div id="main">
                <div className="panel">
                    <div className="titles">
                        <div className="tool-btn-agregar">
                            <button className="btn btn-edit" onClick={() => this.props.history.push(`/personas-fisicas/${id}`)}><span className="icon-edit-pencil"> </span><span className="icon-user"></span>  </button>
                        </div>
                        <div className="tool-btn-agregar">
                            <button className="btn btn-agregar" onClick={() => this.props.history.push(`/personas-fisicas/-1`)}><span className="icon-add-solid"> </span><span className="icon-user"></span></button>
                        </div>
                        <h1>Reporte Persona Fisica</h1>
                    </div>

                    <div className="container">

                        <div>



                        </div>

                        <table className="table simple">
                            <tr>
                                <td>Nombre</td><td colspan="2">{nombre + " " + apellidoPaterno + " " + apellidoMaterno}</td>
                            </tr>
                            <tr>
                                <td>RFC</td><td colspan="2">{rfc}</td>
                            </tr>
                            <tr>
                                <td>CURP</td><td colspan="2">{curp}<br /> {curpFileUrl
                                    ? <button type="button" className="btn btn-success" onClick={() => {
                                        console.log("Descargando Archivo")

                                        ElementsDataService.downloadS3File(`?fileName=${curpFileUrl}`,
                                            `CURP_${nombre}_${apellidoPaterno}_${apellidoMaterno}_${curp}.pdf`)

                                    }}> <span className="icon-download"></span> Descargar</button>
                                    : "No hay un archivo cargado todavía"}</td>
                            </tr>
                            <tr>
                                <td>INE</td><td colspan="2">{ineFileUrl
                                    ? <button type="button" className="btn btn-success" onClick={() => {
                                        console.log("Descargando Archivo")

                                        ElementsDataService.downloadS3File(`?fileName=${ineFileUrl}`,
                                            `INE_${nombre}_${apellidoPaterno}_${apellidoMaterno}_${curp}.pdf`)

                                    }}><span className="icon-download"></span> Descargar</button>
                                    : "No hay un archivo cargado todavía"}</td>
                            </tr>
                            <tr>
                                <td>Vigencia INE</td><td colspan="2">{vigenciaIne == '1900-01-01' ? "NO VIGENTE" : vigenciaIne}</td>
                            </tr>

                            <tr>
                                <td>Impresión de pantalla INE</td><td colspan="2">{impresionPantallaIneFileUrl
                                    ? <button type="button" className="btn btn-success" onClick={() => {
                                        console.log("Descargando Archivo")

                                        ElementsDataService.downloadS3File(`?fileName=${impresionPantallaIneFileUrl}`,
                                            `INE_ImpresionPantalla_${nombre}_${apellidoPaterno}_${apellidoMaterno}_${curp}.pdf`)

                                    }}><span className="icon-download"></span> Descargar</button>
                                    : "No hay un archivo cargado todavía"}</td>
                            </tr>
                            <tr>
                                <td>Vigencia Impresión de pantalla INE</td><td colspan="2">{vigenciaImpresionPantallaIne == '1900-01-01' ? "NO VIGENTE" : vigenciaImpresionPantallaIne}</td>
                            </tr>

                            <tr>
                                <td>CIF</td><td colspan="2">{cifFileUrl
                                    ? <button type="button" className="btn btn-success" onClick={() => {
                                        console.log("Descargando Archivo")

                                        ElementsDataService.downloadS3File(`?fileName=${cifFileUrl}`,
                                            `CIF_${nombre}_${apellidoPaterno}_${apellidoMaterno}_${curp}.pdf`)

                                    }}><span className="icon-download"></span> Descargar</button>
                                    : "No hay un archivo cargado todavía"}</td>
                            </tr>

                            <tr>
                                <td>Domicilios</td><td colspan="2">{listaDomicilios}</td>
                            </tr>

                        </table>
                        <table className="table">
                            <thead>
                                <tr>

                                    <th>Sociedades</th><th>Documentos</th><th>Roles</th>
                                </tr>
                            </thead>
                            <tbody>

                                {listaDocumentos}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonaFisicaReporteComponent