import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { Component } from 'react';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js';
import AuthenticationService from '../general/AuthenticationService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';


class UserFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            edit: false,
            userName: '',
            password1: '',
            password2: '',
            authorities: [],
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.addAuthority = this.addAuthority.bind(this)

    }

    useStyles = makeStyles((theme) => ({
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
    }));


    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("WRITE_USUARIOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;


        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("users", params.id)
            .then(response => {
                console.log("response", response)
                this.setState({
                    userName: response.data.userName,
                    edit: true,
                    authorities: response.data.authoritiesValues,
                })
            })

    }

    validate() {
        let errors = []

        if (!this.state.userName) {
            errors.push('Debe introducir un nombre Usuario');
        }

        if (this.state.password1 && this.state.password1.length < 7) {
            errors.push('La contraseña debe ser de al menos 8 caracteres')
        }

        if (!(this.state.password1 === this.state.password2)) {
            errors.push('Las contraseñas no coinciden')
        }

        return errors

    }

    onSubmit(values) {

        console.log("onSubmit")

        let errors = this.validate();

        if (errors.length) {
            console.log("Errors: ", errors)
            let messages = errors.join("\n")
            alert(messages)
            return;
        }

        let user = {
            userName: this.state.userName,
            password: this.state.password1,
            authoritiesValues: this.state.authorities,
        }


        if (!this.state.edit) {
            ElementsDataService.createElement("users", user)
                .then(() => this.props.history.push('/users'))
        } else {
            ElementsDataService.updateElement("users", this.state.userName, user)
                .then(() => this.props.history.push('/users'))
        }

        console.log("values", values);
    }

    addAuthority(checked, authoritie) {

        let authorities = this.state.authorities;
        if (checked) {
            this.setState({
                authorities: [...authorities, authoritie]
            });
        } else {
            let index = authorities.indexOf(authoritie);
            if (index !== -1) {
                authorities.splice(index, 1);
            }
            this.setState({
                authorities: authorities
            });
        }
    }


    render() {

        const authoritiesArray = [
            "READ_SOCIEDADES",
            "WRITE_SOCIEDADES",
            "DELETE_SOCIEDADES",
            "READ_CLIENTES",
            "WRITE_CLIENTES",
            "DELETE_CLIENTES",
            "READ_DOMICILIOS",
            "WRITE_DOMICILIOS",
            "DELETE_DOMICILIOS",
            "SUPERVISOR_DOMICILIOS",
            "READ_PERSONAS_FISICAS",
            "WRITE_PERSONAS_FISICAS",
            "DELETE_PERSONAS_FISICAS",
            "READ_DOCUMENTOS",
            "WRITE_DOCUMENTOS",
            "DELETE_DOCUMENTOS",
            "READ_CFDIS",
            "WRITE_CFDIS",
            "READ_USUARIOS",
            "WRITE_USUARIOS",
            "DELETE_USUARIOS",
            "READ_HISTORIA",
            "READ_ALERTAS",
            "READ_BUSQUEDA",
            "READ_CONTABILIDAD",
        ]

        const rolesArray = [
            "ADMIN",
            "USUARIO LEGAL",
            "USUARIO DOMICILIOS",
            "SUPERVISOR DOMICILIOS",
            "USUARIO CONTABLE",
            "AUXILIAR CONTABLE",
            "NINGUNO",
        ]


        const usuarioLegalRole = [
            "READ_SOCIEDADES",
            "WRITE_SOCIEDADES",
            "READ_CLIENTES",
            "READ_DOMICILIOS",
            "READ_PERSONAS_FISICAS",
            "WRITE_PERSONAS_FISICAS",
            "DELETE_PERSONAS_FISICAS",
            "READ_DOCUMENTOS",
            "WRITE_DOCUMENTOS",
            "DELETE_DOCUMENTOS",
            "READ_HISTORIA",
            "READ_ALERTAS",
            "READ_BUSQUEDA",
        ]

        const usuarioDomiciliosRole = [
            "READ_SOCIEDADES",
            "READ_CLIENTES",
            "READ_DOMICILIOS",
            "WRITE_DOMICILIOS",
            "READ_PERSONAS_FISICAS",
            "READ_DOCUMENTOS",
            "WRITE_DOCUMENTOS",
            "DELETE_DOCUMENTOS",
            "READ_HISTORIA",
            "READ_ALERTAS",
            "READ_BUSQUEDA",
        ]


        const supervisorDomiciliosRole = [//Capaz de eliminar domicilios y modificar pertnenecia a sociedades
            "READ_SOCIEDADES",
            "READ_CLIENTES",
            "READ_DOMICILIOS",
            "WRITE_DOMICILIOS",
            "DELETE_DOMICILIOS",
            "SUPERVISOR_DOMICILIOS",
            "READ_PERSONAS_FISICAS",
            "READ_DOCUMENTOS",
            "WRITE_DOCUMENTOS",
            "DELETE_DOCUMENTOS",
            "READ_HISTORIA",
            "READ_ALERTAS",
            "READ_BUSQUEDA",
        ]

        const usuarioContableRole = [
            "READ_SOCIEDADES",
            "READ_CLIENTES",
            "READ_DOMICILIOS",
            "READ_PERSONAS_FISICAS",
            "READ_DOCUMENTOS",
            "READ_CFDIS",
            "WRITE_CFDIS",
            "READ_HISTORIA",
            "READ_ALERTAS",
            "READ_BUSQUEDA",
            "READ_CONTABILIDAD",
        ]

        const auxiliarContableRole = [//Este rol es capaz de cargar informacion pero no puede acceder a los reportes contables
            "READ_CFDIS",
            "WRITE_CFDIS",
        ]


        let { edit, userName, password1, password2 } = this.state

        let variant = "standard";//'filled' | 'outlined' | 'standard'

        return (
            <div id="main">
                <div className="panel">
                    <h1>{edit ? "Editar" : "Nuevo"} Usuario</h1>
                    <div className="container">
                        <Formik
                            initialValues={{ userName }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize={true}
                        >
                            {
                                (props) => (
                                    <Form autoComplete="off" >
                                        <ErrorMessage name="nombreCliente" component="div"
                                            className="alert alert-warning" />

                                        <div className="cols">
                                            <div className="col2">
                                                {edit ? <h2>{userName}</h2> :
                                                    <TextField autoComplete="off" className="form-control"
                                                        value={userName}
                                                        variant={variant}
                                                        label="Nombre Usuario"
                                                        placeholder="Nombre Usuario"
                                                        name="userName"
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ userName: value });
                                                        }}
                                                    />
                                                }

                                            </div>
                                        </div>
                                        <div className="cols">
                                            <div className="col2">
                                                <TextField autoComplete="off" className="form-control"
                                                    value={password1}
                                                    variant={variant}
                                                    label="Nueva Contraseña"
                                                    placeholder="Nueva Contraseña"
                                                    name="password1"
                                                    type="password"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ password1: value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="cols">
                                            <div className="col2">
                                                <TextField autoComplete="off" className="form-control"
                                                    value={password2}
                                                    variant={variant}
                                                    label="Repetir Contraseña"
                                                    placeholder="Repetir Contraseña"
                                                    name="password2"
                                                    type="password"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ password2: value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="cols">
                                            <div className="col2">
                                                <Autocomplete
                                                    id="rol"
                                                    options={rolesArray}
                                                    getOptionLabel={(option) => option}
                                                    filterSelectedOptions
                                                    onChange={(e, value) => {
                                                        console.log(value);
                                                        switch (value) {
                                                            case "ADMIN": this.setState({ authorities: authoritiesArray }); break;
                                                            case "USUARIO LEGAL": this.setState({ authorities: usuarioLegalRole }); break;
                                                            case "USUARIO DOMICILIOS": this.setState({ authorities: usuarioDomiciliosRole }); break;
                                                            case "SUPERVISOR DOMICILIOS": this.setState({ authorities: supervisorDomiciliosRole }); break;
                                                            case "USUARIO CONTABLE": this.setState({ authorities: usuarioContableRole }); break;
                                                            case "AUXILIAR CONTABLE": this.setState({ authorities: auxiliarContableRole }); break;
                                                            case "NINGUNO": this.setState({ authorities: [] }); break;
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Rol"
                                                            placeholder=""
                                                            name="rol"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <h2>Autorizaciones</h2>

                                        {authoritiesArray.map(authoritie =>
                                            <div className="form-group">
                                                <label>{authoritie}</label>
                                                <Checkbox
                                                    checked={this.state.authorities.includes(authoritie)}
                                                    color="primary"
                                                    onChange={event => {
                                                        this.addAuthority(event.target.checked, authoritie);
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div className="buttons">
                                            <button className="btn btn-success" type="submit" onClick={this.onSubmit}><span className=" icon-checkmark"></span> Guardar</button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </div>
            </div >
        )
    }
}

export default UserFormularioComponent