import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Checkbox from '@material-ui/core/Checkbox';
import AuthenticationService from '../general/AuthenticationService.js'
import { AiOutlineFileSearch } from 'react-icons/ai'




class DomicilioComponent extends Component {
    constructor(props) {
        console.log("DomicilioComponent Constructor")
        super(props)

        this.state = {
            ...props,
            cliente: '',
            allClientes: [],
            allSociedades: [],
            sociedadSeleccionada: '',
            nombreCliente: '',
            ejercicio: new Date().getFullYear(),//año
            periodo: this.arrayMeses[new Date().getMonth()],//mes
            estado: '',
            municipio: '',
            colonia: '',
            calle: '',
            status: '',
            control: '',
            tipo: '',
            busqueda: '',
            mostrarInactivas: false,
            mostrarSociedadesFinalizadas: false,
            modoReporte: false,
            uncollapseRows: false,
        }

        this.goBusqueda = this.goBusqueda.bind(this)
        this.loadDomiciliosList = this.loadDomiciliosList.bind(this)

    }

    arrayMeses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];


    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_DOMICILIOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        this.loadDomiciliosList();
    }

    loadDomiciliosList() {
        ElementsDataService.retrieveAllElements(`domicilios`)
            .then(
                response => {
                    this.setState({ elements: this.sortByKeyArray('calle', response.data) })
                    ElementsDataService.retrieveAllElements("clientes")
                        .then(
                            response => {
                                //console.log("retrieveAllClientesResponse",response.data);
                                this.setState({ allClientes: response.data, loading: false })
                            })

                })

        ElementsDataService.retrieveAllElements("sociedades-selector")
            .then(
                response => {
                    // console.log("retrieveAllSociedadesResponse",response.data);
                    this.setState({ allSociedades: response.data })
                })

    }


    getGastosByPeriodoEjercicio(ejercicio = this.state.ejercicio, periodo = this.state.periodo) {

        this.setState({ loading: true })


        ElementsDataService.retrieveAllElements(`domicilios/${ejercicio}/${periodo}/${this.state.busqueda.toUpperCase() ? this.state.busqueda.toUpperCase() : 'all-todos'}`)
            .then(
                response => {
                    this.setState({ elements: this.sortByKeyArray('calle', response.data), periodo, ejercicio, loading: false })
                })

    }

    goBusqueda() {

        this.setState({
            loading: true,
            uncollapseRows: true,
        })


        if (this.state.busqueda.toUpperCase().length > 2) {
            ElementsDataService.retrieveAllElements(`domicilios/${this.state.ejercicio}/${this.state.periodo}/${this.state.busqueda.toUpperCase()}`)
                .then(
                    response => {
                        console.log("retrieveBusqueda", response.data);
                        if (response.data.length == 0) {
                            alert("Esta búsqueda no arroja resultados.")
                        }
                        this.setState({ elements: this.sortByKeyArray('calle', response.data), loading: false })
                    }
                )
        } else {
            if (this.state.modoReporte) {
                ElementsDataService.retrieveAllElements(`domicilios/${this.state.ejercicio}/${this.state.periodo}/all-todos`)
                    .then(
                        response => {
                            this.setState({ elements: this.sortByKeyArray('calle', response.data), loading: false });
                        })
            } else {
                alert("Debe escribir al menos tres caracteres para realizar una búsqueda.")
                this.setState({ elements: [], loading: false })
            }
        }

    }

    direccionASC = true;
    actualKey = "";

    compareBy(key, ascending) {
        let reverse = ascending ? 1 : -1;
        return function (a, b) {

            if (a[key] !== 0 && !a[key]) {
                return 1;
            }
            else if (b[key] !== 0 && !b[key]) {
                return -1;
            }

            if (a[key].toString().trim() < b[key].toString().trim()) return -1 * reverse;
            if (a[key].toString().trim() > b[key].toString().trim()) return 1 * reverse;
            return 0;
        };
    }

    sortBy(key) {

        if (!this.actualKey) {
            this.actualKey = key;
            this.direccionASC = 1;
        } else if (this.actualKey != key) {
            this.direccionASC = 1;
            this.actualKey = key;
        } else {
            this.direccionASC = !this.direccionASC;
        }

        let arrayCopy = [...this.state.elements];

        arrayCopy.sort(this.compareBy(key, this.direccionASC));

        this.setState({ elements: arrayCopy });
    }


    sortByKeyArray(key, array) {

        let arrayCopy = [...array];

        arrayCopy.sort(this.compareBy(key, 1));

        return arrayCopy;
    }

    render() {

        let counter = 0;

        let totalRentas = 0;
        let totalRentasRecibo = 0;
        let totalPeriodo = 0;
        let totalAnual = 0;
        let totalSociedades = 0;
        let totalDomiciliosControl = 0;
        let sociedadesDomiciliosControl = 0;


        let { cliente,
            allClientes, elements, ejercicio, periodo, loading,
            estado, municipio, colonia, calle, status, tipo, mostrarInactivas,
            mostrarSociedadesFinalizadas, allSociedades, sociedadSeleccionada, control, modoReporte, uncollapseRows } = this.state


        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {


            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }


            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };

        // console.log("render DomicilioComponent", this.state)



        //Get Estados List
        let estados = []
        elements && elements.map(element => element.estado ? element.estado.toString().trim() : "")
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
            .forEach(estado => estados.push(estado))

        //console.log(estados)

        //Get Municipios
        let municipios = []
        elements && elements.map(element => element.municipio ? element.municipio.toString().trim() : "")
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
            .forEach(municipio => municipios.push(municipio))

        //console.log(municipios)

        //Get Colonias List
        let colonias = []
        elements && elements.map(element => element.colonia ? element.colonia.toString().trim() : "")
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
            .forEach(colonia => colonias.push(colonia))

        //console.log(colonias)


        //Get calles List
        let calles = []
        elements && elements.map(element => element.calle ? element.calle.toString().trim() : "")
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
            .forEach(calle => calles.push(calle))

        //console.log(calles)


        //Get Estatus List
        let estatuses = ["ACTIVO", "INACTIVO", "SIN ESTATUS"]

        //Get Control List
        let controls = ["CONTROL", "NO CONTROL"]

        //console.log(estatuses)

        //Get Tipos List
        let tipos = ["DEL CLIENTE", "FISICO", "VIRTUAL"]

        //console.log(tipos)




        //Encabezados csv
        let csvStr = `sep=;\nNo;CALLE;No Ext - Int;COLONIA;MUNICIPIO;ESTADO;TELEFONO;ESTATUS;TIPO;SOCIEDADES;CLIENTES;COSTO RENTA;COSTO PERIODO;COSTO UNITARIO;COSTO ACUMULADO\n`

        // console.log(csvStr);



        let tableContent = elements &&
            elements
                .filter(element => (!estado || element.estado.toString().trim() === estado))
                .filter(element => (!municipio || element.municipio.toString().trim() === municipio))
                .filter(element => (!colonia || element.colonia.toString().trim() === colonia))
                .filter(element => (!calle || element.calle.toString().trim() === calle))
                .filter(element => (!status || element.estatus === status || (status === "SIN ESTATUS" && !element.estatus)))
                .filter(element => (!control || element.control === control))
                .filter(element => (!tipo || element.tipo === tipo))
                .filter(element => (element.estatus !== "INACTIVO" || mostrarInactivas))
                .map(
                    element => {

                        if (cliente || sociedadSeleccionada) {
                            let isOfClienteflag = false;
                            element.sociedades.forEach(sociedad => {
                                if (sociedad.clientesIds.includes(cliente.id)) {
                                    isOfClienteflag = true
                                }

                                if (sociedad.id === sociedadSeleccionada.id) {
                                    isOfClienteflag = true
                                }
                            })

                            if (!isOfClienteflag) {
                                return
                            }
                        }


                        csvStr += `${++counter};${element.calle};${element.numeroExt}${element.numeroInterior && " int. " + element.numeroInterior};`
                        csvStr += `${element.colonia};${element.municipio};${element.estado};${element.telefono};${element.estatus};${element.tipo};`

                        element.sociedades && element.sociedades.slice(0, 1).forEach(sociedad => {
                            csvStr += `${sociedad.razonSocial};`

                            sociedad.clientesIds && sociedad.clientesIds.forEach(clienteId => {
                                let cliente = allClientes.find(x => x.id === clienteId)
                                csvStr += `${cliente ? cliente.nombreCliente + " " : ""}`
                            })


                        })
                        csvStr += `;${element.costoArrendamiento};${element.costoPeriodo};${element.costoPeriodo / 5};${element.costoAcumuladoAnual}\n`

                        element.sociedades && element.sociedades.slice(1).forEach(sociedad => {
                            csvStr += `;;;;;;;;;${sociedad.razonSocial};`

                            sociedad.clientesIds && sociedad.clientesIds.forEach(clienteId => {
                                let cliente = allClientes.find(x => x.id === clienteId)
                                csvStr += `${cliente ? cliente.nombreCliente + " " : ""}`
                            })
                            csvStr += `\n`

                        })


                        if (element.costoArrendamiento) {
                            totalRentas += element.costoArrendamiento;
                        }

                        if (element.costoArrendamientoRecibo) {
                            totalRentasRecibo += element.costoArrendamientoRecibo;
                        }


                        if (element.costoPeriodo) {
                            totalPeriodo += element.costoPeriodo;
                        }

                        if (element.costoAcumuladoAnual) {
                            totalAnual += element.costoAcumuladoAnual;
                        }

                        let sociedadesNoFinalizadas = element.sociedades && element.sociedades.filter(sociedad => !sociedad.fechaFin);

                        let sociedadesFinalizadas = element.sociedades && element.sociedades.filter(sociedad => sociedad.fechaFin);

                        if (sociedadesNoFinalizadas) {
                            element.sociedadesCount = sociedadesNoFinalizadas.length;
                        }

                        if (sociedadesFinalizadas) {
                            element.sociedadesFinalizadasCount = sociedadesFinalizadas.length;
                        }

                        if (sociedadesNoFinalizadas) {
                            totalSociedades += sociedadesNoFinalizadas.length;
                            if (mostrarSociedadesFinalizadas && sociedadesFinalizadas) {
                                totalSociedades += sociedadesFinalizadas.length;
                            }
                        }

                        if (element.control == "CONTROL") {
                            totalDomiciliosControl++;

                            sociedadesDomiciliosControl += element.sociedadesCount;

                        }


                        //console.log("element.sociedadesCount", element.sociedadesCount)

                        return <tr key={counter}>
                            <td>{counter}</td>
                            <td><Link to={`/domicilios/reportes/${element.id}`}>{element.calle} </Link></td>
                            <td className="right"><Link to={`/domicilios/reportes/${element.id}`}>{element.numeroExt} {element.numeroInterior && "- " + element.numeroInterior} </Link></td>
                            <td>{element.colonia}</td>
                            <td>{element.municipio}</td>
                            <td>{element.estado}</td>
                            <td>{element.telefono}</td>
                            <td>{element.estatus}</td>
                            <td>{element.control}</td>
                            <td>{element.tipo}</td>
                            {uncollapseRows && <td><h2>Sociedades Activas ({element.sociedadesCount}): </h2><br />
                                {sociedadesNoFinalizadas && sociedadesNoFinalizadas.map(sociedad =>
                                    <li><Link to={`/sociedades/reportes/${sociedad.id}`}>{sociedad.razonSocial}</Link>
                                        <br />Fecha Inicio: {sociedad.fechaInicio}
                                        <br />Fecha Fin: {sociedad.fechaFin}
                                        <br />Proyecto:
                                        {sociedad.clientesIds && sociedad.clientesIds.map(clienteId => {
                                            let cliente = allClientes.find(x => x.id === clienteId)
                                            return <span>{cliente ? cliente.nombreCliente : ""}</span>
                                        })}<br /> <br /></li>
                                )}
                                {mostrarSociedadesFinalizadas && <p> <h2>Sociedades Finalizadas ({element.sociedadesFinalizadasCount}):</h2><br />
                                    {sociedadesFinalizadas && sociedadesFinalizadas.map(sociedad =>
                                        <li><Link to={`/sociedades/reportes/${sociedad.id}`}>{sociedad.razonSocial}</Link>
                                            <br />Fecha Inicio: {sociedad.fechaInicio}
                                            <br />Fecha Fin: {sociedad.fechaFin}
                                            <br />Proyecto:
                                            {sociedad.clientesIds && sociedad.clientesIds.map(clienteId => {
                                                let cliente = allClientes.find(x => x.id === clienteId)
                                                return <span>{cliente ? cliente.nombreCliente : ""}</span>
                                            })}<br /> <br /></li>
                                    )}
                                </p>
                                }
                            </td>}
                            {uncollapseRows && <td>{formatMoney(element.costoArrendamiento)}</td>}
                            {uncollapseRows && <td>{formatMoney(element.costoArrendamiento)}</td>}
                            {uncollapseRows && <td>{formatMoney(element.costoPeriodo)}</td>}
                            {uncollapseRows && <td>{formatMoney(element.costoPeriodo / 5)}</td>}
                            {uncollapseRows && <td>{formatMoney(element.costoAcumuladoAnual)}</td>}
                            {AuthenticationService.haveAuthorization("WRITE_DOMICILIOS") && <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>}
                            {AuthenticationService.haveAuthorization("DELETE_DOMICILIOS") &&
                                <td className="icoSize">
                                    <button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}>
                                        <span className="icon-trash"></span>
                                    </button>
                                </td>}

                        </tr>
                    })


        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">

                        {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                        <div className="toolSearch">

                            <span className="icon-search"></span>

                            <TextField className="form-control"
                                value={this.state.busqueda}
                                label="Busqueda"
                                placeholder="Buscar"
                                name="busqueda"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        this.setState({
                                            mostrarInactivas: true
                                        }, this.goBusqueda())
                                    }
                                }}
                                onChange={event => {
                                    const { value } = event.target;

                                    this.setState({ busqueda: value.toUpperCase(), elements: [] });

                                    if (!value) {

                                        this.setState({
                                            loading: true, mostrarInactivas: false,
                                            mostrarSociedadesFinalizadas: false
                                        })

                                        ElementsDataService.retrieveAllElements(`domicilios/${this.state.ejercicio}/${this.state.periodo}/all-todos`)
                                            .then(
                                                response => {
                                                    this.setState({ elements: this.sortByKeyArray('calle', response.data), loading: false })
                                                })
                                    }
                                }}
                            />
                            <div className="tool-btn-agregar float-right" >
                                <button className="btn btn-agregar" onClick={() => {
                                    this.setState({
                                        mostrarInactivas: true
                                    }, this.goBusqueda)
                                }
                                }><AiOutlineFileSearch /></button>
                            </div>
                        </div>
                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button className="btn btn-agregar" onClick={this.props.addElementClicked}><span className="icon-add-solid"> </span><span className="icon-location"></span>
                                    &nbsp;Agregar Domicilio</button>
                            </div>
                            <h1>Domicilios &nbsp;</h1>
                            {!modoReporte &&
                                <div className="tool-btn-agregar" >
                                    <button className="btn btn-success" onClick={() => {
                                        this.setState({ modoReporte: true }, this.goBusqueda)
                                    }}><AiOutlineFileSearch />Modo Reporte</button>
                                </div>}
                            {modoReporte &&
                                <div className="tool-btn-agregar" >
                                    <button className="btn btn-success" onClick={() => {
                                        this.setState({
                                            modoReporte: false, uncollapseRows: false, busqueda: ""
                                        }, this.loadDomiciliosList)
                                    }}><AiOutlineFileSearch />Modo Lista</button>
                                </div>}
                        </div>
                        {modoReporte &&
                            <div>
                                <div className="cols">

                                    <div className="col4">
                                        <Autocomplete
                                            id="cliente"
                                            options={allClientes}
                                            getOptionLabel={(option) => option.nombreCliente}

                                            filterSelectedOptions
                                            onChange={(e, value) => {
                                                console.log("Cliente value", value);

                                                this.setState({ cliente: value })


                                            }}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Proyecto"
                                                    placeholder=""
                                                    name="cliente"
                                                />
                                            )}
                                        /> </div>

                                    <div className="col4">
                                        <FormControl className="form-control">
                                            <label htmlFor=""><span className="icon-calendar"></span> Ejercicio:</label>
                                            <input type="number" min="1900" max="2099" step="1" value={ejercicio}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.setState({ ejercicio: value })
                                                    if (value > 1900) {
                                                        this.getGastosByPeriodoEjercicio(value);
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                    </div>
                                    &nbsp;
                                    <div className="col4">
                                        <FormControl className="form-control">
                                            <InputLabel>Periodo</InputLabel>
                                            <Select

                                                value={periodo}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.getGastosByPeriodoEjercicio(ejercicio, value);
                                                }}

                                            >
                                                <option aria-label="None" value="" />
                                                <option value={"Enero"}>Enero</option>
                                                <option value={"Febrero"}>Febrero</option>
                                                <option value={"Marzo"}>Marzo</option>
                                                <option value={"Abril"}>Abril</option>
                                                <option value={"Mayo"}>Mayo</option>
                                                <option value={"Junio"}>Junio</option>
                                                <option value={"Julio"}>Julio</option>
                                                <option value={"Agosto"}>Agosto</option>
                                                <option value={"Septiembre"}>Septiembre</option>
                                                <option value={"Octubre"}>Octubre</option>
                                                <option value={"Noviembre"}>Noviembre</option>
                                                <option value={"Diciembre"}>Diciembre</option>

                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col4">
                                        <div className="tool-btn-agregar">
                                            <button type="button" className="btn btn-success" onClick={() => {


                                                console.log("Descargando Archivo")
                                                const element = document.createElement("a");
                                                const file = new Blob([csvStr], { type: 'text/plain' });
                                                element.href = URL.createObjectURL(file);
                                                var d = new Date();
                                                element.download = "Reporte Domicilios" + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                                document.body.appendChild(element); // Required for this to work in FireFox
                                                element.click();


                                            }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>
                                                &nbsp; Descargar Reporte</button>
                                        </div>

                                    </div>
                                </div>
                            </div>}
                        <div className="cols">
                            <div className="col4">
                                <Autocomplete
                                    id="Estado"
                                    options={estados}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log("Estado value", value);
                                        this.setState({ estado: value })

                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Estado"
                                            placeholder=""
                                            name="Estado"
                                        />
                                    )}
                                />
                            </div>


                            <div className="col4">
                                <Autocomplete
                                    id="municipio"
                                    options={municipios}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log("municipio value", value);
                                        this.setState({ municipio: value })

                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Municipio"
                                            placeholder=""
                                            name="municipio"
                                        />
                                    )}
                                />
                            </div>


                            <div className="col4">
                                <Autocomplete
                                    id="colonia"
                                    options={colonias}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log("colonia value", value);
                                        this.setState({ colonia: value })

                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Colonia"
                                            placeholder=""
                                            name="colonia"
                                        />
                                    )}
                                />
                            </div>

                            <div className="col4">
                                <Autocomplete
                                    id="calle"
                                    options={calles}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log("calle value", value);
                                        this.setState({ calle: value })

                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Calle"
                                            placeholder=""
                                            name="calle"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="cols">
                            {modoReporte &&
                                <div className="col4">
                                    <Autocomplete
                                        id="sociedadesSelector"
                                        options={allSociedades}
                                        getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            console.log(value);
                                            this.setState({ sociedadSeleccionada: value })

                                        }}
                                        getOptionSelected={(option, value) => option.rfc === value.rfc}
                                        //value={this.getSelectedItem()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Sociedades"
                                                placeholder=""
                                                name="sociedades"
                                            />
                                        )}
                                    />
                                </div>}
                            <div className="col4">
                                <Autocomplete
                                    id="status"
                                    options={estatuses}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        if (value === 'INACTIVO') {
                                            this.setState({ status: value, mostrarInactivas: true })
                                        } else {
                                            this.setState({ status: value, mostrarInactivas: false })
                                        }
                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Estatus"
                                            placeholder=""
                                            name="status"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col4">
                                <Autocomplete
                                    id="control"
                                    options={controls}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log("Control value", value);
                                        this.setState({ control: value })

                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Control"
                                            placeholder=""
                                            name="control"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col4">
                                <Autocomplete
                                    id="tipo"
                                    options={tipos}
                                    getOptionLabel={(option) => option}

                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log("tipo value", value);
                                        this.setState({ tipo: value })

                                    }}
                                    getOptionSelected={(option, value) => option === value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Tipo"
                                            placeholder=""
                                            name="tipo"
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div>
                            <h2>
                                {counter} Resultado{counter !== 1 ? "s" : ""}
                            </h2>
                        </div>
                        <div className="form-group">
                            <label>Mostrar Domicilios Inactivos</label>
                            <Checkbox
                                checked={mostrarInactivas}
                                color="primary"
                                onChange={event => {

                                    if (event.target.checked) {

                                        this.setState({
                                            mostrarInactivas: true
                                        });

                                    } else {
                                        this.setState({
                                            mostrarInactivas: false
                                        });
                                    }
                                }}
                            />
                        </div>
                        {uncollapseRows &&

                            <div className="form-group">
                                <label>Mostrar Sociedades Finalizadas</label>
                                <Checkbox
                                    checked={mostrarSociedadesFinalizadas}
                                    color="primary"
                                    onChange={event => {

                                        if (event.target.checked) {

                                            this.setState({
                                                mostrarSociedadesFinalizadas: true
                                            });

                                        } else {
                                            this.setState({
                                                mostrarSociedadesFinalizadas: false
                                            });
                                        }
                                    }}
                                />
                            </div>
                        }

                        <div id="tablesContainer">
                            {modoReporte &&
                                <div className="tableContainer">
                                    <div className="scrolled">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Totales</th><th>Cantidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Total Domicilios</td><td>{counter}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Domicilios Control</td><td>{totalDomiciliosControl}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Sociedades</td><td>{totalSociedades}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Sociedades en Domicilios de Control</td><td>{sociedadesDomiciliosControl}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Rentas Fijo</td><td>{formatMoney(totalRentas)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Rentas Capturadas</td><td>{formatMoney(totalRentasRecibo)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Gastos Periodo</td><td>{formatMoney(totalPeriodo)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Costo Unitario Promedio</td><td>{formatMoney(totalPeriodo / totalDomiciliosControl / 5)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Gastos Acumulado Anual</td><td>{formatMoney(totalAnual)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            <div className="tableContainer">
                                <div className="scrolled">
                                    <table className="table big-table">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th onClick={() => { this.sortBy('calle') }}>Calle</th>
                                                <th className="right">N. Ext - Int </th>
                                                <th onClick={() => { this.sortBy('colonia') }}>Colonia</th>
                                                <th onClick={() => { this.sortBy('municipio') }}>Municipio</th>
                                                <th onClick={() => { this.sortBy('estado') }}>Estado</th>
                                                <th>Telefono</th>
                                                <th onClick={() => { this.sortBy('estatus') }}>Estatus</th>
                                                <th onClick={() => { this.sortBy('control') }}>Control</th>
                                                <th onClick={() => { this.sortBy('tipo') }}>Tipo</th>

                                                {uncollapseRows && <th onClick={() => { this.sortBy('sociedadesCount') }}>Sociedades</th>}
                                                {uncollapseRows && <th>Costo Renta Fijo</th>}
                                                {uncollapseRows && <th>Costo Renta Capturado</th>}
                                                {uncollapseRows && <th>Costo Periodo</th>}
                                                {uncollapseRows && <th>Costo Unitario</th>}
                                                {uncollapseRows && <th>Costo Acumulado Anual</th>}
                                                {AuthenticationService.haveAuthorization("WRITE_DOMICILIOS") && <th className="icoSize"><span className="icon-edit-pencil"></span></th>}
                                                {AuthenticationService.haveAuthorization("DELETE_DOMICILIOS") &&
                                                    <th className="icoSize"><span className="icon-trash"></span></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableContent}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingMask >
        )
    }
}

export default DomicilioComponent