import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CurrencyInput from 'react-currency-input-field';
import AuthenticationService from '../general/AuthenticationService.js'


class DomicilioFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            tipoVialidad: 'Calle',
            calle: '',
            colonia: '',
            cp: '',
            estado: '',
            municipio: '',
            numeroExt: '',
            numeroInterior: '',
            softDelete: '',
            telefono: '',
            estatus: '',
            control: '',
            tipo: '',
            tipoInmueble: '',
            referencias: '',
            especificaciones: '',
            entreCalle1: '',
            entreCalle2: '',
            contratoArrendamientoFile: '',
            comprobanteDomicilioFile: '',
            croquisFile: '',
            loading: false,
            resumenContrato: '',
            fechaVencimientoContrato: '',
            rentaMensual: '',
            notas: '',
            formaPago: '',
            noCuenta: '',
            arrendadorName: '',
            idArrendadorFile: '',
            propietarioIntermediario: '',
            documentoAcreditacionPosesionFile: '',
            fechaInactivo: '',
            fechaAlta: '',

        }

        this.onSubmit = this.onSubmit.bind(this)

    }

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("WRITE_DOMICILIOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("domicilios", this.state.id)
            .then(response => this.setState({
                calle: response.data.calle,
                tipoVialidad: response.data.tipoVialidad,
                colonia: response.data.colonia,
                cp: response.data.cp,
                estado: response.data.estado,
                municipio: response.data.municipio,
                numeroExt: response.data.numeroExt,
                numeroInterior: response.data.numeroInterior,
                telefono: response.data.telefono,
                estatus: response.data.estatus,
                control: response.data.control,
                tipo: response.data.tipo,
                tipoInmueble: response.data.tipoInmueble,
                referencias: response.data.referencias,
                especificaciones: response.data.especificaciones,
                entreCalle1: response.data.entreCalle1,
                entreCalle2: response.data.entreCalle2,
                contratoArrendamientoFile: response.data.contratoArrendamientoFileUrl,
                comprobanteDomicilioFile: response.data.comprobanteDomicilioFileUrl,
                croquisFile: response.data.croquisFileUrl,
                resumenContrato: response.data.resumenContrato,
                fechaVencimientoContrato: response.data.fechaVencimientoContrato,
                rentaMensual: response.data.rentaMensual,
                notas: response.data.notas,
                formaPago: response.data.formaPago,
                noCuenta: response.data.noCuenta,
                arrendadorName: response.data.arrendadorName,
                idArrendadorFile: response.data.idArrendadorFileURL,
                propietarioIntermediario: response.data.propietarioIntermediario,
                documentoAcreditacionPosesionFile: response.data.documentoAcreditacionPosesionURL,
                fechaInactivo: response.data.fechaInactivo,
                fechaAlta: response.data.fechaAlta,
            }))

    }


    onSubmit(values) {

        console.log("onSubmit")

        //Validation block
        //Validation block
        //Validation block
        let messageStr = ""

        if (!this.state.calle) {
            messageStr += "Debe escribir la calle.\n"
        }

        if (!this.state.numeroExt) {
            messageStr += "Debe escribir el numero exterior.\n"
        }

        if (!this.state.colonia) {
            messageStr += "Debe especificar la colonia.\n"
        }

        if (!this.state.cp) {
            messageStr += "Debe especificar el Codigo Postal.\n"
        }

        if (!this.state.municipio) {
            messageStr += "Debe introducir el Municipio.\n"
        }

        if (!this.state.estado) {
            messageStr += "Debe introducir el Estado.\n"
        }

        if (!this.state.estatus) {
            messageStr += "Debe especificar un Estatus.\n"
        }

        if (!this.state.control) {
            messageStr += "Debe especificar si el domicilio es de control.\n"
        }

        if (!this.state.tipo) {
            messageStr += "Debe especificar un Tipo.\n"
        }

        if (messageStr) {
            alert(messageStr)
            this.setState({ loading: false })
            return
        }

        //End Validation block

        this.setState({ loading: true })

        const formData = new FormData();
        formData.append('calle', this.state.calle.toUpperCase());
        formData.append('tipoVialidad', this.state.tipoVialidad);
        formData.append('numeroExt', this.state.numeroExt.toUpperCase());
        formData.append('numeroInterior', this.state.numeroInterior.toUpperCase());
        formData.append('municipio', this.state.municipio.toUpperCase());
        formData.append('estado', this.state.estado.toUpperCase());
        formData.append('colonia', this.state.colonia.toUpperCase());
        formData.append('cp', this.state.cp);
        formData.append('telefono', this.state.telefono);
        formData.append('estatus', this.state.estatus);
        formData.append('control', this.state.control);
        formData.append('tipo', this.state.tipo);
        formData.append('referencias', this.state.referencias.toUpperCase());
        formData.append('especificaciones', this.state.especificaciones.toUpperCase());
        formData.append('tipoInmueble', this.state.tipoInmueble.toUpperCase());
        formData.append('entreCalle1', this.state.entreCalle1.toUpperCase());
        formData.append('entreCalle2', this.state.entreCalle2.toUpperCase());
        formData.append('contratoArrendamientoFile', this.state.contratoArrendamientoFile);
        formData.append('comprobanteDomicilioFile', this.state.comprobanteDomicilioFile);
        formData.append('croquisFile', this.state.croquisFile);

        formData.append('resumenContrato', this.state.resumenContrato.toUpperCase());
        formData.append('fechaVencimientoContrato', this.state.fechaVencimientoContrato);
        formData.append('rentaMensual', this.state.rentaMensual);
        formData.append('notas', this.state.notas.toUpperCase());
        formData.append('formaPago', this.state.formaPago);
        formData.append('noCuenta', this.state.noCuenta.toUpperCase());
        formData.append('arrendadorName', this.state.arrendadorName.toUpperCase());

        formData.append('idArrendadorFile', this.state.idArrendadorFile);

        formData.append('propietarioIntermediario', this.state.propietarioIntermediario);
        formData.append('documentoAcreditacionPosesionFile', this.state.documentoAcreditacionPosesionFile);

        formData.append('fechaInactivo', this.state.fechaInactivo);
        formData.append('fechaAlta', this.state.fechaAlta);




        if (Number(this.state.id) === -1) {
            ElementsDataService.createElement("domicilios", formData)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "saved") {
                        this.props.history.push(`/domicilios/reportes/${response.data.id}`)
                    } else {
                        alert(response.data.message);
                        this.setState({ loading: false })
                    }
                })
        } else {
            ElementsDataService.updateElement("domicilios", this.state.id, formData)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "saved") {
                        this.props.history.push(`/domicilios/reportes/${response.data.id}`)
                    } else {
                        alert(response.data.message);
                        this.setState({ loading: false })
                    }
                })
        }

    }

    render() {

        console.log("render DomicilioFormularioComponent")
        console.log("DomicilioFormularioComponent state", this.state)
        let { id,
            tipoVialidad,
            calle,
            colonia,
            cp,
            estado,
            municipio,
            numeroExt,
            numeroInterior,
            telefono,
            tipo,
            tipoInmueble,
            estatus,
            control,
            especificaciones,
            referencias,
            entreCalle1,
            entreCalle2,
            contratoArrendamientoFile,
            comprobanteDomicilioFile,
            croquisFile,
            loading,
            resumenContrato,
            fechaVencimientoContrato,
            rentaMensual,
            notas,
            formaPago,
            noCuenta,
            arrendadorName,
            idArrendadorFile,
            propietarioIntermediario,
            documentoAcreditacionPosesionFile,
            fechaInactivo,
            fechaAlta,
        } = this.state


        let variant = "standard";//'filled' | 'outlined' | 'standard'

        return (
            <div id="main">
                <div className="panel">
                    <LoadingMask loading={loading} text={"loading..."}>
                        <div className="titles">

                            <h1>{calle ? calle.toUpperCase() + " " + numeroExt.toUpperCase() + (numeroInterior ? " - " + numeroInterior.toUpperCase() : "") : "Nuevo Domicilio"}</h1>
                        </div>
                        <br />
                        <div className="container">
                            <Formik
                                initialValues={{
                                    id,
                                    calle,
                                    colonia,
                                    cp,
                                    estado,
                                    municipio,
                                    numeroExt,
                                    numeroInterior,
                                    telefono,
                                    especificaciones,
                                    referencias,
                                    tipoInmueble,
                                    resumenContrato,
                                    fechaVencimientoContrato,
                                    rentaMensual,
                                    notas,
                                    formaPago,
                                    noCuenta,
                                    arrendadorName,
                                    idArrendadorFile,
                                    propietarioIntermediario,
                                    documentoAcreditacionPosesionFile,
                                    entreCalle1,
                                    entreCalle2,
                                    fechaInactivo,
                                    fechaAlta,
                                }}
                                onSubmit={this.onSubmit}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validate={this.validate}
                                enableReinitialize={true}
                            >
                                {
                                    (props) => (
                                        <Form autoComplete="off" >
                                            <div className="cols">
                                                <div className="col4">
                                                    <FormControl className="form-control">
                                                        <InputLabel >Tipo Vialidad</InputLabel>
                                                        <Select

                                                            value={tipoVialidad}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ tipoVialidad: value });
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"Calle"}>Calle</option>
                                                            <option value={"Avenida"}>Avenida</option>
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                                <div className="col4">
                                                    <TextField autoComplete="off"
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        className="form-control"
                                                        value={calle}
                                                        variant={variant}
                                                        label={tipoVialidad}
                                                        placeholder="Calle"
                                                        name="calle"
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ calle: value });
                                                        }}
                                                    />
                                                </div>

                                                <div className="col4">
                                                    <TextField autoComplete="off"
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        className="form-control"
                                                        value={numeroExt}
                                                        variant={variant}
                                                        label="Num Ext"
                                                        placeholder="Num Ext"
                                                        name="numeroExt"
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ numeroExt: value });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col4">
                                                    <TextField autoComplete="off"
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        className="form-control"
                                                        value={numeroInterior}
                                                        variant={variant}
                                                        label="Num Int"
                                                        placeholder="Num Int"
                                                        name="numeroInterior"
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ numeroInterior: value });
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                            <div className="cols">
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={entreCalle1}
                                                    variant={variant}
                                                    label="Entre Calle 1"
                                                    placeholder="Entre Calle 1"
                                                    name="entreCalle1"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ entreCalle1: value });
                                                    }}
                                                />
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={entreCalle2}
                                                    variant={variant}
                                                    label="Entre Calle 2"
                                                    placeholder="Entre Calle 2"
                                                    name="entreCalle2"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ entreCalle2: value });
                                                    }}
                                                />
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={colonia}
                                                    variant={variant}
                                                    label="Colonia"
                                                    placeholder="Colonia"
                                                    name="colonia"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ colonia: value });
                                                    }}
                                                />
                                            </div>
                                            <div className="cols">

                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={municipio}
                                                    variant={variant}
                                                    label="Municipio"
                                                    placeholder="Municipio"
                                                    name="municipio"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ municipio: value });
                                                    }}
                                                />
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={estado}
                                                    variant={variant}
                                                    label="Estado"
                                                    placeholder="Estado"
                                                    name="estado"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ estado: value });
                                                    }}
                                                />
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={cp}
                                                    variant={variant}
                                                    label="CP"
                                                    placeholder="CP"
                                                    name="cp"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ cp: value });
                                                    }}
                                                />
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={telefono}
                                                    variant={variant}
                                                    label="Telefono"
                                                    placeholder="Telefono"
                                                    name="telefono"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ telefono: value });
                                                    }}
                                                />


                                            </div>
                                            <div className="cols">
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={tipoInmueble}
                                                    variant={variant}
                                                    label="Tipo Inmueble"
                                                    placeholder="Tipo Inmueble"
                                                    name="tipoInmueble"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ tipoInmueble: value });
                                                    }}
                                                />

                                            </div>
                                            <div className="cols">
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={referencias}
                                                    variant={variant}
                                                    label="Referencias"
                                                    placeholder="Referencias"
                                                    name="referencias"
                                                    multiline
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ referencias: value });
                                                    }}
                                                />
                                            </div>
                                            <div className="cols">
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={especificaciones}
                                                    variant={variant}
                                                    label="Especificaciones"
                                                    placeholder="Especificaciones"
                                                    name="especificaciones"
                                                    multiline
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ especificaciones: value });
                                                    }}
                                                />
                                            </div>

                                            <div className="cols">

                                                <div className="col4">
                                                    <FormControl className="form-control">
                                                        <InputLabel >Estatus</InputLabel>
                                                        <Select

                                                            value={estatus}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ estatus: value });
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"ACTIVO"}>ACTIVO</option>
                                                            <option value={"INACTIVO"}>INACTIVO</option>
                                                        </Select>
                                                    </FormControl>
                                                    {(estatus === "INACTIVO") &&
                                                        <TextField className="form-control"
                                                            value={fechaInactivo}
                                                            id="date"
                                                            label="Fecha de Baja/inactivo"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ fechaInactivo: value });
                                                            }}
                                                        />}
                                                    {(estatus === "ACTIVO") &&
                                                        <TextField className="form-control"
                                                            value={fechaAlta}
                                                            id="date"
                                                            label="Fecha de Alta"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ fechaAlta: value });
                                                            }}
                                                        />}
                                                </div>
                                                <div className="col4">
                                                    <FormControl className="form-control">
                                                        <InputLabel >Domicilio de Control?</InputLabel>
                                                        <Select

                                                            value={control}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ control: value });
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"CONTROL"}>CONTROL</option>
                                                            <option value={"NO CONTROL"}>NO CONTROL</option>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="col4">
                                                    <FormControl className="form-control">
                                                        <InputLabel >Tipo</InputLabel>
                                                        <Select

                                                            value={tipo}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ tipo: value });
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"VIRTUAL"}>VIRTUAL</option>
                                                            <option value={"FISICO"}>FISICO</option>
                                                            <option value={"DEL CLIENTE"}>DEL CLIENTE</option>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={notas}
                                                    variant={variant}
                                                    label="Notas"
                                                    placeholder="Notas"
                                                    name="notas"
                                                    multiline
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ notas: value });
                                                    }}
                                                />
                                            </div>

                                            <div className="cols">
                                                <div className="col1">
                                                    <hr style={{ borderTop: "10px  solid" }}></hr>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <TextField autoComplete="off"
                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                    className="form-control"
                                                    value={resumenContrato}
                                                    variant={variant}
                                                    label="Resumen Contrato Arrendamiento"
                                                    placeholder="Resumen Contrato Arrendamiento"
                                                    name="resumenContrato"
                                                    multiline
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ resumenContrato: value });
                                                    }}
                                                />
                                            </div>

                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Contrato de Arrendamiento: {contratoArrendamientoFile ?
                                                            contratoArrendamientoFile.name ? "" : contratoArrendamientoFile : ""} </label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "contratoArrendamientoFile": event.currentTarget.files[0] });
                                                            //console.log("contratoArrendamientoFile",contratoArrendamientoFile)
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <div className="col2">

                                                    <TextField className="form-control"
                                                        value={fechaVencimientoContrato}
                                                        id="date"
                                                        label="Vigencia Contrato"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ fechaVencimientoContrato: value });
                                                        }}
                                                    />
                                                </div>

                                                <div className="col2">
                                                    <InputLabel >Renta Mensual</InputLabel>
                                                    <CurrencyInput
                                                        id="rentaMensual"
                                                        name="rentaMensual"
                                                        disabled={(control === "NO CONTROL")}
                                                        prefix={'$'}
                                                        value={rentaMensual}
                                                        decimalsLimit={2}
                                                        onValueChange={(value) => this.setState({ rentaMensual: value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="cols">
                                                <div className="col2">
                                                    <FormControl className="form-control">
                                                        <InputLabel >Forma De Pago</InputLabel>
                                                        <Select

                                                            value={formaPago}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ formaPago: value });
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"EFECTIVO"}>EFECTIVO</option>
                                                            <option value={"TRANSFERENCIA"}>TRANSFERENCIA</option>
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                                <div className="col2">

                                                    <TextField autoComplete="off"
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        className="form-control"
                                                        value={noCuenta}
                                                        variant={variant}
                                                        label="Numero de Cuenta"
                                                        placeholder="Numero de Cuenta"
                                                        name="noCuenta"
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ noCuenta: value });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <div className="col1">
                                                    <hr style={{ borderTop: "10px  solid" }}></hr>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <div className="col2">
                                                    <TextField autoComplete="off"
                                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                                        className="form-control"
                                                        value={arrendadorName}
                                                        variant={variant}
                                                        label="Nombre Arrendador"
                                                        placeholder="Nombre Arrendador"
                                                        name="arrendadorName"
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ arrendadorName: value });
                                                        }}
                                                    />
                                                </div>

                                                <div className="col3">
                                                    <FormControl className="form-control">
                                                        <InputLabel >PROPIETARIO?</InputLabel>
                                                        <Select

                                                            value={propietarioIntermediario}
                                                            onChange={event => {
                                                                const { value } = event.target;
                                                                this.setState({ propietarioIntermediario: value });
                                                            }}

                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"PROPIETARIO"}>PROPIETARIO</option>
                                                            <option value={"INTERMEDIARIO"}>INTERMEDIARIO</option>
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                            </div>


                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Identificación Arrendador: {idArrendadorFile ?
                                                            idArrendadorFile.name ? "" : idArrendadorFile : ""} </label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "idArrendadorFile": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Documento que Acredita Posesión: {documentoAcreditacionPosesionFile ?
                                                            documentoAcreditacionPosesionFile.name ? "" : documentoAcreditacionPosesionFile : ""} </label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "documentoAcreditacionPosesionFile": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <div className="col1">
                                                    <hr style={{ borderTop: "10px  solid" }}></hr>
                                                </div>
                                            </div>


                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span>  Comprobante de Domicilio: {comprobanteDomicilioFile ?
                                                            comprobanteDomicilioFile.name ? "" : comprobanteDomicilioFile : ""} </label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "comprobanteDomicilioFile": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Croquis: {croquisFile ?
                                                            croquisFile.name ? "" : croquisFile : ""} </label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "croquisFile": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="buttons">
                                                <button className="btn btn-success" onClick={this.onSubmit} type="button" > <span className="icon-checkmark"></span> Guardar</button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>

                        </div>
                    </LoadingMask>
                </div>
            </div>
        )
    }
}

export default DomicilioFormularioComponent