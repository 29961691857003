import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { ImHome, ImPrinter } from 'react-icons/im';
import LoadingMask from "react-loadingmask";
import { Link } from 'react-router-dom';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js';
import AuthenticationService from '../general/AuthenticationService.js';

class DomicilioReporteComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            tipoVialidad: 'Calle',
            calle: '',
            colonia: '',
            cp: '',
            estado: '',
            municipio: '',
            numeroExt: '',
            numeroInterior: '',
            softDelete: '',
            telefono: '',
            estatus: '',
            control: '',
            tipo: '',
            notas: '',
            referencias: '',
            especificaciones: '',
            entreCalle1: '',
            entreCalle2: '',
            personasFisicas: [],
            sociedades: [],
            comprobanteDomicilioFileUrl: '',
            contratoArrendamientoFileUrl: '',
            croquisFileUrl: '',
            fotografiasFileUrl: '',
            listaSociedadesDocumentosPersonasFisicas: [],
            gastos: [],
            portadaFile: [],
            portadaData: '',
            fotografiasGalleryFileArray: [],
            fotografiasGalleryDataArray: [],
            loading: false,
            imageCount: '',
            ejercicio: new Date().getFullYear(),//año
            periodo: this.arrayMeses[new Date().getMonth()],//mes
            resumenContrato: '',
            fechaVencimientoContrato: '',
            rentaMensual: '',
            formaPago: '',
            noCuenta: '',
            arrendadorName: '',
            idArrendadorFileURL: '',
            propietarioIntermediario: '',
            documentoAcreditacionPosesionURL: '',
            fechaInactivo: '',
            loading: false,
            selectedSavedButtonId: null,
            currentHistorialSociedadDomicilioItem: null,

        }

        this.updateFecha = this.updateFecha.bind(this)


    }

    arrayMeses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];

    print() {
        window.print();
    }

    updateFecha() {

        this.setState({ loading: true });

        let id = this.state.currentHistorialSociedadDomicilioItem.id;

        ElementsDataService.updateElement("historia-sociedad-domicilio", id, this.state.currentHistorialSociedadDomicilioItem)
            .then(() => this.reloadDomicilioDataFromServer())

    }

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_DOMICILIOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("domicilios", this.state.id)
            .then(response => {
                console.log("response domicilios", response)
                this.setState({
                    calle: response.data.calle,
                    tipoVialidad: response.data.tipoVialidad,
                    colonia: response.data.colonia,
                    cp: response.data.cp,
                    estado: response.data.estado,
                    municipio: response.data.municipio,
                    numeroExt: response.data.numeroExt,
                    numeroInterior: response.data.numeroInterior,
                    telefono: response.data.telefono,
                    estatus: response.data.estatus,
                    control: response.data.control,
                    tipo: response.data.tipo,
                    notas: response.data.notas,
                    tipoInmueble: response.data.tipoInmueble,
                    referencias: response.data.referencias,
                    especificaciones: response.data.especificaciones,
                    entreCalle1: response.data.entreCalle1,
                    entreCalle2: response.data.entreCalle2,
                    personasFisicas: response.data.personasFisicas,
                    sociedades: response.data.sociedades,
                    comprobanteDomicilioFileUrl: response.data.comprobanteDomicilioFileUrl,
                    contratoArrendamientoFileUrl: response.data.contratoArrendamientoFileUrl,
                    croquisFileUrl: response.data.croquisFileUrl,
                    fotografiasFileUrl: response.data.fotografiasFileUrl,
                    listaSociedadesDocumentosPersonasFisicas: response.data.listaSociedadesDocumentosPersonasFisicas,
                    resumenContrato: response.data.resumenContrato,
                    fechaVencimientoContrato: response.data.fechaVencimientoContrato,
                    rentaMensual: response.data.rentaMensual,
                    formaPago: response.data.formaPago,
                    noCuenta: response.data.noCuenta,
                    arrendadorName: response.data.arrendadorName,
                    idArrendadorFileURL: response.data.idArrendadorFileURL,
                    propietarioIntermediario: response.data.propietarioIntermediario,
                    documentoAcreditacionPosesionURL: response.data.documentoAcreditacionPosesionURL,
                    fechaInactivo: response.data.fechaInactivo,


                })

            })

        //Retrieve gastos domicilio

        ElementsDataService.retrieveElement(`domicilios/gastos/${this.state.ejercicio}/${this.state.periodo}`, this.state.id)
            .then(response => {
                console.log("response", response)
                this.setState({ gastos: response.data })
            })

        ElementsDataService.retrieveElement("domicilios/galeria", this.state.id)
            .then(response => {

                console.log(response);

                let imageCount = response.data.imageCount;

                if (imageCount) {

                    this.setState({ imageCount: imageCount })

                    //portada

                    if (imageCount > 0) {

                        ElementsDataService.getImageFile(`?fileName=Domicilios/domicilio_${this.state.id}/galeria/0.jpg`)
                            .then(response => {

                                console.log(response);

                                this.setState({
                                    portadaFile: response,
                                    portadaData: URL.createObjectURL(response)
                                })

                            })

                    }

                    //all other images
                    if (imageCount > 1) {
                        for (let i = 1; i < imageCount; i++) {
                            ElementsDataService.getImageFile(`?fileName=Domicilios/domicilio_${this.state.id}/galeria/${i}.jpg`)
                                .then(response => {

                                    //console.log("image " + i + "received" + response);

                                    let fotografiasGalleryFileArray = this.state.fotografiasGalleryFileArray;

                                    let fotografiasGalleryDataArray = this.state.fotografiasGalleryDataArray;

                                    fotografiasGalleryFileArray[i - 1] = response;

                                    fotografiasGalleryDataArray[i - 1] = URL.createObjectURL(response)

                                    this.setState({
                                        fotografiasGalleryFileArray,
                                        fotografiasGalleryDataArray
                                    })

                                })
                        }
                    }



                }


            })




    }

    deleteElementClicked(id) {

        if (window.confirm("¿Eliminar Elemento?")) {
            ElementsDataService.deleteElement("domicilios/gastos", id)
                .then(
                    response => {
                        //Retrieve gastos domicilio

                        ElementsDataService.retrieveElement(`domicilios/gastos/${this.state.ejercicio}/${this.state.periodo}`, this.state.id)
                            .then(response => {
                                console.log("response", response)
                                this.setState({ gastos: response.data })
                            })
                    }
                )
        }


    }

    reloadDomicilioDataFromServer() {

        this.setState({ listaSociedadesDocumentosPersonasFisicas: null })

        ElementsDataService.retrieveElement("domicilios", this.state.id)
            .then(response => {
                //console.log("response domicilios", response)
                this.setState({
                    calle: response.data.calle,
                    tipoVialidad: response.data.tipoVialidad,
                    colonia: response.data.colonia,
                    cp: response.data.cp,
                    estado: response.data.estado,
                    municipio: response.data.municipio,
                    numeroExt: response.data.numeroExt,
                    numeroInterior: response.data.numeroInterior,
                    telefono: response.data.telefono,
                    estatus: response.data.estatus,
                    control: response.data.control,
                    tipo: response.data.tipo,
                    notas: response.data.notas,
                    tipoInmueble: response.data.tipoInmueble,
                    referencias: response.data.referencias,
                    especificaciones: response.data.especificaciones,
                    entreCalle1: response.data.entreCalle1,
                    entreCalle2: response.data.entreCalle2,
                    personasFisicas: response.data.personasFisicas,
                    sociedades: response.data.sociedades,
                    comprobanteDomicilioFileUrl: response.data.comprobanteDomicilioFileUrl,
                    contratoArrendamientoFileUrl: response.data.contratoArrendamientoFileUrl,
                    croquisFileUrl: response.data.croquisFileUrl,
                    fotografiasFileUrl: response.data.fotografiasFileUrl,
                    listaSociedadesDocumentosPersonasFisicas: response.data.listaSociedadesDocumentosPersonasFisicas,
                    resumenContrato: response.data.resumenContrato,
                    fechaVencimientoContrato: response.data.fechaVencimientoContrato,
                    rentaMensual: response.data.rentaMensual,
                    formaPago: response.data.formaPago,
                    noCuenta: response.data.noCuenta,
                    arrendadorName: response.data.arrendadorName,
                    idArrendadorFileURL: response.data.idArrendadorFileURL,
                    propietarioIntermediario: response.data.propietarioIntermediario,
                    documentoAcreditacionPosesionURL: response.data.documentoAcreditacionPosesionURL,
                    fechaInactivo: response.data.fechaInactivo,
                    loading: false,
                    selectedSavedButtonId: null,
                    currentHistorialSociedadDomicilioItem: null,

                })

            })

    }

    getGastosByPeriodoEjercicio(ejercicio = this.state.ejercicio, periodo = this.state.periodo) {

        ElementsDataService.retrieveElement(`domicilios/gastos/${ejercicio}/${periodo}`, this.state.id)
            .then(response => {
                console.log("response", response)
                this.setState({ ejercicio: ejercicio, periodo: periodo, gastos: response.data })
            })

    }






    render() {

        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {

            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }

            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };

        console.log("render DomicilioReporteComponent")
        console.log("DomicilioReporteComponent state", this.state)


        let { id,
            tipoVialidad,
            calle,
            colonia,
            cp,
            estado,
            municipio,
            numeroExt,
            numeroInterior,
            telefono,
            tipo,
            notas,
            tipoInmueble,
            estatus,
            control,
            especificaciones,
            referencias,
            entreCalle1,
            entreCalle2,
            sociedades,
            personasFisicas,
            comprobanteDomicilioFileUrl,
            contratoArrendamientoFileUrl,
            croquisFileUrl,
            fotografiasFileUrl,
            listaSociedadesDocumentosPersonasFisicas,
            gastos,
            portadaData,
            loading,
            fotografiasGalleryDataArray,
            periodo,
            ejercicio,
            resumenContrato,
            fechaVencimientoContrato,
            rentaMensual,
            formaPago,
            noCuenta,
            arrendadorName,
            idArrendadorFileURL,
            propietarioIntermediario,
            documentoAcreditacionPosesionURL,
            fechaInactivo,
            selectedSavedButtonId,
        } = this.state


        const listaPersonasFisicas = listaSociedadesDocumentosPersonasFisicas ? listaSociedadesDocumentosPersonasFisicas.map((sociedad) => {
            return (<tr>

                <td>
                    {sociedad.documentos.map(function (documento) {
                        return (documento.personasFisicas.map(function (personaFisica) {
                            return (<p><Link to={`/personas-fisicas/reportes/${personaFisica.personaFisicaId}`}>
                                {personaFisica.personaFisicaNombre}</Link></p>)
                        })
                        );
                    })}
                </td>

                <td><Link to={`/sociedades/reportes/${sociedad.sociedadId}`}>{sociedad.razonSocialSociedad}</Link></td>

            </tr>)

        }) : [];



        const listaGastos = gastos.map((gasto) => <tr>
            <td>{gasto.tipoGasto}</td>
            <td>{formatMoney(gasto.cantidad)}</td>
            <td>{gasto.fecha}</td>
            <td>{gasto.notas}</td>
            <td className="no-print" >{gasto.reciboFileUrl ? <button type="button" className="btn btn-success" onClick={() => {
                console.log("Descargando Archivo")

                ElementsDataService.downloadS3File(`?fileName=${gasto.reciboFileUrl}`,
                    `RECIBO_${gasto.tipoGasto}_${calle}_${numeroExt}_${numeroInterior}_${colonia}_${gasto.fecha}.pdf`)

            }}><span className="icon-download"></span> Descargar</button>
                : "No hay un archivo cargado todavía"}</td>
            <td className="no-print icoSize"><button className="btn btn-edit"
                onClick={() => this.props.history.push(`/domicilios/gastos/${id}?gasto=${gasto.id}`)}>
                <span className="icon-edit-pencil"></span>    </button></td>
            <td className="no-print icoSize"><button className="btn btn-delete"
                onClick={() => this.deleteElementClicked(gasto.id)}>
                <span className="icon-trash"></span></button></td>
        </tr>
        );

        //console.log("listaSociedadesDocumentosPersonasFisicas",listaSociedadesDocumentosPersonasFisicas);

        const listaSociedadesActuales = listaSociedadesDocumentosPersonasFisicas ? listaSociedadesDocumentosPersonasFisicas
            .filter(sociedad => !(sociedad.historialsociedadesDomicilios[0] && sociedad.historialsociedadesDomicilios[0].fechaFin))
            .map((sociedad) => <tr> <td>
                <Link to={`/sociedades/reportes/${sociedad.sociedadId}`}>
                    {sociedad.razonSocialSociedad}
                </Link>
            </td>
                <td>
                    {sociedad.statusLegal}
                </td>
                <td>
                    {sociedad.clientes.map(cliente => <p>{cliente.nombreCliente}</p>)}
                </td>

                <td>{sociedad.historialsociedadesDomicilios.map(historiaItem =>
                    <div>
                        <TextField className="form-control"
                            defaultValue={historiaItem.fechaInicio}
                            id="date"
                            label="Desde"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={event => {
                                const { value } = event.target;
                                console.log(value)

                                console.log("selectedSavedButtonId", selectedSavedButtonId)

                                let historialSociedadDomicilioItem = {
                                    id: historiaItem.id,
                                    sociedadId: historiaItem.sociedadId,
                                    domicilioId: historiaItem.domicilioId,
                                    fechaInicio: value,
                                    fechaFin: historiaItem.fechaFin,
                                }


                                this.setState({
                                    selectedSavedButtonId: `boton_${historiaItem.id}_desde`,
                                    currentHistorialSociedadDomicilioItem: historialSociedadDomicilioItem
                                })

                            }}
                        />
                        {selectedSavedButtonId === `boton_${historiaItem.id}_desde` &&
                            <button onClick={this.updateFecha}>Guardar</button>}
                    </div>)}</td>



                <td>
                    {sociedad.historialsociedadesDomicilios.map(historiaItem =>
                        <div>
                            <TextField className="form-control"
                                defaultValue={historiaItem.fechaFin}
                                id="date"
                                label="Hasta"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => {
                                    const { value } = event.target;
                                    console.log(value)

                                    console.log("selectedSavedButtonId", selectedSavedButtonId)

                                    let historialSociedadDomicilioItem = {
                                        id: historiaItem.id,
                                        sociedadId: historiaItem.sociedadId,
                                        domicilioId: historiaItem.domicilioId,
                                        fechaInicio: historiaItem.fechaInicio,
                                        fechaFin: value,
                                    }

                                    this.setState({
                                        selectedSavedButtonId: `boton_${historiaItem.id}_hasta`,
                                        currentHistorialSociedadDomicilioItem: historialSociedadDomicilioItem
                                    })



                                }}
                            />
                            {selectedSavedButtonId === `boton_${historiaItem.id}_hasta` &&
                                <button onClick={this.updateFecha}>Guardar</button>}
                        </div>)}
                </td>

            </tr>) : [];


        const listaSociedadesAnteriores = listaSociedadesDocumentosPersonasFisicas ? listaSociedadesDocumentosPersonasFisicas
            .filter(sociedad => (sociedad.historialsociedadesDomicilios[0] && sociedad.historialsociedadesDomicilios[0].fechaFin))
            .map((sociedad) => <tr> <td>
                <Link to={`/sociedades/reportes/${sociedad.sociedadId}`}>
                    {sociedad.razonSocialSociedad}
                </Link>
            </td>
                <td>
                    {sociedad.statusLegal}
                </td>
                <td>
                    {sociedad.clientes.map(cliente => <p>{cliente.nombreCliente}</p>)}
                </td>

                <td>{sociedad.historialsociedadesDomicilios.map(historiaItem =>
                    <div>
                        <TextField key={historiaItem.id}
                            className="form-control"
                            defaultValue={historiaItem.fechaInicio}
                            id="date"
                            label="Desde"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={event => {
                                const { value } = event.target;
                                console.log(value)

                                console.log("selectedSavedButtonId", selectedSavedButtonId)

                                let historialSociedadDomicilioItem = {
                                    id: historiaItem.id,
                                    sociedadId: historiaItem.sociedadId,
                                    domicilioId: historiaItem.domicilioId,
                                    fechaInicio: value,
                                    fechaFin: historiaItem.fechaFin,
                                }

                                this.setState({
                                    selectedSavedButtonId: `boton_${historiaItem.id}_desde`,
                                    currentHistorialSociedadDomicilioItem: historialSociedadDomicilioItem
                                })

                            }}
                        />
                        {selectedSavedButtonId === `boton_${historiaItem.id}_desde` &&
                            <button onClick={this.updateFecha}>Guardar</button>}
                    </div>)}</td>



                <td>
                    {sociedad.historialsociedadesDomicilios.map(historiaItem =>
                        <div>
                            <TextField key={historiaItem.id}
                                className="form-control"
                                defaultValue={historiaItem.fechaFin}
                                id="date"
                                label="Hasta"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => {
                                    const { value } = event.target;
                                    console.log(value)

                                    console.log("selectedSavedButtonId", selectedSavedButtonId)

                                    let historialSociedadDomicilioItem = {
                                        id: historiaItem.id,
                                        sociedadId: historiaItem.sociedadId,
                                        domicilioId: historiaItem.domicilioId,
                                        fechaInicio: historiaItem.fechaInicio,
                                        fechaFin: value,
                                    }

                                    this.setState({
                                        selectedSavedButtonId: `boton_${historiaItem.id}_hasta`,
                                        currentHistorialSociedadDomicilioItem: historialSociedadDomicilioItem
                                    })

                                }}
                            />
                            {selectedSavedButtonId === `boton_${historiaItem.id}_hasta` &&
                                <button onClick={this.updateFecha}>Guardar</button>}
                        </div>)}
                </td>

            </tr>) : [];

        return (

            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <button className="no-print btn btn-imprimir" onClick={this.print}><ImPrinter /> Imprimir</button>
                        <button className="no-print btn-edit btn btn-imprimir" onClick={() => this.props.history.push(`/domicilios`)}> <ImHome /> </button>

                        <div className="titles">
                            <div className="no-print tool-btn-agregar">
                                <button className="btn btn-edit" onClick={() => this.props.history.push(`/domicilios/${id}`)}>
                                    <span className="icon-edit-pencil"></span> <span className="icon-location"></span>  </button>
                            </div>

                            <h1>Reporte Domicilio</h1>

                        </div>




                        <div className="container">

                            <table className="table simple">
                                <tr>
                                    <td>{tipoVialidad ? tipoVialidad : "Calle"}</td><td>{calle}</td>
                                </tr>
                                <tr>
                                    <td>Número Exterior</td><td>{numeroExt}</td>
                                </tr>
                                <tr>
                                    <td>Número Interior</td><td>{numeroInterior}</td>
                                </tr>
                                <tr>
                                    <td>Colonia</td><td>{colonia}</td>
                                </tr>
                                <tr>
                                    <td>Municipio</td><td>{municipio}</td>
                                </tr>
                                <tr>
                                    <td>Entre Calles</td><td>{entreCalle1 ? entreCalle1 != "null" ? entreCalle1 : "-" : "-"} y {entreCalle2 ? entreCalle2 != "null" ? entreCalle2 : "-" : "-"}</td>
                                </tr>
                                <tr>
                                    <td>Tipo de Inmueble</td><td>{tipoInmueble ? tipoInmueble != "null" ? tipoInmueble : "-" : "-"}</td>
                                </tr>
                                <tr>
                                    <td>Referencias</td><td style={{ whiteSpace: "pre-wrap" }}>{referencias ? referencias != "null" ? referencias : "-" : "-"}</td>
                                </tr>
                                <tr>
                                    <td>Especificaciones</td><td style={{ whiteSpace: "pre-wrap" }}>{especificaciones ? especificaciones != "null" ? especificaciones : "-" : "-"}</td>
                                </tr>
                                <tr>
                                    <td>Estado</td><td>{estado}</td>
                                </tr>
                                <tr>
                                    <td>Código Postal</td><td>{cp}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono</td><td>{telefono}</td>
                                </tr>
                                <tr>
                                    <td>Estatus</td><td>{estatus} {estatus === "INACTIVO" && fechaInactivo && `(${fechaInactivo})`}</td>
                                </tr>
                                <tr>
                                    <td>Control</td><td>{control}</td>
                                </tr>
                                <tr>
                                    <td>Tipo</td><td>{tipo}</td>
                                </tr>
                                <tr>
                                    <td>Notas</td><td style={{ whiteSpace: "pre-wrap" }}>{notas}</td>
                                </tr>
                                <tr className="no-print" >
                                    <td>Sociedades Actuales({listaSociedadesActuales.length}):</td><td>
                                        {listaSociedadesActuales.length > 0 && <table>
                                            <tr><th>Sociedad</th><th>Estatus Legal</th><th>Cliente</th><th>Desde:</th><th>Hasta: &nbsp;</th></tr>
                                            {listaSociedadesActuales}
                                        </table>}

                                    </td>
                                </tr>
                                <tr className="no-print" >
                                    <td>Sociedades Anteriores ({listaSociedadesAnteriores.length}):</td><td>
                                        {listaSociedadesAnteriores.length > 0 && <table>
                                            <tr><th>Sociedad</th><th>Estatus Legal</th><th>Cliente</th><th>Desde:</th><th>Hasta: &nbsp;</th></tr>
                                            {listaSociedadesAnteriores}
                                        </table>}

                                    </td>
                                </tr>
                                <tr className="no-print">
                                    <td colSpan="2"><table>
                                        <tr><th>Personas Fisicas</th><th>Sociedad</th></tr>
                                        {listaPersonasFisicas}
                                    </table>

                                    </td>
                                </tr>
                                <tr className="no-print" >
                                    <td>Comprobante Domicilio</td><td>{comprobanteDomicilioFileUrl
                                        ? <button type="button" className="btn btn-success" onClick={() => {
                                            console.log("Descargando Archivo")

                                            ElementsDataService.downloadS3File(`?fileName=${comprobanteDomicilioFileUrl}`,
                                                `ComprobanteDomicilio_${calle}_${numeroExt}_${numeroInterior}_${colonia}.pdf`)

                                        }}><span className="icon-download"></span> Descargar</button>
                                        : "No hay un archivo cargado todavía"}</td>
                                </tr>

                                <tr>
                                    <td>Resumen contrato Arrendamiento</td><td style={{ whiteSpace: "pre-wrap" }}>{resumenContrato ? resumenContrato : "-"}</td>
                                </tr>

                                <tr className="no-print">
                                    <td>Contrato Arrendamiento</td><td>{contratoArrendamientoFileUrl
                                        ? <button type="button" className="btn btn-success" onClick={() => {
                                            console.log("Descargando Archivo")

                                            ElementsDataService.downloadS3File(`?fileName=${contratoArrendamientoFileUrl}`,
                                                `ContratoArrendamiento_${calle}_${numeroExt}_${numeroInterior}_${colonia}.pdf`)

                                        }}><span className="icon-download"></span> Descargar</button>
                                        : "No hay un archivo cargado todavía"}</td>
                                </tr>

                                <tr>
                                    <td>Fecha Vencimiento Contrato</td><td>{fechaVencimientoContrato ? fechaVencimientoContrato : "-"}</td>
                                </tr>

                                <tr>
                                    <td>Costo Renta Mensual</td><td>{rentaMensual ? formatMoney(rentaMensual) : "-"}</td>
                                </tr>

                                <tr>
                                    <td>Forma de Pago</td><td>{formaPago ? formaPago : "-"}</td>
                                </tr>

                                <tr>
                                    <td>Numero de Cuenta</td><td>{noCuenta ? noCuenta : "-"}</td>
                                </tr>

                                <tr>
                                    <td>Nombre Arrendador</td><td>{arrendadorName ? arrendadorName : "-"}</td>
                                </tr>

                                <tr>
                                    <td>Propietario o Intermediario</td><td>{propietarioIntermediario ? propietarioIntermediario : "-"}</td>
                                </tr>

                                <tr className="no-print">
                                    <td>Documento que acredita Posesión</td><td>{documentoAcreditacionPosesionURL
                                        ? <button type="button" className="btn btn-success" onClick={() => {
                                            console.log("Descargando Archivo")

                                            ElementsDataService.downloadS3File(`?fileName=${documentoAcreditacionPosesionURL}`,
                                                `IdentificacinArrendador_${calle}_${numeroExt}_${numeroInterior}_${colonia}.pdf`)

                                        }}><span className="icon-download"></span> Descargar</button>
                                        : "No hay un archivo cargado todavía"}</td>
                                </tr>

                                <tr className="no-print">
                                    <td>Identificacion Arrendador</td><td>{idArrendadorFileURL
                                        ? <button type="button" className="btn btn-success" onClick={() => {
                                            console.log("Descargando Archivo")

                                            ElementsDataService.downloadS3File(`?fileName=${idArrendadorFileURL}`,
                                                `IdentificacinArrendador_${calle}_${numeroExt}_${numeroInterior}_${colonia}.pdf`)

                                        }}><span className="icon-download"></span> Descargar</button>
                                        : "No hay un archivo cargado todavía"}</td>
                                </tr>


                                <tr className="no-print">
                                    <td>Croquis</td><td>{croquisFileUrl
                                        ? <button type="button" className="btn btn-success" onClick={() => {
                                            console.log("Descargando Archivo")
                                            console.log("croquisFileUrl", croquisFileUrl)

                                            ElementsDataService.downloadS3File(`?fileName=${croquisFileUrl}`,
                                                `Croquis_${calle}_${numeroExt}_${numeroInterior}_${colonia}.pdf`, true);

                                        }}><span className="icon-download"></span> Descargar</button>
                                        : "No hay un archivo cargado todavía"}</td>
                                </tr>

                            </table>

                            <hr />
                            <div className="no-print tool-btn-agregar">
                                <button className="btn btn-add" onClick={() => this.props.history.push(`/domicilios/galeria/editar/${id}`)}>
                                    <span className="icon-edit-pencil"></span>Editar Fotografias </button>
                            </div>

                            <div className="no-break">

                                <div className="centered cols ">
                                    <h2>Fachada</h2>
                                </div>

                                <div className="centered cols ">
                                    <img style={{ width: "50%" }} src={portadaData} alt="" />
                                </div>

                            </div>

                            <div className="no-break">
                                <div className="centered cols ">
                                    <h2 className="centered" >Interiores</h2>
                                </div>

                                {
                                    fotografiasGalleryDataArray.map((image, index) => {

                                        if (index % 2 == 0) {
                                            return (
                                                <div className="centered cols ">
                                                    <div className="no-break col2">
                                                        <img style={{ width: "80%", display: "block", marginLeft: "auto", marginRight: "auto" }} src={image} alt="" />
                                                    </div>
                                                    <div className="no-break col2">
                                                        <img style={{ width: "80%", display: "block", marginLeft: "auto", marginRight: "auto" }} src={fotografiasGalleryDataArray[index + 1] && fotografiasGalleryDataArray[index + 1]} alt="" />
                                                    </div>
                                                </div>)
                                        }

                                    })
                                }

                            </div>






                            <div className="no-break">

                                <div className="titles">
                                    <div className="no-print tool-btn-agregar">
                                        <button className="btn btn-agregar" onClick={() =>
                                            this.props.history.push(`/domicilios/gastos/${id}?gasto=-1`)}> <span className="icon-add-solid"> </span><span className="icon-news-paper"></span> </button>
                                    </div>
                                    <h1 className="no-print"  >Reporte Gastos Domicilio</h1>

                                </div>

                                <div className="no-print cols">
                                    <div className="col2">
                                        <FormControl className="form-control">
                                            <label htmlFor=""><span className="icon-calendar"></span> Ejercicio:</label>
                                            <input type="number" min="1900" max="2099" step="1" value={ejercicio}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.getGastosByPeriodoEjercicio(value);

                                                }}
                                            />
                                        </FormControl>

                                    </div>
                                    &nbsp;
                                    <div className="col2">
                                        <FormControl className="form-control">
                                            <InputLabel>Periodo</InputLabel>
                                            <Select

                                                value={periodo}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.getGastosByPeriodoEjercicio(ejercicio, value);
                                                }}

                                            >
                                                <option aria-label="None" value="" />
                                                <option value={"Enero"}>Enero</option>
                                                <option value={"Febrero"}>Febrero</option>
                                                <option value={"Marzo"}>Marzo</option>
                                                <option value={"Abril"}>Abril</option>
                                                <option value={"Mayo"}>Mayo</option>
                                                <option value={"Junio"}>Junio</option>
                                                <option value={"Julio"}>Julio</option>
                                                <option value={"Agosto"}>Agosto</option>
                                                <option value={"Septiembre"}>Septiembre</option>
                                                <option value={"Octubre"}>Octubre</option>
                                                <option value={"Noviembre"}>Noviembre</option>
                                                <option value={"Diciembre"}>Diciembre</option>

                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>


                                <table className="no-print table simple">
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Fecha</th>
                                        <th>Notas</th>
                                        <th className="no-print">Recibo</th>
                                        <th className="no-print icoSize"><span className="icon-edit-pencil"></span></th>
                                        <th className="no-print icoSize"><span className="icon-trash"></span></th>
                                    </tr>
                                    {listaGastos}
                                </table>

                            </div>
                        </div>

                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default DomicilioReporteComponent