import React, { Component } from 'react'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { FilledInput } from '@material-ui/core';
import AuthenticationService from '../general/AuthenticationService.js'


function getInfoRFC(rfc) {
    return ElementsDataService.retrieveElement("contabilidad/refreshDatabaseTotals", rfc)
}

class CfdiRefreshDashboardComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            rfc: '',
            loading: false,
            mensaje: [],
            buttonEnabled: true,
            allSociedades: [],
            entidadesArray: []
        }

        this.onSubmit = this.onSubmit.bind(this)

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("WRITE_CFDIS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        let entidadesArray = [];

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    console.log("retrieveAllSociedadesResponse", response.data);
                    this.setState({ allSociedades: response.data })

                    this.state.allSociedades.map((element) => {
                        entidadesArray.push({
                            id: element.id,
                            nombre: element.razonSocial,
                            rfc: element.rfc,
                            tipo: "sociedad"
                        })
                    })

                    this.setState({ entidadesArray })


                }
            )

        console.log(this.state);



    }



    async onSubmit(values) {

        console.log("onSubmit")

        this.setState({ buttonEnabled: false })


        var ins = this.state.entidadesArray.length;


        //this.setState({loading:true})

        console.log("ins", ins)

        let mensajeStr = [];

        let failures = '';

        let successCounter = 0;


        for (var x = 0; x < ins; x++) {

            let response = await getInfoRFC(this.state.entidadesArray[x].rfc);

            console.log("response", response);


            if (response.status == 200) {

                successCounter++;

                console.log("sociedad  " + this.state.entidadesArray[x].nombre + " " + this.state.entidadesArray[x].rfc + " " + (x + 1) + " de " + ins + "  procesado con éxito");

                mensajeStr.push(<p key={x + 1}>{"Sociedad  " + (x + 1) + " de " + ins + " " +
                    this.state.entidadesArray[x].nombre + " " + this.state.entidadesArray[x].rfc + " " +
                    "Obtenida con éxito"}</p>)

                this.setState({ mensaje: mensajeStr })


            } else {

                console.log("Ha ocurrido un error con la sociedad " + this.state.entidadesArray[x].nombre + " " + this.state.entidadesArray[x].rfc + " ");
                mensajeStr.push(<p key={x + 1}>{"Ha ocurrido un error con el  " + this.state.entidadesArray[x].nombre + " " + this.state.entidadesArray[x].rfc + " " + (x + 1) + " de " + ins + " "}</p>)

                failures += ", ";

                this.setState({ mensaje: mensajeStr })
            }

        }

        console.log("successCounter", successCounter)

        if (successCounter < ins) {
            alert("A Ocurrido un error con los siguientes Archivos: " + failures)

        } else {
            alert("Todos los archivos se han cargado con exito");

        }

        this.setState({ buttonEnabled: true })


    }

    render() {

        console.log("render CfdiRefreshDashboardComponent")
        console.log("CfdiRefreshDashboardComponent state", this.state)
        let { rfc, fecha, cantidad, tipo, loading, mensaje, buttonEnabled } = this.state

        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">

                        <h1>CFDI</h1>
                        <div className="container">

                            <form >

                                <button className="btn btn-success" type="button" onClick={this.onSubmit} disabled={!buttonEnabled} ><span className="icon-reload"></span> Refrescar</button>
                                <div id="mensaje">{mensaje}</div>

                            </form>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default CfdiRefreshDashboardComponent