import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { Link } from 'react-router-dom';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js';
import AuthenticationService from '../general/AuthenticationService.js';

class ReporteSociedadesRevisadas extends Component {
    constructor(props) {
        super(props)

        const arrayMeses = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ];

        this.state = {
            id: this.props.match.params.id,
            clientesDtoArray: [],
            allSociedades: [],
            allClientes: [],
            clientes: [],
            reportesSociedades: [],
            loading: true,
            ejercicio: new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear(),//año
            periodo: arrayMeses[new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1]//mes

        }

        this.getDeclaracionesByPeriodoEjercicio = this.getDeclaracionesByPeriodoEjercicio.bind(this)

    }

    getDeclaracionesByPeriodoEjercicio(ejercicio = this.state.ejercicio, periodo = this.state.periodo) {


        this.setState({ loading: true })

        ElementsDataService.retrieveAllElements(`cfdis/reporte-clientes-sociedades-revisadas/${ejercicio}/${periodo}`)
            .then(response => {
                this.setState({ reportesSociedades: response.data, loading: false, ejercicio: ejercicio, periodo: periodo })
            })
    }



    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_CONTABILIDAD")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }


        let {
            periodo,
            ejercicio,
        } = this.state

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveAllElements(`cfdis/reporte-clientes-sociedades-revisadas/${ejercicio}/${periodo}`)
            .then(response => {

                console.log("Reporte Sociedades Revisadas")

                console.log(response)

                this.setState({ reportesSociedades: response.data, loading: false })
            })




    }



    render() {

        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {

            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }

            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };

        console.log("render ReporteSociedadesRevisadas")
        console.log("ReporteSociedadesRevisadas state", this.state)
        let {

            periodo,
            ejercicio,
            reportesSociedades,
            loading
        } = this.state


        let today = new Date();

        let dayNumber = today.getDate();
        let monthNumber = today.getMonth();
        let year = today.getFullYear();


        //Encabezados csv
        let csvStr =
            `N°,CLIENTE,TOTAL DE SOCIEDADES,SOCIEDADES ACTIVAS,SOCIEDADES SIN UTILIZAR,SOCIEDADES INACTIVAS,SOCIEDADES EN PROCESO DE LIQUIDACION,SOCIEDADES LIQUIDADAS,SOCIEDADES EN PROCESO DE SUSPENSION,SOCIEDADES SUSPENDIDAS,SOCIEDADES VENDIDAS,SOCIEDADES NO REVISIÓN,SOCIEDADES SIN ESTATUS,TOTAL DOM CONTROL,TOTAL DOM NO CONTROL,TOTAL DOM PENDIENTES DE CAMBIO,TOTAL DOM SIN ESTATUS,TOTAL DOMICILIOS,PPM AL 23/${periodo}/${ejercicio},PPM DESPUES 23/${periodo}/${ejercicio},SOCIEDADES SIN DECLARAR,INGRESOS DEC SAT ${periodo} ${ejercicio} ,INGRESOS FACTURADOS SAT ${periodo} ${ejercicio} ,NOMINA TIMBRADA SAT ${periodo} ${ejercicio}\n`;




        let finalTableContent = []

        let consecutivo = 0;

        reportesSociedades.forEach(reporte => {
            let rowData = [];

            consecutivo++;


            rowData.push(<td>{consecutivo}</td>);
            rowData.push(<td><Link to={`/clientes/reportes/${reporte.clienteId}`} >{reporte.clienteName}</Link></td>);
            rowData.push(<td>{reporte.totalSociedades}</td>);
            rowData.push(<td>{reporte.sociedadesActivas}</td>);
            rowData.push(<td>{reporte.sociedadesSinUtilizar}</td>);
            rowData.push(<td>{reporte.sociedadesInactivas}</td>);
            rowData.push(<td>{reporte.sociedadesProcesoLiquidacion}</td>);
            rowData.push(<td>{reporte.sociedadesLiquidadas}</td>);
            rowData.push(<td>{reporte.sociedadesProcesoSuspension}</td>);
            rowData.push(<td>{reporte.sociedadesSuspendidas}</td>);
            rowData.push(<td>{reporte.sociedadesVendidas}</td>);
            rowData.push(<td>{reporte.sociedadesDevueltas}</td>);
            rowData.push(<td>{reporte.sociedadesNoRevision}</td>);
            rowData.push(<td>{reporte.sociedadesSinEstatus}</td>);
            rowData.push(<td>{reporte.totalDomControl}</td>);
            rowData.push(<td>{reporte.totalDomNoControl}</td>);
            rowData.push(<td>{reporte.totalDomPendienteDeCambio}</td>);
            rowData.push(<td>{reporte.totalDomSinStatus}</td>);
            rowData.push(<td>{reporte.totalDomicilios}</td>);
            rowData.push(<td>{reporte.sociedadesPPMantes}</td>);
            rowData.push(<td>{reporte.sociedadesPPMdespues}</td>);
            rowData.push(<td>{reporte.sociedadesSinDeclarar}</td>);
            rowData.push(<td>{formatMoney(reporte.ingresosDeclaradosPeriodoEjercicio)}</td>);
            rowData.push(<td>{formatMoney(reporte.ingresosTimbradosPeriodoEjercicio)}</td>);
            rowData.push(<td>{formatMoney(reporte.nominaTimbradaPeriodoEjercicio)}</td>);


            csvStr += consecutivo + ",";
            csvStr += reporte.clienteName + ",";
            csvStr += reporte.totalSociedades + ",";
            csvStr += reporte.sociedadesActivas + ",";
            csvStr += reporte.sociedadesSinUtilizar + ",";
            csvStr += reporte.sociedadesInactivas + ",";
            csvStr += reporte.sociedadesProcesoLiquidacion + ",";
            csvStr += reporte.sociedadesLiquidadas + ",";
            csvStr += reporte.sociedadesProcesoSuspension + ",";
            csvStr += reporte.sociedadesSuspendidas + ",";
            csvStr += reporte.sociedadesVendidas + ",";
            csvStr += reporte.sociedadesNoRevision + ",";
            csvStr += reporte.sociedadesSinEstatus + ",";
            csvStr += reporte.totalDomControl + ",";
            csvStr += reporte.totalDomNoControl + ",";
            csvStr += reporte.totalDomPendienteDeCambio + ",";
            csvStr += reporte.totalDomSinStatus + ",";
            csvStr += reporte.totalDomicilios + ",";
            csvStr += reporte.sociedadesPPMantes + ",";
            csvStr += reporte.sociedadesPPMdespues + ",";
            csvStr += reporte.sociedadesSinDeclarar + ",";
            csvStr += reporte.ingresosDeclaradosPeriodoEjercicio + ",";
            csvStr += reporte.ingresosTimbradosPeriodoEjercicio + ",";
            csvStr += reporte.nominaTimbradaPeriodoEjercicio + "\n";


            let inlineOldDeclaracionesStyle = { textAlign: "right" };


            finalTableContent.push(<tr >{rowData}</tr>)

        })






        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button type="button" className="btn btn-success" onClick={() => {
                                    console.log("Descargando Archivo")
                                    const element = document.createElement("a");
                                    const file = new Blob([csvStr], { type: 'text/plain' });
                                    element.href = URL.createObjectURL(file);
                                    var d = new Date();
                                    element.download = "Reporte Sociedades Revisadas" + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                            </div>
                            <h1>REPORTE DE SOCIEDADES REVISADAS <br />{periodo} DE {ejercicio}</h1>
                        </div>

                        <div className="cols">
                            <div className="col2">
                                <FormControl className="form-control">
                                    <label htmlFor=""><span className="icon-calendar"></span> Ejercicio:</label>
                                    <input type="number" min="1900" max="2099" step="1" value={this.state.ejercicio}
                                        onChange={event => {
                                            const { value } = event.target;

                                            this.getDeclaracionesByPeriodoEjercicio(value);

                                        }}
                                    />
                                </FormControl>

                            </div>
                            &nbsp;
                            <div className="col2">
                                <FormControl className="form-control">
                                    <InputLabel>Periodo</InputLabel>
                                    <Select

                                        value={periodo}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.getDeclaracionesByPeriodoEjercicio(ejercicio, value);
                                        }}

                                    >
                                        <option aria-label="None" value="" />
                                        <option value={"Enero"}>Enero</option>
                                        <option value={"Febrero"}>Febrero</option>
                                        <option value={"Marzo"}>Marzo</option>
                                        <option value={"Abril"}>Abril</option>
                                        <option value={"Mayo"}>Mayo</option>
                                        <option value={"Junio"}>Junio</option>
                                        <option value={"Julio"}>Julio</option>
                                        <option value={"Agosto"}>Agosto</option>
                                        <option value={"Septiembre"}>Septiembre</option>
                                        <option value={"Octubre"}>Octubre</option>
                                        <option value={"Noviembre"}>Noviembre</option>
                                        <option value={"Diciembre"}>Diciembre</option>

                                    </Select>
                                </FormControl>
                            </div>
                        </div>


                        <div className="container">
                            <div className="scrolled">
                                <table className="table big-table" >
                                    <thead>

                                        <tr>
                                            <th>N°</th><th>CLIENTE</th>
                                            <th>TOTAL DE SOCIEDADES</th>
                                            <th>SOCIEDADES ACTIVAS</th>
                                            <th>SOCIEDADES SIN UTILIZAR</th>
                                            <th>SOCIEDADES INACTIVAS</th>
                                            <th>SOCIEDADES EN PROCESO DE LIQUIDACION</th>
                                            <th>SOCIEDADES LIQUIDADAS</th>
                                            <th>SOCIEDADES EN PROCESO DE SUSPENSION</th>
                                            <th>SOCIEDADES SUSPENDIDAS</th>
                                            <th>SOCIEDADES VENDIDAS</th>
                                            <th>SOCIEDADES DEVUELTAS</th>
                                            <th>SOCIEDADES NO REVISIÓN</th>
                                            <th>SOCIEDADES SIN ESTATUS</th>
                                            <th>TOTAL DOM CONTROL</th>
                                            <th>TOTAL DOM NO CONTROL</th>
                                            <th>TOTAL DOM PENDIENTES DE CAMBIO</th>
                                            <th>TOTAL DOM SIN ESTATUS</th>
                                            <th>TOTAL DOMICILIOS</th>
                                            <th>PPM AL 23/{periodo}/{ejercicio}</th>
                                            <th>PPM DESPUES 23/{periodo}/{ejercicio}</th>
                                            <th>EMP SIN DECLARAR</th>
                                            <th>INGRESOS DEC SAT {periodo} {ejercicio} </th>
                                            <th>INGRESOS FACTURADOS SAT {periodo} {ejercicio} </th>
                                            <th>NOMINA TIMBRADA SAT {periodo} {ejercicio} </th></tr>
                                    </thead>
                                    <tbody>
                                        {finalTableContent}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>

        )
    }
}

export default ReporteSociedadesRevisadas