import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import AuthenticationService from '../general/AuthenticationService.js'



class ClienteReporteComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            nombreCliente: '',
            sociedades: [],
            clientes: [],
            inicioDate: 2018,
            finDate: new Date().getFullYear(),
            rfcContabilidad: [],
            clientesDtoArray: [],
            allSociedades: [],
            allClientes: [],
            sociedadesActivasFlag: true,
            sociedadesInactivasFlag: true,
            sociedadesSinUtilizarFlag: true,
            sociedadesProcesoLiquidacionFlag: true,
            sociedadesLiquidadasFlag: true,
            sociedadesProcesoSuspensionFlag: true,
            sociedadesSuspendidasFlag: true,
            sociedadesVendidasFlag: true,
            sociedadesDevueltasFlag: true,
            sociedadesNoRevisionFlag: true,
            sociedadesSinStatusContableFlag: true,
            loading: true,

        }

    }



    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_CONTABILIDAD")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;


        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("reporte-contable-cliente", this.state.id)
            .then(response => {

                console.log("reporte-contable-cliente Response", response)

                this.setState({
                    clientesDtoArray: response.data,
                    loading: false
                })
            })

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    //console.log("retrieveAllSociedadesResponse",response.data);
                    this.setState({ allSociedades: response.data })
                })

        ElementsDataService.retrieveAllElements("clientes")
            .then(
                response => {
                    //console.log("retrieveAllClientesResponse",response.data);
                    this.setState({ allClientes: response.data })
                })



    }



    render() {

        //console.log("render ClienteReporteComponent")
        //console.log("ClienteReporteComponent state", this.state)
        let { nombreCliente,
            sociedades,
            clientes,
            inicioDate,
            finDate,
            rfcContabilidad,
            clientesDtoArray,
            allSociedades,
            allClientes,
            loading
        } = this.state


        let tableHeader = [];

        let csvStr = 'Cliente,RFC,Razon Social,Tipo,Status'

        let arrayMeses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo",
            "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

        for (let i = finDate; i >= inicioDate; i--) {
            tableHeader.push(<th className="right">Total <br /> Ingresos <br /> {i}</th>)

            csvStr += ",Total Ingresos " + i

            arrayMeses.forEach(mes => {
                tableHeader.push(<th className="right">Ingresos <br /> {mes} <br /> {i}</th>)
                csvStr += ",Ingresos " + mes + " " + i
            })

            tableHeader.push(<th className="right">Total <br /> Nomina <br /> {i} </th>)
            csvStr += ",Total Nómina " + i

            arrayMeses.forEach(mes => {
                tableHeader.push(<th className="right">Nómina <br /> {mes} <br /> {i}</th>)
                csvStr += ",Nómina " + mes + " " + i
            })

        }

        tableHeader.push(<th className="right">Total Ingresos</th>)
        tableHeader.push(<th className="right">Total Nomina</th>)

        csvStr += ",Total Ingresos"
        csvStr += ",Total Nomina\n"


        let finalTableContent = []

        if (clientesDtoArray.length === 1) {
            nombreCliente = clientesDtoArray[0].nombreCliente
        } else {
            nombreCliente = "TODOS"
        }

        let sociedadesIds = [];
        let clientesIds = [];

        sociedades.forEach(element => {
            sociedadesIds.push(element.id)
        })

        //console.log("sociedadesIds",sociedadesIds)

        clientes.forEach(element => {
            clientesIds.push(element.id)
        })

        //console.log("clientesIds",clientesIds)


        let sociedadesActivasCount = 0,
            sociedadesSinUtilizarCount = 0,
            sociedadesInactivasCount = 0,
            sociedadesProcesoLiquidacionCount = 0,
            sociedadesLiquidadasCount = 0,
            sociedadesProcesoSuspensionCount = 0,
            sociedadesSuspendidasCount = 0,
            sociedadesVendidasCount = 0,
            sociedadesDevueltasCount = 0,
            sociedadesNoRevisionCount = 0,
            sociedadesSinStatusContable = 0,
            sociedadesTOTAL = 0;






        clientesDtoArray.forEach(clienteDto => {
            console.log("clienteDto", clienteDto);
            console.log("clienteDto.nombreCliente", clienteDto.nombreCliente);
            console.log("clienteDto.rfcContabilidad", clienteDto.rfcContabilidad);
            console.log("clienteDto.sociedades", clienteDto.sociedades);
            //console.log("clienteDto.id",clienteDto.id);

            if (clientesIds.length === 0 || clientesIds.includes(clienteDto.id)) {

                clienteDto.sociedades.forEach(element => {

                    let rowData = [];

                    if (sociedadesIds.length === 0 || sociedadesIds.includes(element.id)) {


                        if (element.statusContabilidad === "ACTIVA") { sociedadesActivasCount++; }
                        else if (element.statusContabilidad === "INACTIVA") { sociedadesInactivasCount++; }
                        else if (element.statusContabilidad === "SIN UTILIZAR") { sociedadesSinUtilizarCount++; }
                        else if (element.statusContabilidad === "PROCESO LIQUIDACION") { sociedadesProcesoLiquidacionCount++; }
                        else if (element.statusContabilidad === "LIQUIDADA") { sociedadesLiquidadasCount++; }
                        else if (element.statusContabilidad === "PROCESO SUSPENSION") { sociedadesProcesoSuspensionCount++; }
                        else if (element.statusContabilidad === "SUSPENDIDA") { sociedadesSuspendidasCount++; }
                        else if (element.statusContabilidad === "VENDIDA") { sociedadesVendidasCount++; }
                        else if (element.statusContabilidad === "DEVUELTA") { sociedadesDevueltasCount++; }
                        else if (element.statusContabilidad === "NO REVISION") { sociedadesNoRevisionCount++; }
                        else { sociedadesSinStatusContable++; }

                        sociedadesTOTAL++;

                        //console.log("sociedadesActivasFlag",this.state.sociedadesActivasFlag)

                        //console.log("info row sociedad " ,element.razonSocial)
                        //console.log("info row  Sociedad statusContabilidad " , element.statusContabilidad)



                        if ((this.state.sociedadesActivasFlag && element.statusContabilidad === "ACTIVA") ||
                            (this.state.sociedadesInactivasFlag && element.statusContabilidad === "INACTIVA") ||
                            (this.state.sociedadesSinUtilizarFlag && element.statusContabilidad === "SIN UTILIZAR") ||
                            (this.state.sociedadesProcesoLiquidacionFlag && element.statusContabilidad === "PROCESO LIQUIDACION") ||
                            (this.state.sociedadesLiquidadasFlag && element.statusContabilidad === "LIQUIDADA") ||
                            (this.state.sociedadesProcesoSuspensionFlag && element.statusContabilidad === "PROCESO SUSPENSION") ||
                            (this.state.sociedadesSuspendidasFlag && element.statusContabilidad === "SUSPENDIDA") ||
                            (this.state.sociedadesVendidasFlag && element.statusContabilidad === "VENDIDA") ||
                            (this.state.sociedadesDevueltasFlag && element.statusContabilidad === "DEVUELTA") ||
                            (this.state.sociedadesNoRevisionFlag && element.statusContabilidad === "NO REVISION") ||
                            (this.state.sociedadesSinStatusContableFlag && !element.statusContabilidad)) {


                            rowData.push(<td>{clienteDto.nombreCliente}</td>);
                            rowData.push(<td> <Link to={`/sociedades/reportesContables/${element.rfc}`}>{element.rfc}</Link></td>)
                            rowData.push(<td> <Link to={`/sociedades/reportes/${element.id}`}>{element.razonSocial}</Link></td>)
                            rowData.push(<td> {element.tipo ? element.tipo : "-"}</td>)
                            rowData.push(<td> {element.statusContabilidad ? element.statusContabilidad : "-"}</td>)


                            csvStr += clienteDto.nombreCliente + ","
                            csvStr += element.rfc + ","
                            csvStr += element.razonSocial + ","
                            csvStr += (element.tipo ? element.tipo : "-") + ","
                            csvStr += (element.statusContabilidad ? element.statusContabilidad : "-") + ","



                            for (let i = finDate; i >= inicioDate; i--) {



                                let currentYearIncome = clienteDto.rfcContabilidad[element.rfc].ingresosPerYear ? clienteDto.rfcContabilidad[element.rfc].ingresosPerYear[i] : null;

                                rowData.push(<td>{currentYearIncome ? formatMoney(currentYearIncome, 2) : "-"}</td>);

                                csvStr += (currentYearIncome ? currentYearIncome : "-") + ","

                                for (let mes = 1; mes <= 12; mes++) {

                                    let añoMes = i.toString() + mes.toString();

                                    let currentYeaMonthIncome = clienteDto.rfcContabilidad[element.rfc].ingresosPerYearMonth[añoMes] ? clienteDto.rfcContabilidad[element.rfc].ingresosPerYearMonth[añoMes] : null;

                                    rowData.push(<td className="right">{formatMoney(currentYeaMonthIncome)}</td>);

                                    csvStr += (currentYeaMonthIncome ? currentYeaMonthIncome : "-") + ","

                                }


                                let currentYearNomina = clienteDto.rfcContabilidad[element.rfc].nominaPerYear ? clienteDto.rfcContabilidad[element.rfc].nominaPerYear[i] : null;
                                rowData.push(<td>{currentYearNomina ? formatMoney(currentYearNomina, 2) : "-"}</td>);
                                csvStr += (currentYearNomina ? currentYearNomina : "-") + ","

                                for (let mes = 1; mes <= 12; mes++) {

                                    let añoMes = i.toString() + mes.toString();

                                    let currentYearMonthNomina = clienteDto.rfcContabilidad[element.rfc].nominaPerYearMonth[añoMes] ? clienteDto.rfcContabilidad[element.rfc].nominaPerYearMonth[añoMes] : null;

                                    rowData.push(<td className="right">{formatMoney(currentYearMonthNomina)}</td>);
                                    csvStr += (currentYearMonthNomina ? currentYearMonthNomina : "-") + ","

                                }





                            }

                            let totalIngresosFacturados = clienteDto.rfcContabilidad[element.rfc].totalIngresosFacturados ? clienteDto.rfcContabilidad[element.rfc].totalIngresosFacturados : null;
                            let totalNominaTimbrada = clienteDto.rfcContabilidad[element.rfc].totalNominaTimbrada ? clienteDto.rfcContabilidad[element.rfc].totalNominaTimbrada : null;

                            rowData.push(<td className="right">{totalIngresosFacturados ? formatMoney(totalIngresosFacturados, 2) : "-"}</td>);
                            rowData.push(<td className="right">{totalNominaTimbrada ? formatMoney(totalNominaTimbrada, 2) : "-"}</td>);

                            csvStr += (totalIngresosFacturados ? totalIngresosFacturados : "-") + ","
                            csvStr += (totalNominaTimbrada ? totalNominaTimbrada : "-")


                            finalTableContent.push(<tr>{rowData}</tr>)


                            csvStr += "\n"
                        }

                    }


                })
            }

        })



        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {

            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }

            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };


        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button type="button" className="btn btn-success" onClick={() => {
                                    //console.log("Descargando Archivo")
                                    const element = document.createElement("a");
                                    const file = new Blob([csvStr], { type: 'text/plain' });
                                    element.href = URL.createObjectURL(file);
                                    var d = new Date();
                                    element.download = "Reporte" + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                            </div>
                            <h1>Reporte {nombreCliente}</h1>
                        </div>
                        <h2>Periodo</h2>
                        <div className="cols">
                            <div className="col4">
                                <label htmlFor=""><span className="icon-calendar"></span> Inicio:</label> <input type="number" min="1900" max="2099" step="1" value={this.state.inicioDate}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({ inicioDate: value });
                                    }}
                                />
                            </div>
                            <div className="col4">

                                <label htmlFor=""><span className="icon-calendar"> Fin:</span></label> <input type="number" min="1900" max="2099" step="1" value={this.state.finDate}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({ finDate: value });
                                    }}
                                />

                            </div>
                            <div className="col2"></div>

                        </div>



                        <div className="container">

                            <div className="cols">

                                {nombreCliente === "TODOS" && <div className="col2"><Autocomplete
                                    multiple
                                    id="clientes"
                                    options={allClientes}
                                    getOptionLabel={(option) => option.nombreCliente}

                                    value={clientes}
                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        //console.log(value);
                                        this.setState({ clientes: value });
                                    }}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Clientes"
                                            placeholder=""
                                            name="clientes"
                                        />
                                    )}
                                /> </div>}

                                <div className="col2">
                                    <Autocomplete
                                        multiple
                                        id="sociedadesSelector"
                                        options={allSociedades}
                                        getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                        value={sociedades}
                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            //console.log(value);
                                            this.setState({ sociedades: value })
                                        }}
                                        getOptionSelected={(option, value) => option.rfc === value.rfc}
                                        //value={this.getSelectedItem()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Sociedades"
                                                placeholder=""
                                                name="sociedades"
                                            />
                                        )}
                                    />

                                </div>
                            </div>


                            <table className="table simple">
                                <tr>
                                    <td>Estatus</td><td className="right">Cantidad</td><td className="icoSize right">
                                        <Checkbox
                                            defaultChecked
                                            color="primary"
                                            onChange={event => {
                                                this.setState({
                                                    sociedadesActivasFlag: event.target.checked,
                                                    sociedadesSinUtilizarFlag: event.target.checked,
                                                    sociedadesInactivasFlag: event.target.checked,
                                                    sociedadesProcesoLiquidacionFlag: event.target.checked,
                                                    sociedadesLiquidadasFlag: event.target.checked,
                                                    sociedadesProcesoSuspensionFlag: event.target.checked,
                                                    sociedadesSuspendidasFlag: event.target.checked,
                                                    sociedadesVendidasFlag: event.target.checked,
                                                    sociedadesDevueltasFlag: event.target.checked,
                                                    sociedadesNoRevisionFlag: event.target.checked,
                                                    sociedadesSinStatusContableFlag: event.target.checked,
                                                });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Activas</td><td className="right">{sociedadesActivasCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesActivasFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesActivasFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Sin Utilizar</td><td className="right">{sociedadesSinUtilizarCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesSinUtilizarFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesSinUtilizarFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Inactivas</td><td className="right">{sociedadesInactivasCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesInactivasFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesInactivasFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Proceso Liquidación</td><td className="right">{sociedadesProcesoLiquidacionCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesProcesoLiquidacionFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesProcesoLiquidacionFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Liquidadas</td><td className="right">{sociedadesLiquidadasCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesLiquidadasFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesLiquidadasFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Proceso Suspensión</td><td className="right">{sociedadesProcesoSuspensionCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesProcesoSuspensionFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesProcesoSuspensionFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Suspendidas</td><td className="right">{sociedadesSuspendidasCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesSuspendidasFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesSuspendidasFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Vendidas</td><td className="right">{sociedadesVendidasCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesVendidasFlag}

                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesVendidasFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Devueltas</td><td className="right">{sociedadesDevueltasCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesDevueltasFlag}

                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesDevueltasFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades No Revisión</td><td className="right">{sociedadesNoRevisionCount}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesNoRevisionFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesNoRevisionFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sociedades Sin Estatus</td><td className="right">{sociedadesSinStatusContable}</td><td className="icoSize right">
                                        <Checkbox
                                            checked={this.state.sociedadesSinStatusContableFlag}
                                            color="primary"
                                            onChange={event => {
                                                this.setState({ sociedadesSinStatusContableFlag: event.target.checked });
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>TOTAL</td><td className="right">{sociedadesTOTAL}</td><td></td>
                                </tr>
                            </table >


                            <div className="scrolled">

                                <table className="table big-table">
                                    <thead>
                                        <tr>
                                            <th>Proyecto</th>
                                            <th>RFC</th>
                                            <th>Razon Social</th>
                                            <th>Tipo</th>
                                            <th>Estatus Contable</th>
                                            {tableHeader}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {finalTableContent}
                                    </tbody>
                                </table >
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default ClienteReporteComponent