import axios from 'axios'
import { JPA_API_URL } from '../../Constants'

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const TOKEN = 'token'
export const AUTHORITIES = 'authorities'

export const authoritiesArray = [
    "READ_SOCIEDADES",
    "WRITE_SOCIEDADES",
    "READ_CLIENTES",
    "WRITE_CLIENTES",
    "READ_DOMICILIOS",
    "WRITE_DOMICILIOS",
    "READ_PERSONAS_FISICAS",
    "WRITE_PERSONAS_FISICAS",
    "READ_DOCUMENTOS",
    "WRITE_DOCUMENTOS",
    "READ_CFDIS",
    "WRITE_CFDIS",
    "READ_USERS",
    "WRITE_USERS",
    "READ_HISTORIA",
    "READ_ALERTAS",
]

class AuthenticationService {

    executeBasicAuthenticationService(username, password) {
        return axios.get(`${JPA_API_URL}/basicauth`,
            { headers: { authorization: this.createBasicAuthToken(username, password) } })
    }

    executeJwtAuthenticationService(username, password) {
        return axios.post(`${JPA_API_URL}/authenticate`, {
            username,
            password
        })
    }

    createBasicAuthToken(username, password) {
        return 'Basic ' + window.btoa(username + ":" + password)
    }

    registerSuccessfulLogin(username, password) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)

        this.setupAxiosInterceptors(this.createBasicAuthToken(username, password))
    }

    registerSuccessfulLoginForJwt(username, token, authorities) {

        sessionStorage.setItem(TOKEN, token)
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)

        if (authorities !== null) {
            let authoritiesStr = "";
            authorities.forEach(element => {
                authoritiesStr += element.authority + ",";
            });

            sessionStorage.setItem(AUTHORITIES, authoritiesStr)
        }

        this.setupAxiosInterceptors()
    }

    createJWTToken(token) {
        return 'Bearer ' + token
    }


    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(TOKEN);
        sessionStorage.removeItem(AUTHORITIES);
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    getLoggedInUserAuthorities() {
        return sessionStorage.getItem(AUTHORITIES)
    }

    haveAuthorization(value) {
        let authorizations = sessionStorage.getItem(AUTHORITIES);

        if (authorizations === null) return false;

        return authorizations.includes(value);
    }


    setupAxiosInterceptors() {

        axios.interceptors.request.use(
            (config) => {

                if (this.isUserLoggedIn()) {
                    config.headers.authorization = this.createJWTToken(sessionStorage.getItem(TOKEN))
                }
                return config
            }
        )
    }
}

export default new AuthenticationService()