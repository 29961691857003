import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'

class AddFile extends Component {


  constructor(props) {
    super(props)

    this.state = {
        imageData: '',
    }
  }


  componentDidMount() {
    console.log("AddFile componentDidMount"  )

   
    ElementsDataService.getBase64("http://localhost:8080/files/WithoutWhiteSpaces/1729720_Alejandro_Real.png")
          .then((response) => {
            //console.log(response)

            this.setState({            
            imageData: response
        })
      }
        )
      

}


downloadFile(){

  ElementsDataService.downloadFile('WithoutWhiteSpaces', '1729720_Alejandro_Real.png')

}

    render() {

      let imageData = this.state.imageData;

        return (
          <div className="container">
            <Formik 
              initialValues={{ file: null }}
              onSubmit={(values) => {
                /*
                alert(
                  JSON.stringify(
                    { 
                      fileName: values.file.name, 
                      type: values.file.type,
                      size: `${values.file.size} bytes`
                    },
                    null,
                    2
                  )
                );*/

                const formData = new FormData();
                formData.append('file', values.file);
                formData.append('folderName', "WithoutWhiteSpaces");


                //formData.append('productPrice', "This is a prodct price");
                //formData.append('categoryId', "This is a category Id");
          
                ElementsDataService.saveFile(formData)
                  .then(res => {
                    console.log(res)
                   
                   /* ElementsDataService.getFile(res.data.documentID)
                    .then(res => {
                      console.log(res)
                      var reader = new FileReader();
                      reader.readAsBinaryString(res.data);
                      //reader.
                      //this.setState({ imageData :  reader.readAsDataURL(res.data) }); 
                    })
                    .catch(err => {
                      console.log(err);
                    });*/
                    
                  })
                  .catch(err => {
                    console.log(err);
                  });

              }} 

             
             
              render={({ values, handleSubmit, setFieldValue }) => {
                return (
                  <div>
                  <form onSubmit={handleSubmit} enctype="multipart/form-data">
                    <div className="form-group">
                      <label for="file">File upload</label>
                      <input id="file" name="file" type="file" onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }} className="form-control" />
                    </div>
                      
                    <button type="submit" className="btn btn-primary">submit</button>
                  </form>
                  <img src={'data:image/png;base64,' + imageData} alt="n eta?" width="100" height="100"></img>

                  <a  onClick={this.downloadFile}>Download</a>
                  </div>
                );
              }} />
          </div>
        );
      }
    }


  

   
export default AddFile
