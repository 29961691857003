import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';


export function drawChart(data,divID){ 

    console.log("drawChart data: ",data)

   
    // set the dimensions and margins of the graph

    // set the dimensions and margins of the graph
    var margin = {top: 20, right: 20, bottom: 100, left: 80},
    width = 960 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;

    // set the ranges
    var x = d3.scaleBand()
        .range([0, width])
        .padding(0.1);
    var y = d3.scaleLinear()
        .range([height, 0]);
        
    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    var svg = d3.select(divID).append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
   // .style("border", "1px solid black")
    .append("g")
    .attr("transform", 
        "translate(" + margin.left + "," + margin.top + ")");

    // format the data
    data.forEach(function(d) {
        d.ingresos = +d.ingresos;
        d.year =  +d.year;
    });

    // Scale the range of the data in the domains
    x.domain(data.map(function(d) { return (d.mes + " " + d.year); }));
    y.domain([0, d3.max(data, function(d) { return d.ingresos; })]);

    // append the rectangles for the bar chart
  svg.selectAll(".bar")
    .data(data)
    .enter().append("rect")
    .style("fill",  (d) => {        
        return colors [ d.year % colors.length];
    })
    .attr("x", function(d) { return x(d.mes + " " + d.year); })
    .attr("width", x.bandwidth())
    .attr("y", function(d) { return y(d.ingresos); })
    .attr("height", function(d) { return height - y(d.ingresos); });

    
 /*    svg.selectAll(".bar")
    .data(data)
    .enter().append("text")
    .attr("x", function(d) { return x(d.mes + " " + d.year); })
    .attr("y", function(d) { return y(d.ingresos); })
    .attr("text-anchor", "start") // set anchor y justification
    .attr("dy", "-.35em") // set offset y position
    .attr("dx", "1em") // set offset y position
    .text(function(d) { return d.ingresos; }); // define the text to display
    
 */


    // add the x Axis
    svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x))
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "-0.7em")
    .attr("dy", "-0.1em")
    .attr("transform", "rotate(-65)");

    // add the y Axis
    svg.append("g")
    .call(d3.axisLeft(y));

        
   
}

const colors = [
    "#fe5b51",
    "#ffcb50",
    "#30db9f",
    "#0ab5bd"      
]


var i = 0;


function Chart(props) {

    const [data, setData] = useState([]);

  
    useEffect(() => {       
        drawChart(props.ingresosPerMonthYear, props.divId);
    }, []);

   

    return (
        <div className="Grafica">           
        </div>
    );
}

export default Chart;