import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import { Link } from 'react-router-dom'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Block } from '@material-ui/icons';
import AuthenticationService from '../general/AuthenticationService.js'


class SociedadReporteComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            correo: '',
            password: '',
            generacion: '',
            domicilios: [],
            opinionCumplimiento: '',
            pendientes: '',
            razonSocial: '',
            revisionFiscal: '',
            status: '',
            statusContabilidad: '',
            responsableContabilidad: '',
            auxiliarContable: '',
            tipo: '',
            situacion: '',
            rfc: '',
            clientes: [],
            documentos: [],
            softDelete: '',
            datosConstitucion: '',
            objetoSocial: '',
            clausulaExtranjeria: '',
            capitalSocialFijo: '',
            capitalSocialVariable: '',
            valorNominal: '',
            distribucionCapital: '',
            allPersonasFisicas: [],
            allDomicilios: [],
            vigenciaFiel: '',
            telefono: '',
            telefonoDesignadoCliente: '',
            buzonTributarioStatus: '',
            historialSociedadDomicilios: [],
            fechaSuspension: '',
            fechaEntrega: '',

        }

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("READ_SOCIEDADES")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("sociedades", this.state.id)
            .then(response => {
                console.log(response)
                this.setState({
                    correo: response.data.correo,
                    password: response.data.password,
                    generacion: response.data.generacion,
                    domicilios: response.data.domicilios,
                    opinionCumplimiento: response.data.opinionCumplimiento,
                    pendientes: response.data.pendientes,
                    razonSocial: response.data.razonSocial,
                    revisionFiscal: response.data.revisionFiscal,
                    status: response.data.status,
                    statusContabilidad: response.data.statusContabilidad,
                    responsableContabilidad: response.data.responsableContabilidad,
                    auxiliarContable: response.data.auxiliarContable,
                    tipo: response.data.tipo,
                    situacion: response.data.situacion,
                    rfc: response.data.rfc,
                    clientes: response.data.clientes,
                    documentos: response.data.documentos,
                    entidadesRolesComentarios: response.data.entidadesRolesComentarios,
                    datosConstitucion: response.data.datosConstitucion,
                    objetoSocial: response.data.objetoSocial,
                    clausulaExtranjeria: response.data.clausulaExtranjeria,
                    capitalSocialFijo: response.data.capitalSocialFijo,
                    capitalSocialVariable: response.data.capitalSocialVariable,
                    valorNominal: response.data.valorNominal,
                    distribucionCapital: response.data.distribucionCapital,
                    vigenciaFiel: response.data.vigenciaFiel,
                    telefono: response.data.telefono,
                    telefonoDesignadoCliente: response.data.telefonoDesignadoCliente,
                    buzonTributarioStatus: response.data.buzonTributarioStatus,
                    historialSociedadDomicilios: response.data.historialSociedadDomicilios,
                    fechaSuspension: response.data.fechaSuspension,
                    fechaEntrega: response.data.fechaEntrega,

                })

            })

    }



    render() {

        console.log("render SociedadReporteComponent")
        console.log("SociedadReporteComponent state", this.state)

        let { id,
            correo,
            password,
            generacion,
            opinionCumplimiento,
            pendientes,
            razonSocial,
            revisionFiscal,
            status,
            tipo,
            situacion,
            rfc,
            clientes,
            domicilios,
            personasFisicas,
            documentos,
            personasMorales,
            entidadesRolesComentarios,
            datosConstitucion,
            objetoSocial,
            clausulaExtranjeria,
            capitalSocialFijo,
            capitalSocialVariable,
            valorNominal,
            distribucionCapital,
            allPersonasFisicas,
            allDomicilios,
            vigenciaFiel,
            statusContabilidad,
            responsableContabilidad,
            auxiliarContable,
            telefono,
            telefonoDesignadoCliente,
            buzonTributarioStatus,
            historialSociedadDomicilios,
            fechaSuspension,
            fechaEntrega,

        } = this.state

        const listaClientes = clientes ? clientes.map((cliente) => <li key={cliente.id}>
            <Link to={`/clientes/reportes/${cliente.id}`}>
                {cliente.nombreCliente}
            </Link>
        </li>) : [];

        const listaDomiciliosOrdenados = domicilios ? domicilios
            .sort((a, b) => {

                let fechaInicioA = 0, fechaInicioB = 0

                historialSociedadDomicilios
                    .filter(historial => historial.domicilioId == a.id)
                    .forEach(historial => {
                        fechaInicioA = Date.parse(historial.fechaInicio)

                        if (isNaN(fechaInicioA)) {
                            fechaInicioA = 0
                        }

                    })

                historialSociedadDomicilios
                    .filter(historial => historial.domicilioId == b.id)
                    .forEach(historial => {
                        fechaInicioB = Date.parse(historial.fechaInicio)

                        if (isNaN(fechaInicioB)) {
                            fechaInicioB = 0
                        }

                    })

                return fechaInicioB - fechaInicioA
            }) : []


        const listaDomiciliosActual = listaDomiciliosOrdenados ? listaDomiciliosOrdenados.map((domicilio) =>
            <li key={domicilio.id}>
                <Link to={`/domicilios/reportes/${domicilio.id}`}>
                    {`Calle: ${domicilio.calle} # ${domicilio.numeroExt}${domicilio.numeroInterior && " Int. " + domicilio.numeroInterior}, ${domicilio.colonia}, ${domicilio.municipio}, ${domicilio.estado}`}
                </Link>
                {historialSociedadDomicilios
                    .filter(historial => historial.domicilioId == domicilio.id && historial.fechaInicio)
                    .map(historial => <div>
                        <p><b>Desde:</b> {historial.fechaInicio}
                            {historial.fechaFin ? <b> Hasta: </b> : <b> *Actual</b>}
                            {historial.fechaFin}</p>
                        <p><b>Estatus:</b> {domicilio.estatus}</p>
                        <p><b>Teléfono:</b> {domicilio.telefono}</p>
                        <br />
                    </div>
                    )}
            </li>).slice(0, 1) : []


        const listaDomiciliosAnteriores = listaDomiciliosOrdenados ? listaDomiciliosOrdenados.map((domicilio) =>
            <row>
                <li key={domicilio.id}>
                    <Link to={`/domicilios/reportes/${domicilio.id}`}>
                        {`Calle: ${domicilio.calle} # ${domicilio.numeroExt}${domicilio.numeroInterior && " Int. " + domicilio.numeroInterior}, ${domicilio.colonia}, ${domicilio.municipio}, ${domicilio.estado}`}
                    </Link>
                    {historialSociedadDomicilios
                        .filter(historial => historial.domicilioId == domicilio.id && historial.fechaInicio)
                        .map(historial => <div>
                            <p><b>Desde:</b> {historial.fechaInicio}
                                {historial.fechaFin ? <b> Hasta: </b> : <b> *Actual</b>}
                                {historial.fechaFin}</p>
                            <p><b>Estatus:</b> {domicilio.estatus}</p>
                            <p><b>Teléfono:</b> {domicilio.telefono}</p>
                            <br />
                        </div>
                        )}
                </li>
            </row>).slice(1) : []









        const listaDocumentos = documentos && documentos.map(documento =>
            <div className="item" key={documento.id}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >


                        <div className="cols">
                            <div className="subjectFit"> <Link to={`/documentos/reportes/${documento.id}`}>
                                {documento.asuntos ? documento.asuntos : documento.tipoDocumento}</Link>

                            </div>

                            <div className="dateFit">
                                {documento.fecha}
                            </div>

                            <div className="icoSize">
                                {documento.documentFileUrl ?
                                    <button type="button" className="btn btn-success" onClick={() => {
                                        console.log("Descargando Archivo")

                                        ElementsDataService.downloadS3File(`?fileName=${documento.documentFileUrl}`,
                                            `${razonSocial}_${rfc}_${documento.asuntos.replace(",", "_")}.pdf`)

                                    }}><span className="icon-download"></span> </button>
                                    : <span className="icon-block"></span>}
                            </div>
                        </div>

                    </AccordionSummary>

                    <AccordionDetails>

                        <table>


                            <tr key={documento.id}>

                                <td>
                                    <table>
                                        {entidadesRolesComentarios.map(function (roles) {

                                            return (documento.id == roles.documentoId ? <tr>
                                                <td>{roles.rol}</td>
                                                <td><Link to={roles.tipo == `personaFisica` ? `/personas-fisicas/reportes/${roles.entidadId}` :
                                                    `/sociedades/reportes/${roles.entidadId}`}>{roles.razonSocial}</Link> </td>
                                            </tr> : ''
                                            );
                                        })}
                                    </table>
                                </td>
                                <td style={{ whiteSpace: "pre-wrap" }} > {documento.resumen} </td>
                            </tr>
                        </table>
                    </AccordionDetails>
                </Accordion>
            </div>);


        const listaSociosActuales = documentos && documentos
            .filter(documento =>
                documento.asuntos &&
                (documento.asuntos.includes("Constitutiva") ||
                    documento.asuntos.includes("Compra venta de acciones/partes sociales") ||
                    documento.asuntos.includes("Nombramiento Administrador/Consejo de Administración") ||
                    documento.asuntos.includes("Admisión/Renuncia de Asociados"))).slice(0, 1)
            .map(documento => <tr key={documento.id}>
                <td>
                    <table>
                        <td>
                            {entidadesRolesComentarios.map(function (roles) {

                                return (documento.id == roles.documentoId ? <tr>
                                    <td>{roles.rol}</td>
                                    <td><Link to={roles.tipo == `personaFisica` ? `/personas-fisicas/reportes/${roles.entidadId}` :
                                        `/sociedades/reportes/${roles.entidadId}`}>{roles.razonSocial}</Link> </td>
                                </tr> : ''
                                );
                            })}
                        </td>
                        <td>
                            <Link to={`/documentos/reportes/${documento.id}`}>
                                {documento.asuntos ? documento.asuntos : documento.tipoDocumento}</Link>
                            <br></br><b>Fecha:</b>  {documento.fecha}
                        </td>
                    </table>
                </td>
            </tr>)

        const listaSociosAnteriores = documentos && documentos
            .filter(documento =>
                documento.asuntos &&
                (documento.asuntos.includes("Constitutiva") ||
                    documento.asuntos.includes("Compra venta de acciones/partes sociales") ||
                    documento.asuntos.includes("Nombramiento Administrador/Consejo de Administración") ||
                    documento.asuntos.includes("Admisión/Renuncia de Asociados"))).slice(1)
            .map(documento => <tr key={documento.id}>
                <td>
                    <table>
                        <td>
                            {entidadesRolesComentarios.map(function (roles) {

                                return (documento.id == roles.documentoId ? <tr>
                                    <td>{roles.rol}</td>
                                    <td><Link to={roles.tipo == `personaFisica` ? `/personas-fisicas/reportes/${roles.entidadId}` :
                                        `/sociedades/reportes/${roles.entidadId}`}>{roles.razonSocial}</Link> </td>
                                </tr> : ''
                                );
                            })}
                        </td>
                        <td>
                            <Link to={`/documentos/reportes/${documento.id}`}>
                                {documento.asuntos ? documento.asuntos : documento.tipoDocumento}</Link>
                            <br></br><b>Fecha:</b>  {documento.fecha}
                        </td>
                    </table>
                </td>
            </tr>)



        const documentoCIF = documentos ? documentos.map((documento) => (documento.tipoDocumento == "CIF") ? <tr key={documento.id}>
            <td> <Link to={`/documentos/reportes/${documento.id}`}>
                {documento.asuntos ? documento.asuntos : documento.tipoDocumento}</Link>
            </td>
            <td><b>Fecha:</b>  {documento.fecha}</td>
            <td>{documento.documentFileUrl ? <button type="button" className="btn btn-success" onClick={() => {
                console.log("Descargando Archivo")

                ElementsDataService.downloadS3File(`?fileName=${documento.documentFileUrl}`,
                    `${rfc}_CIF.pdf`)

            }}><span className="icon-download"></span></button>
                : <span className="icon-block"></span>}</td>
        </tr> : "") : '';

        return (
            <div id="main">
                <div className="panel">
                    <div className="titles">
                        <div className="tool-btn-agregar">
                            <button className="btn btn-edit" onClick={() => this.props.history.push(`/sociedades/${id}`)}><span className="icon-edit-pencil"> </span><span className="icon-factory"></span></button>
                        </div>
                        <div className="tool-btn-agregar">
                            <button className="btn btn-agregar" onClick={() => this.props.history.push(`/sociedades/-1`)}><span className="icon-add-solid"> </span><span className="icon-factory"></span></button>
                        </div>
                        <h1>Reporte Legal</h1>
                    </div>


                    <h2 className="title"> {razonSocial}</h2>
                    <div className="container">
                        <table className="table simple">
                            <tr>
                                <td>Razon Social/Denominación</td><td colSpan="2">{razonSocial}</td>
                            </tr>
                            <tr>
                                <td>RFC</td><td colSpan="2"><Link to={`/sociedades/reportesContables/${rfc}`} >{rfc}</Link></td>
                            </tr>
                            <tr>
                                <td>Responsable Contabilidad</td><td colSpan="2">{responsableContabilidad}</td>
                            </tr>
                            <tr>
                                <td>Auxiliar Contable</td><td colSpan="2">{auxiliarContable}</td>
                            </tr>
                            <tr>
                                <td>Telefono</td><td colSpan="2">{telefono}</td>
                            </tr>
                            <tr>
                                <td>Telefono Designado por el cliente</td><td colSpan="2">{telefonoDesignadoCliente}</td>
                            </tr>
                            <tr>
                                <td>Domicilios ({domicilios.length}):</td><td colSpan="2">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            {listaDomiciliosActual}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <table>
                                                {listaDomiciliosAnteriores}
                                            </table>
                                        </AccordionDetails>
                                    </Accordion>


                                </td>
                            </tr>

                            <tr>
                                <td>Proyectos</td><td>{listaClientes}</td> <td>Fecha Entrega a Cliente: {fechaEntrega} </td>
                            </tr>

                            <tr>
                                <td>Estatus Buzon Tributario</td><td colSpan="2">{buzonTributarioStatus}</td>
                            </tr>
                            <tr>
                                <td>Correo electrónico</td><td colSpan="2">{correo}</td>
                            </tr>
                            <tr>
                                <td>Contraseña de correo electrónico</td><td colSpan="2">{password}</td>
                            </tr>
                            <tr>
                                <td>Vigencia FIEL</td><td colSpan="2">{vigenciaFiel}</td>
                            </tr>
                            <tr>
                                <td>Generación</td><td colSpan="2">{generacion}</td>
                            </tr>
                            <tr>
                                <td>Estatus Legal</td><td colSpan="2">{status}</td>
                            </tr>
                            <tr>
                                <td>Estatus Contable</td><td colSpan="2">{statusContabilidad}
                                    {statusContabilidad === "SUSPENDIDA" && fechaSuspension && ` (${fechaSuspension})`}</td>
                            </tr>
                            <tr>
                                <td>Tipo</td><td colSpan="2">{tipo}</td>
                            </tr>
                            <tr>
                                <td>Revision Fiscal</td><td colSpan="2">{revisionFiscal}</td>
                            </tr>
                            <tr>
                                <td>Opinion Cumplimiento</td><td colSpan="2">{opinionCumplimiento}</td>
                            </tr>
                            <tr>
                                <td>Situacion</td><td colSpan="2">{situacion}</td>
                            </tr>
                            <tr>
                                <td>Pendientes</td><td colSpan="2">{pendientes}</td>
                            </tr>
                            <tr>
                                <td>Datos Constitucion</td><td colSpan="2" style={{ whiteSpace: "pre-wrap" }} >{datosConstitucion}</td>
                            </tr>
                            <tr>
                                <td>Objeto Social</td><td colSpan="2" style={{ whiteSpace: "pre-wrap" }} >{objetoSocial}</td>
                            </tr>
                            <tr>
                                <td>Clausula Extranjeria</td><td colSpan="2">{clausulaExtranjeria}</td>
                            </tr>
                            <tr>
                                <td>Capital Social Fijo</td><td colSpan="2">{capitalSocialFijo}</td>
                            </tr>
                            <tr>
                                <td>Capital Social Variable</td><td colSpan="2">{capitalSocialVariable}</td>
                            </tr>
                            <tr>
                                <td>Valor Nominal</td><td colSpan="2">{valorNominal}</td>
                            </tr>
                            <tr>
                                <td>Distribucion Capital</td><td colSpan="2">{distribucionCapital}</td>
                            </tr>
                            <tr>
                                <td>CIF</td><td colSpan="2">{documentoCIF}</td>
                            </tr>
                            <tr>
                                <td>Socios Actuales</td><td colSpan="2">{listaSociosActuales}</td>
                            </tr>

                            <tr>
                                <td>Socios Anteriores</td><td colSpan="2">{listaSociosAnteriores}</td>
                            </tr>




                        </table>
                        <h2 className="title">Documentos</h2>
                        <div className="acordeon">
                            <div className="encabezado">
                                <h3 className="subjectFit">Asuntos</h3>
                                <h3 className="dateFit" >Fecha</h3>
                                <h3 className="icoSize"></h3>
                                <h3 className="icoSize"></h3>
                            </div>
                            {listaDocumentos}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default SociedadReporteComponent