import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'html-react-parser';
import LoadingMask from "react-loadingmask";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AuthenticationService from '../general/AuthenticationService.js'


class AlertasComponent extends Component {
    constructor(props) {
        console.log("AlertasComponent Constructor")
        super(props)

        this.state = {
            ...props,
            elements: [],
            currentAlerta: '',
            allSociedades: [],
            allClientes: [],
            clientes: [],
            selectedPropietarios: [],
        }

        this.expand = this.expand.bind(this)

    }



    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_ALERTAS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }



        this.setState({ elements: [], loading: true })

        let sociedadesArray = []

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    console.log("retrieveAllSociedadesResponse", response.data);
                    console.log("Sociedades: " + response.data)

                    response.data.map((element) => {
                        sociedadesArray.push({
                            id: element.id,
                            razonSocial: element.razonSocial,
                            rfc: element.rfc,
                            clientes: element.clientes
                        })
                    })

                    this.setState({ allSociedades: sociedadesArray })

                    ElementsDataService.retrieveAllElements("clientes")
                        .then(
                            respuesta => {
                                console.log("retrieveClientesrespuesta", respuesta.data);
                                this.setState({ allClientes: respuesta.data })

                                ElementsDataService.retrieveAllElements("alertas")
                                    .then(
                                        respuesta => {
                                            console.log("retrieveAlertasrespuesta", respuesta.data);
                                            this.setState({ elements: respuesta.data, loading: false })


                                        }
                                    )


                            }
                        )





                }
            )


    }

    expand(diccionario, mensaje) {

        let newString = ""

        let map = new Map(Object.entries(diccionario));

        //        console.log(map)



        for (let i = 0; i < mensaje.length; i++) {
            let c = mensaje[i];

            if (c === '^') {
                // console.log("Caret")
                c = mensaje[++i]
                // console.log(c)
                // console.log("Diccionario index ", map.get(c))
                newString = newString.concat(map.get(c));
            } else {
                // console.log(c);
                newString = newString.concat(c);
            }
        }

        //console.log(newString)

        return newString;


    }


    render() {


        //Encabezados csv
        let csvStr = ""


        const roles = [
            { id: 1, nombre: 'Socio' },
            { id: 2, nombre: 'Accionista' },
            { id: 3, nombre: 'Asociado' },
            { id: 4, nombre: 'Comisario' },
            { id: 5, nombre: 'Administrador General Único' },
            { id: 6, nombre: 'Poderdante' },
            { id: 7, nombre: 'Apoderado' },
            { id: 8, nombre: 'Presidente Consejo Admnistración' },
            { id: 9, nombre: 'Delegado Especial' },
            { id: 10, nombre: 'Socio Administrador' },
            { id: 11, nombre: 'Escrutador' },
            { id: 12, nombre: 'Gerente' },
            { id: 13, nombre: 'Director General' },
            { id: 15, nombre: 'Otro:' },

        ]

        const propietarios = [
            { id: 1, nombre: 'ST' },
            { id: 2, nombre: 'SPH' },
        ]

        console.log("render AlertasComponent", this.state)

        let counter = 0;

        let { elements, loading, currentAlerta, allSociedades, allClientes, clientes, selectedPropietarios } = this.state

        let mensajesAlertas = []

        let alertasTables = []

        let variant = "outlined";//'filled' | 'outlined' | 'standard'


        elements && elements.forEach(
            listadoAlertas => {
                //console.log(listadoAlertas.encabezado)
                //console.log(listadoAlertas.objectType)
                //console.log("listadoAlertas.mensajes", listadoAlertas.mensajes)


                Object.values(listadoAlertas.mensajes).forEach(mensaje => {
                    mensajesAlertas.push(mensaje)
                })


                console.log("mensajesAlertas", mensajesAlertas)
                console.log(listadoAlertas.alertas)

                let tipoObjeto = ''
                let nameHeader = ''
                let url = ''

                if (listadoAlertas.objectType === 'sociedad') {
                    tipoObjeto = "Sociedad"
                    nameHeader = "Razon Social"
                    url = "sociedades"
                } else if (listadoAlertas.objectType === 'personaFisica') {
                    tipoObjeto = "Persona Fisica"
                    nameHeader = "Nombre"
                    url = "personas-fisicas"
                }

                let header = ""

                header += `"${listadoAlertas.encabezado}",,,\n`
                header += `#,"${nameHeader}",Alerta,`
                header += listadoAlertas.objectType === 'personaFisica' ? "Sociedades,Roles,Cliente,Propietario" : "Cliente,Propietario"
                header += "\n"

                let content = ""

                let alertasRows = []
                counter = 0

                listadoAlertas.alertas.forEach(alerta => {

                    let mensajeAlerta = this.expand(listadoAlertas.mensajes, alerta.mensaje)

                    let clienteFlag = clientes.length ? false : true;
                    let propietarioFlag = selectedPropietarios.length ? false : true;

                    if (listadoAlertas.objectType === 'personaFisica') {

                        alerta.idsSociedades.map(sociedadRol => {
                            let sociedad = allSociedades.find(x => x.id === sociedadRol.sociedadId)

                            sociedad && sociedad.clientes && clientes && sociedad.clientes.map(clienteMini => {
                                clientes.forEach(clienteSeleccionado => {
                                    if (clienteSeleccionado.id === clienteMini.id) clienteFlag = true;

                                })

                                let cliente = allClientes.find(x => x.id === clienteMini.id)

                                if (selectedPropietarios === cliente.propietario) propietarioFlag = true;
                            })
                        })

                    }

                    if (listadoAlertas.objectType === 'sociedad') {

                        allSociedades.find(x => x.id === alerta.objectId).clientes.map(clienteMini => {

                            let cliente = allClientes.find(x => x.id === clienteMini.id)

                            if (selectedPropietarios === cliente.propietario) propietarioFlag = true;

                            clientes.forEach(clienteSeleccionado => {
                                if (clienteSeleccionado.id === cliente.id) clienteFlag = true;
                            })

                        })

                    }



                    if (mensajeAlerta.includes(currentAlerta) && clienteFlag && propietarioFlag) {

                        content += ++counter + ","
                        content += `"${alerta.name}",`
                        content += `"${mensajeAlerta}",`


                        alertasRows.push(
                            <tr>
                                <td>{counter}</td>
                                <td><Link to={`/${url}/reportes/${alerta.objectId}`} >{alerta.name}</Link></td>
                                <td>{mensajeAlerta}</td>
                                {listadoAlertas.objectType === 'personaFisica' && <td>{alerta.idsSociedades.map(function (sociedadRol, index) {

                                    let sociedad = allSociedades.find(x => x.id === sociedadRol.sociedadId)
                                    if (index === 0) {
                                        content += sociedad ? `"${sociedad.razonSocial}"` : ""
                                    } else {
                                        content += sociedad ? `\n,,,"${sociedad.razonSocial}"` : ""
                                    }

                                    if (sociedadRol.roles.length === 0) {
                                        content += ","
                                    }

                                    return <div><p>{sociedad && <Link to={`/sociedades/reportes/${sociedad.id}`} >*{
                                        sociedad.razonSocial
                                    }</Link>}</p>
                                        <p>Roles: {sociedadRol.roles.map(function (rol, index) {
                                            let rolName = roles.find(x => x.id === rol)

                                            if (index === 0) {
                                                content += `,"${rolName.nombre} `
                                            } else {
                                                content += `, ${rolName.nombre} `
                                            }

                                            if (index === sociedadRol.roles.length - 1) {
                                                content += `"`
                                            }


                                            return <span>{rolName.nombre}, </span>
                                        }
                                        )} Cliente: {sociedad && sociedad.clientes && sociedad.clientes.map(function (clienteMini, index) {

                                            let cliente = allClientes.find(x => x.id === clienteMini.id)

                                            if (index === 0) {
                                                content += `,${cliente.nombreCliente},${cliente.propietario}`
                                            } else {
                                                content += `\n,,,,,${cliente.nombreCliente},${cliente.propietario}`

                                            }


                                            return <strong>{cliente.nombreCliente}-{cliente.propietario} </strong>
                                        }

                                        )}</p>
                                    </div>


                                })}</td>}


                                {listadoAlertas.objectType === 'sociedad' && <td>
                                    {
                                        allSociedades.find(x => x.id === alerta.objectId).clientes.map(function (clienteMini, index) {

                                            let cliente = allClientes.find(x => x.id === clienteMini.id)

                                            if (index === 0) {
                                                content += `${cliente.nombreCliente},${cliente.propietario}`
                                            } else {
                                                content += `\n,,,${cliente.nombreCliente},${cliente.propietario}`
                                            }


                                            return <div>{cliente.nombreCliente}-{cliente.propietario}</div>
                                        })
                                    }
                                </td>




                                }



                            </tr>

                        )

                        content += "\n"
                    }

                })

                if (content) {
                    csvStr += header + content
                }



                alertasRows.length && alertasTables.push(
                    <div class='tableContainer'>

                        <h1>{listadoAlertas.encabezado}</h1>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th>{nameHeader}</th>
                                    <th>Alerta</th>
                                    {listadoAlertas.objectType === 'personaFisica' && <th>Sociedades</th>}
                                    {listadoAlertas.objectType === 'sociedad' && <th>Cliente-Propietario</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    alertasRows
                                }
                            </tbody>
                        </table>
                    </div>)




            })




        // console.log("csvStr",csvStr )


        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="container">
                            <div className="tool-btn-agregar">
                                <button type="button" className="btn btn-success" onClick={() => {
                                    console.log("Descargando Archivo")
                                    const element = document.createElement("a");
                                    const file = new Blob([csvStr], { type: 'text/plain' });
                                    element.href = URL.createObjectURL(file);
                                    var d = new Date();
                                    element.download = "Reporte Alertas " + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                            </div>
                            <div className="cols">
                                <div className="col3 ">

                                    <FormControl variant={variant} className="form-control">
                                        <InputLabel >Alertas</InputLabel>
                                        <Select

                                            //value={}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ currentAlerta: value });
                                            }}
                                            label="Alertas"

                                        >

                                            <option value={""}>-----</option>
                                            {
                                                mensajesAlertas.map(element => <option value={element}>{element}</option>)
                                            }

                                        </Select>
                                    </FormControl>

                                </div>

                                <div className="col3 ">

                                    <FormControl variant={variant} className="form-control">
                                        <InputLabel >Propietarios</InputLabel>
                                        <Select

                                            //value={}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ selectedPropietarios: value });
                                            }}
                                            label="Propietarios"

                                        >

                                            <option value={""}>-----</option>
                                            {
                                                propietarios.map(element => <option value={element.nombre}>{element.nombre}</option>)
                                            }

                                        </Select>
                                    </FormControl>

                                </div>

                                <div className="col3">
                                    <Autocomplete
                                        multiple
                                        id="clientes"
                                        options={allClientes}
                                        getOptionLabel={(option) => option.nombreCliente}

                                        value={clientes}
                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            this.setState({ clientes: value })
                                            console.log("Cliente: ", value);

                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={variant}
                                                label="Proyectos"
                                                placeholder=""
                                                name="clientes"
                                            />
                                        )}
                                    /> </div>
                            </div>
                            <div id="tablesContainer">

                                {
                                    alertasTables
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default AlertasComponent