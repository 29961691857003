import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LoadingMask from "react-loadingmask";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import AuthenticationService from '../general/AuthenticationService.js'



class DocumentoFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            tipoDocumento: [],
            asuntos: [],
            resumen: '',
            fecha: '',
            resourceUri: '',
            sociedades: [],
            personasFisicas: [],
            rolesArray: [],
            entidades: [{
                nombres: [],
                roles: [],
                comentarios: "",
                id: 1
            },

            ],
            allSociedades: [],
            allPersonasFisicas: [],
            documentFileUrl: '',
            entidadesArray: [],
            loading: false,

        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)

    }

    useStyles = makeStyles((theme) => ({
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
    }));

    removeDuplicatesFromArrayByProperty = (arr, prop) => arr.reduce((accumulator, currentValue) => {
        if (!accumulator.find(obj => obj[prop] === currentValue[prop])) {
            accumulator.push(currentValue);
        }
        return accumulator;
    }, []);

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("WRITE_DOCUMENTOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        let entidadesArray = [];

        let sociedadesArray = [];

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    console.log("retrieveAllSociedadesResponse", response.data);
                    console.log("Sociedades: " + response.data)

                    response.data.map((element) => {
                        sociedadesArray.push({
                            id: element.id,
                            razonSocial: element.razonSocial,
                            rfc: element.rfc
                        })
                    })

                    this.setState({ allSociedades: sociedadesArray })

                    this.state.allSociedades.map((element) => {
                        entidadesArray.push({
                            id: element.id,
                            nombre: element.razonSocial,
                            rfc: element.rfc,
                            tipo: "sociedad"
                        })
                    })



                    ElementsDataService.retrieveAllElements("personas-fisicas")
                        .then(
                            respuesta => {
                                console.log("retrieveAllElementsPersonas-fisicasrespuesta", respuesta.data);
                                this.setState({ allPersonasFisicas: respuesta.data })

                                this.state.allPersonasFisicas.map((element) => {
                                    entidadesArray.push({
                                        id: element.id,
                                        nombre: element.nombre + " " + element.apellidoPaterno + " " + element.apellidoMaterno,
                                        rfc: element.rfc,
                                        tipo: "personaFisica"
                                    })
                                })


                                this.setState({ entidadesArray: entidadesArray })

                                console.log("allPersonasFisicas", this.state.allPersonasFisicas);
                                console.log("entidadesArray", entidadesArray)
                            }
                        )

                }
            )




        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("documentos", this.state.id)
            .then(response => {

                console.log("response", response)

                let asuntosArray = response.data.asuntos.split(",")
                let asuntosObjects = []

                for (var i = 0; i < asuntosArray.length; i++) {
                    asuntos.forEach(asunto => {
                        if (asunto.nombre === asuntosArray[i].trim()) {
                            asuntosObjects.push(asunto)
                        }
                    })
                }

                tipoDocumentoAsamblea.forEach(tipo => {
                    if (tipo.nombre === response.data.tipoDocumento) {
                        this.setState({ tipoDocumento: tipo })
                    }
                })

                /*let miniSociedadesArray = []

                console.log()
              
                response.data.sociedades.data.forEach(sociedad =>{
                    miniSociedadesArray.push({id:sociedad.id,
                        razonSocial:sociedad.razonSocial,
                        rfc:sociedad.rfc                       
                    })
                })*/


                this.setState({
                    asuntos: asuntosObjects,
                    sociedades: response.data.sociedades,
                    resumen: response.data.resumen,
                    documentFileUrl: response.data.documentFileUrl,
                    entidades: JSON.parse(response.data.entidadesRolDocumento).data,
                    fecha: response.data.fecha
                })

            })

    }

    validate(values) {
        let errors = {}
        /*
        if (!values.description) {
            errors.description = 'Enter a Description'
        } else if (values.description.length < 5) {
            errors.description = 'Enter atleast 5 Characters in Description'
        }

        if (!moment(values.targetDate).isValid()) {
            errors.targetDate = 'Enter a valid Target Date'
        }*/

        return errors

    }



    onSubmit(values) {

        console.log("onSubmit")
        this.setState({ loading: true })

        console.log("this.state", this.state)

        //Validation block
        //Validation block
        //Validation block
        let messageStr = ""

        if (!this.state.tipoDocumento.nombre) {
            messageStr += "Debe especificar el tipo de documento.\n"
        }

        if (!this.state.sociedades.length) {
            messageStr += "Debe especificar al menos una Sociedad.\n"
        }

        if (!this.state.fecha) {
            messageStr += "No se ha establecido la fecha.\n"
        } else {//adding year validation
            console.log("Fecha: ", this.state.fecha)
            let date = new Date(this.state.fecha);
            console.log("date.getFullYear", date.getFullYear());
            if (date.getFullYear() < 1900 || date.getFullYear() > 2100
                || isNaN(date.getFullYear())) {
                messageStr += "El año " + date.getFullYear() + " no es un valor válido.\n";
            }
        }

        if (!this.state.documentFileUrl) {
            messageStr += "No se ha seleccionado el documento.\n"
        }


        if (messageStr) {
            alert(messageStr)
            this.setState({ loading: false })
            return
        }

        //End Validation block


        const formData = new FormData();
        formData.append('tipoDocumento', this.state.tipoDocumento.nombre);
        formData.append('asuntos', JSON.stringify({ data: this.state.asuntos }));
        formData.append('resumen', this.state.resumen);
        formData.append('sociedades', JSON.stringify({ data: this.state.sociedades }));
        formData.append('entidades', JSON.stringify({ data: this.state.entidades }));
        formData.append('documentoFile', this.state.documentFileUrl);
        formData.append('fecha', this.state.fecha);




        if (Number(this.state.id) === -1) {
            ElementsDataService.createElement("documentos", formData)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "Created") {
                        this.props.history.push(`/documentos/reportes/${response.data.id}`)
                    } else {
                        alert(response.data.message);
                        this.setState({ loading: false })
                    }
                })
        } else {
            ElementsDataService.updateElement("documentos", this.state.id, formData)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "Updated") {
                        this.props.history.push(`/documentos/reportes/${this.state.id}`)
                    } else {
                        alert(response.data.message);
                        this.setState({ loading: false })
                    }
                })
        }

    }

    render() {

        console.log("render DocumentoFormularioComponent")
        console.log("DocumentoFormularioComponent state", this.state)
        let { tipoDocumento,
            resumen,
            sociedades,
            allSociedades,
            allPersonasFisicas,
            entidadesArray,
            documentFileUrl,
            fecha,
            loading
        } = this.state

        let variant = "standard";//'filled' | 'outlined' | 'standard'


        return (
            <div id="main">
                <div className="panel">
                    <LoadingMask loading={loading} text={"loading..."}>
                        <h1><span className="icon-add-outline"></span> Nuevo Documento</h1>
                        <div className="container">

                            <form>

                                <Autocomplete
                                    id="tipoDocumento"
                                    options={tipoDocumentoAsamblea}
                                    getOptionLabel={(option) => option.nombre}

                                    value={tipoDocumento}
                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        this.setState({ tipoDocumento: value })
                                    }}
                                    getOptionSelected={(option, value) => option.nombre === value.nombre}
                                    //value={this.getSelectedItem()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant={variant}
                                            label="Tipo Documento"
                                            placeholder=""
                                            name="tipoDocumento"
                                        />
                                    )}
                                />



                                <Autocomplete
                                    multiple
                                    id="asuntos"
                                    options={asuntos}
                                    getOptionLabel={(option) => option.nombre}

                                    value={this.state.asuntos}
                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        this.setState({ asuntos: value })
                                    }}
                                    getOptionSelected={(option, value) => option.nombre === value.nombre}
                                    //value={this.getSelectedItem()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant={variant}
                                            label="Asuntos"
                                            placeholder=""
                                            name="asuntos"
                                        />
                                    )}
                                />


                                <TextField className="form-control"
                                    value={fecha}
                                    id="date"
                                    label="fecha"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({ fecha: value });
                                    }}
                                />

                                <TextField className="form-control"
                                    value={resumen}
                                    variant={variant}
                                    label="Resumen"
                                    placeholder="Resumen"
                                    name="resumen"
                                    multiline
                                    rows={4}
                                    onChange={event => {
                                        const { value } = event.target;
                                        this.setState({ resumen: value });
                                    }}
                                />

                                <Autocomplete
                                    multiple
                                    id="sociedadesSelector"
                                    options={allSociedades}
                                    getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                    value={sociedades}
                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        this.setState({ sociedades: value })
                                    }}
                                    getOptionSelected={(option, value) => option.rfc === value.rfc}
                                    //value={this.getSelectedItem()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Sociedades"
                                            placeholder=""
                                            name="sociedades"
                                        />
                                    )}
                                />
                                <div className="scrolled">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Ente</th>
                                                <th>Roles</th>
                                                <th>Comentario  </th>
                                                <th className="right"><button type="button" className="btn btn-agregar" onClick={() => {
                                                    var newEntidad = {
                                                        id: this.state.entidades.length + 1,
                                                        nombres: [],
                                                        roles: [],
                                                        comentarios: ''
                                                    };
                                                    this.setState({ entidades: [...this.state.entidades, newEntidad] })
                                                }}>
                                                    <span className="icon-add-solid"></span>   Agregar Ente</button></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.entidades.map(
                                                    element =>
                                                        <tr key={element.id}>
                                                            <td><Autocomplete
                                                                multiple
                                                                id="personasFisicasSelector"
                                                                options={entidadesArray}
                                                                getOptionLabel={(option) => option.nombre + " " + option.rfc + " : " + option.id}

                                                                value={element.nombres}
                                                                filterSelectedOptions
                                                                onChange={(e, value) => {
                                                                    console.log(value);
                                                                    element.nombres = value;
                                                                    this.setState({ element })
                                                                }}
                                                                getOptionSelected={(option, value) => option.rfc === value.rfc}
                                                                //value={this.getSelectedItem()}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Persona Fisica/Moral"
                                                                        placeholder=""
                                                                        name="personasFisicas"
                                                                    />
                                                                )}
                                                            />
                                                            </td>
                                                            <td>
                                                                <Autocomplete
                                                                    multiple
                                                                    id="rolesSelector"
                                                                    options={roles}
                                                                    getOptionLabel={(option) => option.nombre}

                                                                    value={element.roles}
                                                                    filterSelectedOptions
                                                                    onChange={(e, value) => {
                                                                        console.log(value);
                                                                        element.roles = value;
                                                                        this.setState({ element })
                                                                    }}
                                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                                    //value={this.getSelectedItem()}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            label="Roles"
                                                                            placeholder=""
                                                                            name="rolesSelector"
                                                                        />
                                                                    )}
                                                                />
                                                            </td>
                                                            <td >
                                                                <TextField className="form-control"
                                                                    value={element.comentarios}
                                                                    variant={variant}
                                                                    label="Comentario"
                                                                    multiline
                                                                    placeholder="Comentario"
                                                                    name="comentario"
                                                                    onChange={event => {
                                                                        const { value } = event.target;
                                                                        element.comentarios = value;
                                                                        this.setState({ element: element });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="right"><button type="button" className="btn btn-delete" onClick={() => {


                                                                var newEntidadesArray = this.state.entidades;
                                                                console.log("newEntidadesArray", newEntidadesArray)


                                                                newEntidadesArray = this.state.entidades.filter((item) => item.id !== element.id);
                                                                console.log("newEntidadesArray", newEntidadesArray)


                                                                //Recorrer los ids

                                                                for (var i = 0; i < newEntidadesArray.length; i++) {
                                                                    newEntidadesArray[i].id = i + 1;
                                                                }

                                                                console.log("newEntidadesArray", newEntidadesArray)


                                                                this.setState({ entidades: newEntidadesArray })

                                                            }}><span className="icon-trash"></span></button></td>
                                                        </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <br />
                                <br />

                                <div className="cols">
                                    <div className="col1">

                                        <div className="form-group">
                                            <label for="file"><span className="icon-attachment"></span> Archivo Documento:  {documentFileUrl ?
                                                documentFileUrl.name ? "" : documentFileUrl : ""}</label>
                                            <input id="file" name="file" type="file" onChange={(event) => {
                                                this.setState({ "documentFileUrl": event.currentTarget.files[0] });
                                            }} className="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <br />

                                <div className="buttons">

                                    <button className="btn btn-success" onClick={this.onSubmit} type="button" > <span className="icon-checkmark"></span> Guardar</button>
                                </div>
                            </form>

                        </div>
                    </LoadingMask>
                </div>

            </div>
        )
    }
}

const tipoDocumentoAsamblea = [
    { id: 1, nombre: 'Asamblea Ordinaria' },
    { id: 2, nombre: 'Asamblea Extraordinaria' },
    { id: 3, nombre: 'Asamblea Constitutiva' },
    { id: 4, nombre: 'Asamblea Mixta' },
    { id: 5, nombre: 'Mandato' },
    { id: 6, nombre: 'CIF' },
    { id: 7, nombre: 'Acuse de cambio de domicilio' },
    { id: 8, nombre: 'Acuse FIEL' },
    { id: 9, nombre: 'Solicitud de certificado e firma' },
    { id: 10, nombre: 'Carta responsiva para generar los archivos .req y/o .ren' },
    { id: 11, nombre: 'Acuse de movimientos de actualización de situación fiscal' },
    { id: 12, nombre: 'Otro' }
]

const asuntos = [
    { id: 1, nombre: 'Constitutiva' },
    { id: 2, nombre: 'Nombramiento Administrador/Consejo de Administración' },
    { id: 3, nombre: 'Determinacion Emolumentos' },
    { id: 4, nombre: 'Prórroga de la duración de la sociedad' },
    { id: 5, nombre: 'Disolución anticipada de la sociedad' },
    { id: 6, nombre: 'Aumento o reducción del capital social' },
    { id: 7, nombre: 'Cambio de objeto de la sociedad' },
    { id: 8, nombre: 'Cambio de nacionalidad de la sociedad' },
    { id: 9, nombre: 'Transformación de la sociedad' },
    { id: 10, nombre: 'Fusión con otra sociedad' },
    { id: 11, nombre: 'Emisión de acciones privilegiadas' },
    { id: 12, nombre: 'Amortización por la sociedad de sus propias acciones y emisión de acciones de goce' },
    { id: 13, nombre: 'Emisión de bonos' },
    { id: 14, nombre: 'Cualquiera otra modificación del contrato social' },
    { id: 15, nombre: 'Otorgamiento de Poder' },
    { id: 16, nombre: 'Revocación' },
    { id: 17, nombre: 'Aprobación de algún otro Jurídico' },
    { id: 18, nombre: 'Compra venta de acciones/partes sociales' },// cambio de anteriores/nuevos
    { id: 19, nombre: 'Cambio de Domicilio Social' },
    { id: 20, nombre: 'Cambio de Domicilio Fiscal' },
    { id: 21, nombre: 'Cambio de Denominación' },
    { id: 22, nombre: 'Admisión/Renuncia de Asociados' },
    { id: 23, nombre: 'Otro' },

]

const roles = [
    { id: 1, nombre: 'Socio' },
    { id: 2, nombre: 'Accionista' },
    { id: 3, nombre: 'Asociado' },
    { id: 4, nombre: 'Comisario' },
    { id: 5, nombre: 'Administrador General Único' },
    { id: 6, nombre: 'Poderdante' },
    { id: 7, nombre: 'Apoderado' },
    { id: 8, nombre: 'Presidente Consejo Admnistración' },
    { id: 9, nombre: 'Delegado Especial' },
    { id: 10, nombre: 'Socio Administrador' },
    { id: 11, nombre: 'Escrutador' },
    { id: 12, nombre: 'Gerente' },
    { id: 13, nombre: 'Director General' },
    { id: 15, nombre: 'Otro:' },

]

export default DocumentoFormularioComponent