import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import LoadingMask from "react-loadingmask";
import Checkbox from '@material-ui/core/Checkbox';
import AuthenticationService from '../general/AuthenticationService.js'





class SociedadFormularioComponent extends Component {
  constructor(props) {
    super(props)



    this.state = {
      id: this.props.match.params.id,
      correo: '',
      password: '',
      generacion: '',
      domicilios: [],
      opinionCumplimiento: '',
      pendientes: '',
      razonSocial: '',
      revisionFiscal: '',
      status: '',
      statusContabilidad: '',
      responsableContabilidad: '',
      auxiliarContable: '',
      tipo: '',
      situacion: '',
      rfc: '',
      clientes: [],
      personasFisicas: [],
      datosConstitucion: '',
      objetoSocial: '',
      clausulaExtranjeria: '',
      informacionSociosAccionistasStr: '',
      informacionSociosAccionistas: [],
      capitalSocialFijo: '',
      capitalSocialVariable: 'Ilimitado',
      valorNominal: '',
      distribucionCapital: '',
      softDelete: '',
      allDomicilios: [],
      allClientes: [],
      loading: false,
      vigenciaFiel: '',
      telefono: '',
      telefonoDesignadoCliente: '',
      buzonTributarioStatus: '',
      noAplica: '',
      fechaSuspension: '',
      fechaEntrega: '',

    }

    this.onSubmit = this.onSubmit.bind(this)
    this.validate = this.validate.bind(this)

  }


  handleChange = (event) => { }




  useStyles = makeStyles((theme) => ({
    root: {
      width: 500,
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  }));

  removeDuplicatesFromArrayByProperty = (arr, prop) => arr.reduce((accumulator, currentValue) => {
    if (!accumulator.find(obj => obj[prop] === currentValue[prop])) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

  componentDidMount() {
    if (!AuthenticationService.haveAuthorization("WRITE_SOCIEDADES")) {
      alert("Usuario no autorizado")
      this.props.history.push(`/login`)
      return;
    }

    const { match: { params } } = this.props;

    console.log("--params.id", params.id)

    ElementsDataService.retrieveAllElements("clientes")
      .then(
        response => {
          console.log("retrieveAllElementsClientesResponse", response.data);
          this.setState({ allClientes: response.data })
          console.log("allClientes", this.state.allClientes);
        }
      )

    ElementsDataService.retrieveAllElements("domicilios")
      .then(
        response => {
          console.log("retrieveAllElementsDomiciliosResponse", response.data);
          this.setState({ allDomicilios: response.data })
          console.log("allDomicilios", this.state.allDomicilios);
        }
      )

    if (Number(params.id) === -1) {
      return
    }

    ElementsDataService.retrieveElement("sociedades", this.state.id)
      .then(response => {
        this.setState({
          correo: response.data.correo,
          password: response.data.password,
          generacion: response.data.generacion,
          domicilios: response.data.domicilios,
          personasFisicas: response.data.personasFisicas,
          opinionCumplimiento: response.data.opinionCumplimiento,
          pendientes: response.data.pendientes,
          razonSocial: response.data.razonSocial,
          revisionFiscal: response.data.revisionFiscal,
          status: response.data.status,
          tipo: response.data.tipo,
          situacion: response.data.situacion,
          rfc: response.data.rfc,
          clientes: response.data.clientes,
          datosConstitucion: response.data.datosConstitucion,
          objetoSocial: response.data.objetoSocial,
          clausulaExtranjeria: response.data.clausulaExtranjeria,
          capitalSocialFijo: response.data.capitalSocialFijo,
          capitalSocialVariable: response.data.capitalSocialVariable ? response.data.capitalSocialVariable : "Ilimitado",
          valorNominal: response.data.valorNominal,
          distribucionCapital: response.data.distribucionCapital,
          vigenciaFiel: response.data.vigenciaFiel,
          statusContabilidad: response.data.statusContabilidad,
          responsableContabilidad: response.data.responsableContabilidad,
          auxiliarContable: response.data.auxiliarContable,
          telefono: response.data.telefono,
          telefonoDesignadoCliente: response.data.telefonoDesignadoCliente,
          buzonTributarioStatus: response.data.buzonTributarioStatus,
          fechaSuspension: response.data.fechaSuspension,
          fechaEntrega: response.data.fechaEntrega,

        })



      })

  }

  validate(values) {
    let errors = {}


    return errors

  }




  onSubmit(values) {

    console.log("onSubmit this.state.id: ", this.state.id)


    let messageStr = ""

    if (!this.state.razonSocial) {
      messageStr += "Debe especificar una Razon Social\n"
    }

    if (!this.state.rfc) {
      messageStr += "Debe especificar un RFC\n"
    }


    if (messageStr) {
      alert(messageStr)
      this.setState({ loading: false })
      return
    }

    this.setState({ loading: true })

    let sociedad = {
      id: this.state.id,
      correo: this.state.correo,
      password: this.state.password,
      generacion: this.state.generacion,
      opinionCumplimiento: this.state.opinionCumplimiento,
      pendientes: this.state.pendientes,
      razonSocial: this.state.razonSocial,
      domicilios: this.state.domicilios,
      clientes: this.state.clientes,
      revisionFiscal: this.state.revisionFiscal,
      status: this.state.status,
      statusContabilidad: this.state.statusContabilidad,
      responsableContabilidad: this.state.responsableContabilidad,
      auxiliarContable: this.state.auxiliarContable,
      tipo: this.state.tipo,
      situacion: this.state.situacion,
      rfc: this.state.rfc,
      datosConstitucion: this.state.datosConstitucion,
      distribucionCapital: this.state.distribucionCapital,
      objetoSocial: this.state.objetoSocial,
      clausulaExtranjeria: this.state.clausulaExtranjeria,
      capitalSocialFijo: this.state.capitalSocialFijo,
      capitalSocialVariable: this.state.capitalSocialVariable,
      valorNominal: this.state.valorNominal,
      vigenciaFiel: this.state.vigenciaFiel,
      telefono: this.state.telefono,
      telefonoDesignadoCliente: this.state.telefonoDesignadoCliente,
      buzonTributarioStatus: this.state.buzonTributarioStatus,
      fechaSuspension: this.state.fechaSuspension,
      fechaEntrega: this.state.fechaEntrega,

    }

    if (Number(this.state.id) === -1) {
      ElementsDataService.createElement("sociedades", sociedad)
        .then(response => {
          console.log(response);
          if (response.data.message == "Created") {
            this.props.history.push(`/sociedades/reportes/${response.data.id}`)
          } else {
            alert(response.data.message);
            this.setState({ loading: false })
          }
        })
    } else {
      ElementsDataService.updateElement("sociedades", this.state.id, sociedad)
        .then(() => this.props.history.push(`/sociedades/reportes/${this.state.id}`))
    }

    console.log(sociedad);
  }

  render() {

    let { correo,
      password,
      generacion,
      opinionCumplimiento,
      pendientes,
      razonSocial,
      revisionFiscal,
      status,
      tipo,
      situacion,
      domicilios,
      rfc,
      clientes,
      personasFisicas,
      datosConstitucion,
      objetoSocial,
      clausulaExtranjeria,
      capitalSocialFijo,
      capitalSocialVariable,
      valorNominal,
      distribucionCapital,
      allDomicilios,
      allClientes,
      loading,
      vigenciaFiel,
      statusContabilidad,
      responsableContabilidad,
      auxiliarContable,
      telefono,
      telefonoDesignadoCliente,
      buzonTributarioStatus,
      noAplica,
      fechaSuspension,
      fechaEntrega

    } = this.state

    let variant = "standard";//'filled' | 'outlined' | 'standard'



    return (
      <div id="main">
        <LoadingMask loading={loading} text={"loading..."}>
          <div className="panel">

            <h1>{razonSocial ? razonSocial : "Nueva Sociedad"}</h1>
            <div className="container">
              <Formik
                initialValues={{
                  correo,
                  password,
                  generacion,
                  opinionCumplimiento,
                  pendientes,
                  razonSocial,
                  revisionFiscal,
                  status,
                  statusContabilidad,
                  responsableContabilidad,
                  auxiliarContable,
                  tipo,
                  situacion,
                  rfc,
                  domicilios,
                  personasFisicas,
                  clientes,
                  datosConstitucion,
                  objetoSocial,
                  clausulaExtranjeria,
                  capitalSocialFijo,
                  capitalSocialVariable,
                  valorNominal,
                  distribucionCapital,
                  telefono,
                  telefonoDesignadoCliente,
                  buzonTributarioStatus,
                  fechaSuspension,
                  fechaEntrega
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {
                  ({ props, setFieldValue, values }) => (
                    <Form>

                      <div className="cols">
                        <div className="col2 col">
                          <TextField className="form-control"
                            value={razonSocial}
                            variant={variant}
                            label="Razon Social"
                            placeholder="Razon Social"
                            name="razonSocial"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ razonSocial: value.toUpperCase() });
                            }}
                          />
                        </div>
                        <div className="col2 col">
                          <TextField autoComplete="off" className="form-control"
                            value={rfc}
                            variant={variant}
                            label="RFC"
                            placeholder="RFC"
                            name="rfc"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ rfc: value.toUpperCase() });
                            }}
                          />
                        </div>
                      </div>
                      <div className="cols">
                        <div className="col2 col">
                          <TextField className="form-control"
                            value={responsableContabilidad}
                            variant={variant}
                            label="Responsable Contabilidad"
                            placeholder="Responsable Contabilidad"
                            name="responsableContabilidad"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ responsableContabilidad: value.toUpperCase() });
                            }}
                          />
                        </div>
                        <div className="col2 col">
                          <TextField autoComplete="off" className="form-control"
                            value={auxiliarContable}
                            variant={variant}
                            label="Auxiliar Contable"
                            placeholder="Auxiliar Contable"
                            name="auxiliarContable"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ auxiliarContable: value.toUpperCase() });
                            }}
                          />
                        </div>
                      </div>
                      <div className="cols">
                        <div className="col2 col">
                          <Autocomplete
                            multiple
                            id="clientes"
                            options={allClientes}
                            getOptionLabel={(option) => option.nombreCliente}

                            value={clientes}
                            filterSelectedOptions
                            onChange={(e, value) => {
                              console.log(value);
                              this.setState({ clientes: value });
                            }}

                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant={variant}
                                label="Proyectos"
                                placeholder=""
                                name="clientes"
                              />
                            )}
                          />
                        </div>
                        <div className="col2 col">
                          <TextField className="form-control"
                            value={fechaEntrega}
                            id="date"
                            label="Fecha Entrega a Cliente"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ fechaEntrega: value });
                            }}
                          />

                        </div>
                      </div>

                      <div className="cols">

                        <div className="col2 col">

                          <TextField autoComplete="off" className="form-control"
                            value={telefono}
                            variant={variant}
                            label="Telefono"
                            placeholder="Telefono"
                            name="telefono"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ telefono: value });
                            }}
                          />
                        </div>

                        <div className="col2 col">

                          <TextField autoComplete="off" className="form-control"
                            value={telefonoDesignadoCliente}
                            variant={variant}
                            label="Telefono Designado por el cliente"
                            placeholder="Telefono Designado por el cliente"
                            name="telefonoDesignadoCliente"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ telefonoDesignadoCliente: value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="cols">
                        <div className="col3 col">

                          <FormControl variant={variant} className="form-control">
                            <InputLabel >Estatus Buzon tributario</InputLabel>
                            <Select

                              value={buzonTributarioStatus}
                              onChange={event => {
                                const { value } = event.target;
                                this.setState({ buzonTributarioStatus: value });
                              }}
                              label="Estatus Contable"

                            >
                              <option aria-label="None" value="" />
                              <option value={"ACTIVO"}>ACTIVO</option>
                              <option value={"INACTIVO"}>INACTIVO</option>
                            </Select>
                          </FormControl>

                        </div>
                      </div>

                      <div className="cols">

                        <div className="col3 col">

                          <TextField autoComplete="off" className="form-control"
                            value={correo}
                            variant={variant}
                            label="Correo"
                            placeholder="Correo"
                            name="correo"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ correo: value });
                            }}
                          />
                        </div>

                        <div className="col3 col">

                          <TextField autoComplete="off" className="form-control"
                            value={password}
                            variant={variant}
                            label="Contraseña correo"
                            placeholder="password"
                            name="password"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ password: value });
                            }}
                          />
                        </div>

                        <div className="col3 col">

                          <TextField autoComplete="off" className="form-control"
                            value={vigenciaFiel}
                            id="date"
                            label="Vigencia FIEL"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ vigenciaFiel: value });
                            }}
                          />

                        </div>
                      </div>
                      <div className="cols">

                        <div className="col2 col">

                          <Autocomplete
                            multiple
                            id="domicilios"
                            disabled={!AuthenticationService.haveAuthorization("SUPERVISOR_DOMICILIOS")}
                            options={allDomicilios}
                            getOptionLabel={(option) => option.calle + " " + option.numeroExt
                              + (option.numeroInterior ? " Int: " + option.numeroInterior : "") + " " + option.colonia}

                            value={domicilios}
                            filterSelectedOptions
                            onChange={(e, value) => {
                              console.log(value);
                              this.setState({ domicilios: value });
                            }}

                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant={variant}
                                label="Domicilios"
                                placeholder=""
                                name="domicilios"
                              />
                            )}
                          />
                        </div>
                        <div className="col3 col">

                          <TextField autoComplete="off" className="form-control"
                            value={generacion}
                            variant={variant}
                            label="Generación"
                            placeholder="Generación"
                            name="generacion"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ generacion: value });
                            }}
                          />
                        </div>

                      </div>
                      <div className="cols">

                        <div className="col3 col">
                          <TextField autoComplete="off" className="form-control"
                            value={situacion}
                            variant={variant}
                            label="Situación"
                            placeholder="Situación"
                            name="situacion"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ situacion: value });
                            }}
                          />

                        </div>
                        <div className="col3 col">

                          <FormControl variant={variant} className="form-control">
                            <InputLabel >Estatus Legal</InputLabel>
                            <Select

                              value={status}
                              onChange={event => {
                                const { value } = event.target;
                                this.setState({ status: value });
                              }}
                              label="Estatus Legal"

                            >
                              <option aria-label="None" value="" />
                              <option value={"Activa"}>Activa</option>
                              <option value={"Inactiva"}>Inactiva</option>
                              <option value={"Susp>L..."}>Susp{'>'}L...</option>
                              <option value={"Liqx"}>Liqx</option>
                              <option value={"P.E."}>P.E.</option>
                              <option value={"React"}>React</option>
                              <option value={"N/A"}>N/A</option>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col3 col">

                          <FormControl variant={variant} className="form-control">
                            <InputLabel >Estatus Contable</InputLabel>
                            <Select

                              value={statusContabilidad}
                              onChange={event => {
                                const { value } = event.target;
                                this.setState({ statusContabilidad: value });
                              }}
                              label="Estatus Contable"

                            >
                              <option aria-label="None" value="" />
                              <option value={"ACTIVA"}>ACTIVA</option>
                              <option value={"SIN UTILIZAR"}>SIN UTILIZAR</option>
                              <option value={"INACTIVA"}>INACTIVA</option>
                              <option value={"PROCESO LIQUIDACION"}>PROCESO LIQUIDACION</option>
                              <option value={"LIQUIDADA"}>LIQUIDADA</option>
                              <option value={"PROCESO SUSPENSION"}>PROCESO SUSPENSION</option>
                              <option value={"SUSPENDIDA"}>SUSPENDIDA</option>
                              <option value={"VENDIDA"}>VENDIDA</option>
                              <option value={"DEVUELTA"}>DEVUELTA</option>
                              <option value={"NO REVISION"}>NO REVISION</option>
                            </Select>
                          </FormControl>
                          {(statusContabilidad === "SUSPENDIDA") &&

                            <TextField className="form-control"
                              value={fechaSuspension}
                              id="date"
                              label="Fecha Suspensión"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={event => {
                                const { value } = event.target;
                                this.setState({ fechaSuspension: value });
                              }}
                            />}

                        </div>
                      </div>


                      <div className="cols">

                        <div className="col3 col">

                          <TextField autoComplete="off" className="form-control"
                            value={tipo}
                            variant={variant}
                            label="Tipo"
                            placeholder="Tipo"
                            name="tipo"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ tipo: value });
                            }}
                          />
                        </div>

                        <div className="col2 col">

                          <TextField autoComplete="off" className="form-control"
                            value={pendientes}
                            variant={variant}
                            label="Pendientes"
                            placeholder="Pendientes"
                            name="pendientes"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ pendientes: value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="cols">

                        <div className="col1">

                          <TextField autoComplete="off" className="form-control"
                            value={datosConstitucion}
                            variant={variant}
                            label="Datos Constitución"
                            placeholder="Datos Constitución"
                            name="datosConstitucion"
                            multiline
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ datosConstitucion: value });
                            }}
                          />


                        </div>
                      </div>
                      <div className="cols">

                        <div className="col1">

                          <TextField autoComplete="off" className="form-control"
                            value={objetoSocial}
                            variant={variant}
                            label="Objeto Social"
                            placeholder="Objeto Social"
                            name="objetoSocial"
                            multiline
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ objetoSocial: value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="cols">
                        <div className="col2 col">

                          <FormControl variant={variant} className="form-control">
                            <InputLabel >Clausula Extranjería</InputLabel>
                            <Select

                              value={clausulaExtranjeria}
                              onChange={event => {
                                const { value } = event.target;
                                this.setState({ clausulaExtranjeria: value });
                              }}
                              label="Clausula Extranjería"

                            >
                              <option aria-label="None" value="" />
                              <option value={"Admisión de extranjeros"}>Admisión de extranjeros</option>
                              <option value={"Exclusión de extranjeros"}>Exclusión de extranjeros</option>
                            </Select>
                          </FormControl>

                        </div>
                        <div className="col2 col">

                          <FormControl variant={variant} className="form-control">
                            <InputLabel >Revision Fiscal</InputLabel>
                            <Select

                              value={revisionFiscal}
                              onChange={event => {
                                const { value } = event.target;
                                this.setState({ revisionFiscal: value });
                              }}
                              label="Revision"
                            >
                              <option aria-label="None" value="" />
                              <option value={"SI"}>SI</option>
                              <option value={"NO"}>NO</option>
                            </Select>
                          </FormControl>

                        </div>
                      </div>
                      <hr></hr>

                      <div className="cols">
                        <div className="form-group">
                          <label>No Aplican los Siguientes Campos</label>
                          <Checkbox
                            checked={noAplica}
                            color="primary"
                            onChange={event => {

                              if (event.target.checked) {

                                this.setState({
                                  noAplica: true,
                                  capitalSocialFijo: "N/A",
                                  capitalSocialVariable: "N/A",
                                  valorNominal: "N/A",
                                  distribucionCapital: "N/A"
                                });

                              } else {
                                this.setState({
                                  noAplica: false,
                                  capitalSocialFijo: "",
                                  capitalSocialVariable: "",
                                  valorNominal: "",
                                  distribucionCapital: ""
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="cols">

                        <div className="col4 col">
                          <TextField autoComplete="off" className="form-control"
                            value={capitalSocialFijo}
                            variant={variant}
                            label="Capital Social Fijo"
                            placeholder="Capital Social Fijo"
                            name="capitalSocialFijo"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ capitalSocialFijo: value });
                            }}
                          />
                        </div>
                        <div className="col4 col">

                          <TextField autoComplete="off" className="form-control"
                            value={capitalSocialVariable}
                            variant={variant}
                            label="Capital Social Variable"
                            placeholder="Capital Social Variable"
                            name="capitalSocialVariable"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ capitalSocialVariable: value });
                            }}
                          />
                        </div>
                        <div className="col4 col">

                          <TextField autoComplete="off" className="form-control"
                            value={valorNominal}
                            variant={variant}
                            label="Valor Nominal"
                            placeholder="Valor Nominal"
                            name="valorNominal"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ valorNominal: value });
                            }}
                          />
                        </div>
                        <div className="col4 col">

                          <TextField autoComplete="off" className="form-control"
                            value={distribucionCapital}
                            variant={variant}
                            label="Distribución Capital"
                            placeholder="Distribución Capital"
                            name="distribucionCapital"
                            onChange={event => {
                              const { value } = event.target;
                              this.setState({ distribucionCapital: value });
                            }}
                          />
                        </div>


                      </div>





                      <div className="buttons">

                        <button className="btn btn-success" type="submit"><span className="icon-checkmark"></span> Guardar</button>
                      </div>

                      <ErrorMessage name="razonSocial" component="div"
                        className="alert alert-warning" />
                      <ErrorMessage name="rfc" component="div"
                        className="alert alert-warning" />

                    </Form>
                  )
                }
              </Formik>

            </div>
          </div>
        </LoadingMask>
      </div>
    )
  }
}

export default SociedadFormularioComponent