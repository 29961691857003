import React, { Component } from 'react'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



function saveFile(formData) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    return ElementsDataService.createElement("cfdis/opinion-cumplimiento", formData, config)
}

class getCFDIs extends Component {
    constructor(props) {
        super(props)

        const arrayMeses = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ];

        this.state = {
            id: this.props.match.params.id,
            allSociedades: [],
            loading: false,
            buttonEnabled: true,
            rfc: '',
            ejercicio: new Date().getFullYear(),
            periodo:"all",//mes
        }

        this.onSubmit = this.onSubmit.bind(this)

    }

    componentDidMount() {
        console.log("getCFDIs componentDidMount")

        let sociedadesArray = [];

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {

                    // console.log("retrieveAllSociedadesResponse", response.data);
                    // console.log("Sociedades: " + response.data)

                    response.data.map((element) => {
                        sociedadesArray.push({
                            id: element.id,
                            razonSocial: element.razonSocial,
                            rfc: element.rfc
                        })
                    })

                    this.setState({ allSociedades: sociedadesArray, loading: false })


                }
            )


    }


    async onSubmit(values) {



    }

    render() {

        let { loading, allSociedades, rfc, ejercicio, periodo } = this.state

        console.log("render getCFDIs")
        console.log("getCFDIs state", this.state)

        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <h1>Obtener CFDIs</h1>
                        <div className="container">

                            <div className="cols">
                                <div className="col3">

                                    <Autocomplete
                                        id="sociedadesSelector"
                                        options={allSociedades}
                                        getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            console.log(value);
                                            this.setState({ rfc: value.rfc })

                                        }}
                                        getOptionSelected={(option, value) => option.rfc === value.rfc}
                                        //value={this.getSelectedItem()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Sociedades"
                                                placeholder=""
                                                name="sociedades"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col3">

                                    <label htmlFor=""><span className="icon-calendar"></span> Ejercicio:</label> <input type="number" min="1900" max="2099" step="1" value={ejercicio}
                                        onChange={event => {
                                            const { value } = event.target;
                                            this.setState({ ejercicio: value });
                                        }}
                                    />
                                </div>

                                <div className="col3">
                                    <FormControl className="form-control">
                                        <InputLabel>Periodo</InputLabel>
                                        <Select

                                            value={periodo}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ periodo: value });
                                            }}

                                        >
                                            <option value="all" >Todos</option>
                                            <option value={"Enero"}>Enero</option>
                                            <option value={"Febrero"}>Febrero</option>
                                            <option value={"Marzo"}>Marzo</option>
                                            <option value={"Abril"}>Abril</option>
                                            <option value={"Mayo"}>Mayo</option>
                                            <option value={"Junio"}>Junio</option>
                                            <option value={"Julio"}>Julio</option>
                                            <option value={"Agosto"}>Agosto</option>
                                            <option value={"Septiembre"}>Septiembre</option>
                                            <option value={"Octubre"}>Octubre</option>
                                            <option value={"Noviembre"}>Noviembre</option>
                                            <option value={"Diciembre"}>Diciembre</option>

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="cols">
                                <div className="col3">
                                    <div className="tool-btn-agregar">
                                        <button type="button" className="btn btn-success" onClick={() => {
                                            console.log("Descargando MetaData")
                                            console.log("rfc: ", rfc)
                                            console.log("ejercicio: ", ejercicio)
                                            console.log("periodo: ", periodo)

                                            if (rfc) {
                                                this.setState({loading:true})

                                                ElementsDataService.retrieveAllElements(`cfdis/getcfdis/${rfc}/${periodo}/${ejercicio}`)
                                                    .then(response => {

                                                        console.log("CFDIs")

                                                        console.log(response)

                                                        if(response.data.length === 0){
                                                            alert("No hay CFDIs para RFC/periodo/ejercicio")
                                                            this.setState({loading:false})
                                                            return;
                                                        }

                                                        let csvStr = "Uuid~RfcEmisor~NombreEmisor~RfcReceptor~NombreReceptor~RfcPac~FechaEmision~FechaCertificacionSat~Monto~EfectoComprobante~Estatus~FechaCancelacion\n"

                                                        response.data.forEach(cfdi => {
                                                           csvStr += cfdi.uuid + "~"
                                                           csvStr += cfdi.rfcEmisor + "~"
                                                           csvStr += cfdi.nombreEmisor + "~"
                                                           csvStr += cfdi.rfcReceptor + "~"
                                                           csvStr += cfdi.nombreReceptor + "~"
                                                           csvStr += cfdi.rfcPac + "~"
                                                           csvStr += cfdi.fechaEmision + "~"
                                                           csvStr += cfdi.fechaCertificacionSat + "~"
                                                           csvStr += cfdi.monto + "~"
                                                           csvStr += cfdi.efectoComprobante + "~"
                                                           csvStr += cfdi.estatus + "~"
                                                           csvStr += cfdi.fechaCancelacion + "\n"                                                           
                                                        })

                                                        const element = document.createElement("a");
                                                        const file = new Blob([csvStr], { type: 'text/plain' });
                                                        element.href = URL.createObjectURL(file);
                                                        var d = new Date();
                                                        element.download = `CFDIs ${rfc} ${periodo}/${ejercicio}.csv`;
                                                        document.body.appendChild(element); // Required for this to work in FireFox
                                                        element.click();

                                                        this.setState({loading:false})

                                                    })

                                            } else {
                                                alert("Debe Seleccionar un RFC")
                                            }

                                        }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default getCFDIs