import axios from 'axios'
import { JPA_API_URL } from '../../Constants'
import download from 'downloadjs'

class ElementsDataService {

    retrieveAllElements(ruta) {
        console.log('executed service retrieveAllElements' + ruta)
        return axios.get(`${JPA_API_URL}/${ruta}`);
    }


    retrieveSearch(busqueda, tipoObjeto) {

        console.log('executed service retrieve Seach' + busqueda)
        return axios.get(`${JPA_API_URL}/busqueda/${tipoObjeto}/${busqueda}`)

    }

    retrieveElement(ruta, id) {
        console.log('executed service retrieveElement ruta: ' + ruta + '/' + id)
        return axios.get(`${JPA_API_URL}/${ruta}/${id}`);
    }

    retrieveElementPost(ruta, element) {
        console.log('executed service retrieveElementPost ruta: ' + ruta)
        return axios.post(`${JPA_API_URL}/${ruta}/`, element);
    }



    deleteElement(ruta, id) {
        console.log('executed service deleteElement ruta: ' + ruta + '/' + id)
        return axios.delete(`${JPA_API_URL}/${ruta}/${id}`);
    }

    updateElement(ruta, id, element) {
        console.log('executed service updateElement ruta: ' + ruta + '/' + id)
        return axios.put(`${JPA_API_URL}/${ruta}/${id}`, element);
    }

    createElement(ruta, element) {
        console.log('executed service createElement ruta: ' + ruta)
        return axios.post(`${JPA_API_URL}/${ruta}/`, element)
            .catch((error) => {
                console.log("Error Manejado")
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    saveFile(file) {
        console.log('executed service saveFiles ')
        return axios.post(`${JPA_API_URL}/files/`, file);
    }

    getFile(id) {
        console.log('executed service getFile')
        return axios.get(`${JPA_API_URL}/files/${id}`);
    }

    async getBase64(url) {
        const response = await axios
            .get(`${JPA_API_URL}/download${url}`, {
                responseType: 'arraybuffer'
            });
        return Buffer.from(response.data, 'binary').toString('base64');
    }

    async getPDFFile(url) {
        const response = await axios
            .get(`${JPA_API_URL}/download${url}`, {
                responseType: 'arraybuffer'
            });
        return response.data;
    }

    async getImageFile(url) {
        const response = await axios
            .get(`${JPA_API_URL}/download${url}`, {
                responseType: 'blob'
            });
        return response.data;
    }

    downloadS3File(path, fileName, checkFile = false) {
        axios.get(`${JPA_API_URL}/download${path}`, {
            headers: this.headers,
            responseType: 'blob',
        })
            .then(response => {

                //console.log(response)

                var fileReader = new FileReader();
                fileReader.onloadend = function (e) {
                    var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                    var header = "";
                    for (var i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16);
                    }
                    console.log(header);

                    let type = '';

                    switch (header) {
                        case "89504e47":
                            type = "image/png";
                            break;
                        case "47494638":
                            type = "image/gif";
                            break;
                        case "ffd8ffe0":
                        case "ffd8ffe1":
                        case "ffd8ffe2":
                        case "ffd8ffe3":
                        case "ffd8ffe8":
                            type = "image/jpeg";
                            break;
                        default:
                            type = "unknown"; // Or you can use the blob.type as fallback
                            break;
                    }

                    console.log(type);
                    
                    if(type === "image/jpeg"){
                        fileName = fileName.replace(".pdf", ".jpg");
                    }else  if(type === "image/png"){
                        fileName = fileName.replace(".pdf", ".png");
                    }

                    download(response.data, fileName);

                    // Check the file signature against known types

                };
                if(checkFile){
                    fileReader.readAsArrayBuffer(response.data);
                }else{
                    download(response.data, fileName);
                }

            })
            .catch(error => console.log(error));
    }


    downloadFile(folderName, fileName) {
        axios
            .get(`${JPA_API_URL}/files/${folderName}/${fileName}`, {
                headers: this.headers,
                responseType: 'blob',
            })
            .then(response => {
                console.log(response)
                const content = response.headers['content-type'];
                download(response.data, fileName)
            })
            .catch(error => console.log(error));
    }



}


export default new ElementsDataService()