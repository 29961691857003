import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'html-react-parser';
import { AiOutlineFileSearch } from 'react-icons/ai'
import AuthenticationService from '../general/AuthenticationService.js'

class DocumentoComponent extends Component {
    constructor(props) {
        console.log("DocumentoComponent Constructor")
        super(props)


        this.state = {
            description: '',
            elements: [],
            count: '',
            pagenumber: '',
            allSociedades: [],
            entidadesArray: [],
            loading: true,
            ...props
        }

        this.goBusqueda = this.goBusqueda.bind(this)

    }

    goBusqueda() {

        console.log("goBusqueda")
        this.setState({ loading: true })

        if (this.state.busqueda.toUpperCase().length > 2) {
            ElementsDataService.retrieveSearch(this.state.busqueda.toUpperCase(), "documentos")
                .then(
                    response => {
                        console.log("retrieveBusqueda", response.data);
                        if (response.data.length == 0) {
                            alert("Esta búsqueda no arroja resultados.")
                        }
                        this.setState({ elements: response.data, loading: false })
                        window.removeEventListener('scroll', this.handleScroll);
                    }
                )
        } else {
            alert("Debe escribir al menos tres caracteres para realizar una búsqueda.")
            this.setState({ elements: [], loading: false })
        }

    }

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_DOCUMENTOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }


        ElementsDataService.retrieveAllElements("documentosCount")
            .then(
                response => {
                    console.log(response);
                    this.setState({ count: response.data })

                    ElementsDataService.retrieveAllElements("documentosPaginated?pageNumber=0&size=30")
                        .then(
                            response => {
                                // console.log(response);
                                this.setState({ elements: response.data, loading: false })
                                window.addEventListener('scroll', this.handleScroll, { passive: true })

                            }
                        )
                }
            )


        this.setState({ elements: [] })

        let entidadesArray = [];

        let sociedadesArray = [];

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {

                    console.log("retrieveAllSociedadesResponse", response.data);
                    console.log("Sociedades: " + response.data)

                    response.data.map((element) => {
                        sociedadesArray.push({
                            id: element.id,
                            razonSocial: element.razonSocial,
                            rfc: element.rfc
                        })
                    })

                    this.setState({ allSociedades: sociedadesArray, loading: false })

                    this.state.allSociedades.map((element) => {
                        entidadesArray.push({
                            id: element.id,
                            nombre: element.razonSocial,
                            rfc: element.rfc,
                            tipo: "sociedad"
                        })
                    })
                }
            )

    }


    handleScroll = () => {


        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {

            let pagenumber = this.state.pagenumber;

            pagenumber++;

            this.setState({ pagenumber })

            console.log("pagenumber", pagenumber)

            if (pagenumber * 30 < this.state.count) {

                ElementsDataService.retrieveAllElements("documentosPaginated?pageNumber=" + pagenumber + "&size=30")
                    .then(
                        response => {
                            console.log(response.data);
                            //console.log(this.state.elements)
                            let elementsArray = this.state.elements.concat(response.data)
                            //console.log("elementsArray", elementsArray)

                            this.setState({ elements: elementsArray })

                            // window.addEventListener('scroll', this.handleScroll, { passive: true })

                        }
                    )

            } else {
                window.removeEventListener('scroll', this.handleScroll);
            }

        }

    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }



    render() {

        console.log("render DocumentoComponent", this.state)

        let counter = 0;
        let { loading, allSociedades, busqueda } = this.state

        return (
            <LoadingMask loading={loading} text={"loading..."}>

                <div id="main">

                    <div className="panel">



                        <div className="toolSearch">

                            <span className="icon-search"></span>

                            <TextField className="form-control"
                                value={this.state.busqueda}
                                label="Busqueda"
                                placeholder="Buscar"
                                name="busqueda"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        this.goBusqueda();
                                    }
                                }}
                                onChange={event => {
                                    const { value } = event.target;

                                    this.setState({ busqueda: value.toUpperCase() });

                                    if (!value) {

                                        this.setState({ loading: true })
                                        ElementsDataService.retrieveAllElements("documentosCount")
                                            .then(
                                                response => {
                                                    console.log(response);
                                                    this.setState({ count: response.data })

                                                    ElementsDataService.retrieveAllElements("documentosPaginated?pageNumber=0&size=30")
                                                        .then(
                                                            response => {
                                                                // console.log(response);
                                                                this.setState({ elements: response.data, loading: false })
                                                                window.addEventListener('scroll', this.handleScroll, { passive: true })

                                                            }
                                                        )
                                                }
                                            )

                                    }



                                }}
                            />
                            <div className="tool-btn-agregar float-right" >
                                <button className="btn btn-agregar" onClick={this.goBusqueda}><AiOutlineFileSearch /></button>
                            </div>
                        </div>

                        <Autocomplete
                            id="sociedadesSelector"
                            options={allSociedades}
                            getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                            filterSelectedOptions
                            onChange={(e, value) => {
                                console.log(value);

                                this.setState({ loading: true, elements: [] })
                                if (value) {
                                    ElementsDataService.retrieveElement("getDocumentosSociedad", value.id)
                                        .then(
                                            response => {
                                                // console.log(response);
                                                this.setState({ elements: response.data, loading: false })
                                                window.removeEventListener('scroll', this.handleScroll);

                                            }
                                        )
                                } else {
                                    ElementsDataService.retrieveAllElements("documentosPaginated?pageNumber=0&size=30")
                                        .then(
                                            response => {
                                                // console.log(response);
                                                this.setState({ elements: response.data, loading: false })
                                                window.addEventListener('scroll', this.handleScroll, { passive: true })

                                            }
                                        )
                                }
                            }}
                            getOptionSelected={(option, value) => option.rfc === value.rfc}
                            //value={this.getSelectedItem()}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Sociedades"
                                    placeholder=""
                                    name="sociedades"
                                />
                            )}
                        />

                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button className="btn btn-agregar" onClick={this.props.addElementClicked}><span className="icon-add-solid"> </span><span className="icon-document"></span></button>
                            </div>
                            <h1>Documentos</h1>
                        </div>
                        {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                        <div className="container">
                            <div id="tablesContainer">
                                <div className="tableContainer">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th>Sociedades</th>
                                                <th>Tipo Documento</th>
                                                <th>Asuntos</th>
                                                <th>Fecha</th>
                                                <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                                <th className="icoSize"><span className="icon-trash"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.elements && this.state.elements.map(
                                                    element =>
                                                        <tr key={element.id}>
                                                            <td>{++counter}</td>
                                                            <td>{
                                                                element.sociedades ? element.sociedades.map(
                                                                    sociedad => <Link to={`/sociedades/reportes/${sociedad.id}`} >
                                                                        <p> {busqueda.length > 2 ? parse(sociedad.razonSocial.replace(busqueda, "<span style=\"color:blue;\">" + busqueda + "</span>")) : sociedad.razonSocial}</p></Link>) : ""
                                                            }</td>
                                                            <td><Link to={`/documentos/reportes/${element.id}`}>{element.tipoDocumento}</Link></td>
                                                            <td><Link to={`/documentos/reportes/${element.id}`}>{element.asuntos ? element.asuntos : element.tipoDocumento}</Link></td>

                                                            <td>{element.fecha}</td>

                                                            <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                                                            <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>
                                                        </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default DocumentoComponent