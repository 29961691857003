import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



class HistoriaComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            description: '',
            elements: [],
            count: '',
            pagenumber: '',
            usuarios: [],
            usuario: '',
            tipoObjeto: ''

        }



    }

    componentDidMount() {

        ElementsDataService.retrieveAllElements("historiaCount")
            .then(
                response => {
                    //console.log(response);
                    this.setState({ count: response.data })

                    ElementsDataService.retrieveAllElements("historia?pageNumber=0&size=50")
                        .then(
                            response => {
                                // console.log(response);
                                this.setState({ elements: response.data })

                                window.addEventListener('scroll', this.handleScroll, { passive: true })

                                ElementsDataService.retrieveAllElements("usuarios")
                                    .then(
                                        response => {
                                            //console.log(response);
                                            this.setState({ usuarios: response.data })
                                        }
                                    )

                            }
                        )
                }
            )

    }

    refreshElements = () => {

        ElementsDataService.retrieveAllElements("historia?pageNumber=0&size=50" +
        "&objeto=" + this.state.tipoObjeto + "&usuario=" + this.state.usuario)
            .then(
                response => {
                    // console.log(response);
                    this.setState({ elements: response.data })
                }
            )

    }

    handleScroll = () => {


        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {

            let pagenumber = this.state.pagenumber;

            pagenumber++;

            this.setState({ pagenumber })

            console.log("pagenumber", pagenumber)

            if (pagenumber * 50 < this.state.count) {

                ElementsDataService.retrieveAllElements("historia?pageNumber=" + pagenumber + "&size=50"  +
                "&objeto=" + this.state.tipoObjeto + "&usuario=" + this.state.usuario)
                    .then(
                        response => {
                            console.log(response.data);
                            //console.log(this.state.elements)
                            let elementsArray = this.state.elements.concat(response.data)
                            //console.log("elementsArray", elementsArray)

                            this.setState({ elements: elementsArray })

                            //window.addEventListener('scroll', this.handleScroll, { passive: true })

                        }
                    )

            } else {
                window.removeEventListener('scroll', this.handleScroll);
            }

        }

    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }



    render() {

        let { description, elements, usuarios, tipoObjeto } = this.state

        let counter = 0

        let objetos = ["Contabilidad", "Cfdi", "Cliente", "Domicilio", "PersonaFisica", "Sistema", "Sociedad"]


        return (
            <div id="main">
                <div className="panel">
                    <div className="titles">

                        <h1>Historial</h1>
                    </div>

                    <div className="cols">

                        <div className="col2">
                            <Autocomplete
                                id="usuario"
                                options={usuarios}
                                getOptionLabel={(option) => option}

                                filterSelectedOptions
                                onChange={(e, value) => {
                                    console.log("usuario value", value);

                                  
                                    this.setState({pagenumber:1, usuario: value?value:"" },() => {
                                        this.refreshElements();
                                      })


                                }}
                                // getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Usuario"
                                        placeholder=""
                                        name="usuario"
                                    />
                                )}
                            />
                        </div>
                        <div className="col2">
                            <Autocomplete
                                id="objeto"
                                options={objetos}
                                getOptionLabel={(option) => option}

                                filterSelectedOptions
                                onChange={(e, value) => {
                                    console.log("objeto value", value);

                                    this.setState({pagenumber:1, tipoObjeto: value?value:"" },() => {
                                        this.refreshElements();
                                      })

                                    

                                }}
                                // getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Tipo"
                                        placeholder=""
                                        name="objeto"
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="container">
                        <div id="tablesContainer">
                            <div className="tableContainer">

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Usuario</th>
                                            <th>Evento</th>
                                            <th>Fecha/Hora</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            elements && elements.map(
                                                item =>
                                                    <tr key={item.id}>
                                                        <td>{item.userId}</td>
                                                        <td>{item.descripcionEvento}</td>
                                                        <td>{convertDateTime(item.dateTime)}</td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


function convertDateTime(dateTime) {

    /*let timeArray = dateTime.split("T");
 
     var date = timeArray[0].split("-");
     var yyyy = date[0];
     var mm = date[1]-1;
     var dd = date[2];
 
     var time = timeArray[1].split(":");
     var h = time[0];
     var m = time[1];
     var s = parseInt(time[2]); //get rid of that 00.0;*/

    // console.log(new Date(dateTime))


    return new Date(dateTime).toLocaleString();
}


export default HistoriaComponent