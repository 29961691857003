import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AuthenticationService from '../general/AuthenticationService.js'


class ClienteFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            nombreCliente: '',
            comentarios: '',
            propietario: '',
            sociedades: [],
            allSociedades: []
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)

    }

    useStyles = makeStyles((theme) => ({
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
    }));

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("WRITE_CLIENTES")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    console.log("retrieveAllElementsSociedadesresponse", response.data);
                    this.setState({ allSociedades: response.data })
                    console.log("allSociedades", this.state.allSociedades);
                }
            )

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("clientes", this.state.id)
            .then(response => {
                console.log("response", response)
                this.setState({
                    nombreCliente: response.data.nombreCliente,
                    comentarios: response.data.comentarios,
                    propietario: response.data.propietario,
                    sociedades: response.data.sociedades
                })
            })

    }

    validate(values) {
        let errors = {}

        if (!values.nombreCliente) {
            errors.nombreCliente = 'Debe introducir un nombre de Proyecto'
        }


        return errors

    }

    onSubmit(values) {

        console.log("onSubmit")

        let cliente = {
            id: this.state.id,
            nombreCliente: values.nombreCliente,
            comentarios: values.comentarios,
            propietario: values.propietario,
            sociedades: this.state.sociedades
        }

        console.log("this.state.sociedades", this.state.sociedades)

        if (Number(this.state.id) === -1) {
            ElementsDataService.createElement("clientes", cliente)
                .then(() => this.props.history.push('/clientes'))
        } else {
            ElementsDataService.updateElement("clientes", this.state.id, cliente)
                .then(() => this.props.history.push('/clientes'))
        }

        console.log("values", values);
    }

    getSelectedItem() {
        const item = this.state.allSociedades.find((opt) => {
            if (opt.value == this.props.selectedValue)
                return opt;
        })
        return item || {};
    }

    render() {

        let { nombreCliente, comentarios, propietario, allSociedades, sociedades } = this.state

        let variant = "standard";//'filled' | 'outlined' | 'standard'

        return (
            <div id="main">
                <div className="panel">
                    <h1>Nuevo Proyecto</h1>
                    <div className="container">
                        <Formik
                            initialValues={{ nombreCliente, comentarios, propietario }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={this.validate}
                            enableReinitialize={true}
                        >
                            {
                                (props) => (
                                    <Form autoComplete="off" >
                                        <ErrorMessage name="nombreCliente" component="div"
                                            className="alert alert-warning" />

                                        <div className="cols">
                                            <div className="col2">

                                                <TextField autoComplete="off" className="form-control"
                                                    value={nombreCliente}
                                                    variant={variant}
                                                    label="Nombre Proyecto"
                                                    placeholder="Nombre Proyecto"
                                                    name="nombreCliente"
                                                    onChange={event => {
                                                        const { value } = event.target;
                                                        this.setState({ nombreCliente: value.toUpperCase() });
                                                    }}
                                                />
                                            </div>
                                            <div className="col2">
                                                <FormControl className="form-control">
                                                    <InputLabel >Propietario</InputLabel>
                                                    <Select

                                                        value={propietario}
                                                        onChange={event => {
                                                            const { value } = event.target;
                                                            this.setState({ propietario: value });
                                                        }}

                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value={"SPH"}>SPH</option>
                                                        <option value={"ST"}>ST</option>

                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>


                                        <TextField autoComplete="off" className="form-control"

                                            value={comentarios}
                                            variant={variant}
                                            label="Comentarios"
                                            placeholder="Comentarios"
                                            name="comentarios"
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ comentarios: value.toUpperCase() });
                                            }}
                                        />

                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={allSociedades}
                                            getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                            value={sociedades}
                                            filterSelectedOptions
                                            onChange={(e, value) => {
                                                console.log(value);
                                                this.setState({ sociedades: value })
                                            }}
                                            getOptionSelected={(option, value) => option.rfc === value.rfc}
                                            //value={this.getSelectedItem()}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Sociedades"
                                                    placeholder=""
                                                    name="sociedades"
                                                />
                                            )}
                                        />

                                        <div className="buttons">

                                            <button className="btn btn-success" type="submit"><span className=" icon-checkmark"></span> Guardar</button>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClienteFormularioComponent