import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";


class DomiciliosReporteCostoSociedadesCliente extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            costoDomiciliosSociedadesClienteDtoArray: [],
            allSociedades: [],
            allClientes: [],
            sociedades: [],
            clientes: [],
            loading: true,

        }

    }



    componentDidMount() {
        console.log("DomiciliosReporteCostoSociedadesCliente componentDidMount")

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveAllElements("domicilios/gastos-sociedades-cliente/")
            .then(response => {

                console.log(response)

                this.setState({
                    costoDomiciliosSociedadesClienteDtoArray: response.data,
                    loading: false
                })
            })

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    console.log("retrieveAllSociedadesResponse", response.data);
                    this.setState({ allSociedades: response.data })
                })

        ElementsDataService.retrieveAllElements("clientes")
            .then(
                response => {
                    console.log("retrieveAllClientesResponse", response.data);
                    this.setState({ allClientes: response.data })
                })



    }



    render() {

        console.log("render DomiciliosReporteCostoSociedadesCliente")
        console.log("DomiciliosReporteCostoSociedadesCliente state", this.state)
        let {
            opinionesDtoArray,
            allSociedades,
            allClientes,
            sociedades,
            clientes,
            loading
        } = this.state


        let sociedadesIds = [];
        let clientesIds = [];

        sociedades.forEach(element => {
            sociedadesIds.push(element.id)
        })

        console.log("sociedadesIds", sociedadesIds)

        clientes.forEach(element => {
            clientesIds.push(element.id)
        })

        console.log("clientesIds", clientesIds)



        //Encabezados csv
        let csvStr = 'Cliente,Razon Social,RFC,fecha,opinion\n'



        let finalTableContent = []



        opinionesDtoArray.forEach(opinionDto => {
            console.log("opinionDto", opinionDto);

            if (clientesIds.length == 0 || clientesIds.includes(opinionDto.clienteId)) {
                if (sociedadesIds.length == 0 || sociedadesIds.includes(opinionDto.sociedadId)) {

                    let rowData = [];

                    rowData.push(<td>{opinionDto.nombreCliente}</td>);
                    rowData.push(<td>{opinionDto.razonSocial}</td>);
                    rowData.push(<td>{opinionDto.rfc}</td>);
                    rowData.push(<td>{opinionDto.fecha}</td>);
                    rowData.push(<td>{opinionDto.opinion == 'P' ? "POSITIVA" : "NEGATIVA"}</td>);
                    rowData.push(<td dangerouslySetInnerHTML={{ __html: opinionDto.respuestaDeOpinion }} />);
                    rowData.push(<td className="icoSize">
                        {opinionDto.documentFileUrl ?
                            <button type="button" className="btn btn-success" onClick={() => {
                                console.log("Descargando Archivo")

                                ElementsDataService.downloadS3File(`?fileName=${opinionDto.documentFileUrl}`,
                                    `${opinionDto.razonSocial}_${opinionDto.rfc}.pdf`)

                            }}><span className="icon-download"></span> </button>
                            : <span className="icon-block"></span>}
                    </td>);


                    csvStr += opinionDto.nombreCliente + ","
                    csvStr += opinionDto.razonSocial + ","
                    csvStr += opinionDto.rfc + ","
                    csvStr += opinionDto.fecha + ","
                    csvStr += opinionDto.opinion + ","
                    csvStr += opinionDto.respuestaDeOpinion + "\n"


                    finalTableContent.push(<tr>{rowData}</tr>)
                }
            }

        })




        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button type="button" className="btn btn-success" onClick={() => {
                                    console.log("Descargando Archivo")
                                    const element = document.createElement("a");
                                    const file = new Blob([csvStr], { type: 'text/plain' });
                                    element.href = URL.createObjectURL(file);
                                    var d = new Date();
                                    element.download = "Reporte" + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                            </div>
                            <h1>Reporte Opiniones Cumplimiento</h1>
                        </div>

                        <div className="container">

                            <div className="cols">
                                <div className="col2">
                                    <Autocomplete
                                        multiple
                                        id="clientes"
                                        options={allClientes}
                                        getOptionLabel={(option) => option.nombreCliente}

                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            console.log(value);
                                            this.setState({ clientes: value });
                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Clientes"
                                                placeholder=""
                                                name="clientes"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col2">
                                    <Autocomplete
                                        multiple
                                        id="sociedadesSelector"
                                        options={allSociedades}
                                        getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            console.log(value);
                                            this.setState({ sociedades: value })
                                        }}
                                        getOptionSelected={(option, value) => option.rfc === value.rfc}
                                        //value={this.getSelectedItem()}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Sociedades"
                                                placeholder=""
                                                name="sociedades"
                                            />
                                        )}
                                    />

                                </div>
                            </div>

                            <div className="scrolled">

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Cliente</th>
                                            <th>Razon Social</th>
                                            <th>RFC</th>
                                            <th>Fecha</th>
                                            <th>Opinion Cumplimiento</th>
                                            <th>Observaciones</th>
                                            <th>Archivo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {finalTableContent}
                                    </tbody>
                                </table >
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default DomiciliosReporteCostoSociedadesCliente