import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import parse from 'html-react-parser';
import AuthenticationService from '../general/AuthenticationService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';

class SociedadComponent extends Component {
    constructor(props) {
        console.log("CfdiComponent Constructor")
        super(props)

        this.state = {
            allClientes: [],
            statusLegal: '',
            statusContable: '',
            allClientes: [],
            clientes: [],
            busqueda: '',
            ...props
        }

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("READ_SOCIEDADES")) {
            alert("Usuario no autorizado")
            return;
        }

        ElementsDataService.retrieveAllElements(`sociedades`)
            .then(
                response => {
                    this.setState({ elements: this.sortByKeyArray('razonSocial', response.data) })
                    ElementsDataService.retrieveAllElements("clientes")
                        .then(
                            response => {
                                this.setState({ allClientes: response.data, loading: false })
                            })

                })
    }

    direccionASC = true;
    actualKey = "";

    compareBy(key, ascending) {
        let reverse = ascending ? 1 : -1;
        return function (a, b) {
            //console.log(a[key])
            // nulls sort after anything else
            if (!a[key]) {
                return 1;
            }
            else if (!b[key]) {
                return -1;
            }
            if (a[key].toString().trim() < b[key].toString().trim()) return -1 * reverse;
            if (a[key].toString().trim() > b[key].toString().trim()) return 1 * reverse;
            return 0;
        };
    }

    compareByCliente(ascending) {

        console.log("Compare by cliente")
        let reverse = ascending ? 1 : -1;
        return function (a, b) {
            //console.log(a[key])
            // nulls sort after anything else
            if (!a.clientes[0]) {
                return 1;
            }
            else if (!b.clientes[0]) {
                return -1;
            }
            if (a.clientes[0].nombreCliente < b.clientes[0].nombreCliente) return -1 * reverse;
            if (a.clientes[0].nombreCliente > b.clientes[0].nombreCliente) return 1 * reverse;
            return 0;
        };
    }


    sortBy(key) {

        if (!this.actualKey) {
            this.actualKey = key;
            this.direccionASC = 1;
        } else if (this.actualKey != key) {
            this.direccionASC = 1;
            this.actualKey = key;
        } else {
            this.direccionASC = !this.direccionASC;
        }

        let arrayCopy = [...this.state.elements];

        if (key == "clientes") {
            arrayCopy.sort(this.compareByCliente(this.direccionASC));
        } else {
            arrayCopy.sort(this.compareBy(key, this.direccionASC));
        }


        this.setState({ elements: arrayCopy });
    }

    sortByKeyArray(key, array) {

        if (!this.actualKey) {
            this.actualKey = key;
            this.direccionASC = 1;
        } else if (this.actualKey != key) {
            this.direccionASC = 1;
            this.actualKey = key;
        } else {
            this.direccionASC = !this.direccionASC;
        }

        let arrayCopy = [...array];

        arrayCopy.sort(this.compareBy(key, this.direccionASC));

        return arrayCopy;
    }

    render() {

        console.log("renderSociedadComponent", this.state)

        let counter = 0;

        let { loading,
            statusLegal,
            statusContable,
            busqueda,
            allClientes,
            clientes } = this.state

        let variant = "outlined";//'filled' | 'outlined' | 'standard'

        let filteredSociedadesArray = []


        let clientesIds = [];

        clientes.forEach(cliente => {
            clientesIds.push(cliente.id)
        })


        this.state.elements && this.state.elements.forEach(sociedad => {

            if (!statusContable || sociedad.statusContabilidad == statusContable) {
                if (!statusLegal || (sociedad.statusLegal && sociedad.statusLegal.toUpperCase() == statusLegal)) {
                    if (clientes.length === 0) {

                        filteredSociedadesArray.push(sociedad)
                    } else {

                        sociedad.clientes.forEach(clienteSociedad => {
                            if (clientesIds.includes(clienteSociedad.id)) {
                                filteredSociedadesArray.push(sociedad)
                            }
                        })
                    }

                }

            }

        })


        //Encabezados csv
        let csvStr = `N°;PROYECTO;RAZON SOCIAL;RFC;RESPONSABLE CONTABILIDAD;AUXILIAR CONTABLE;ESTATUS CONTABLE;ESTATUS LEGAL\n`

        let sociedadesTableRows = []


        filteredSociedadesArray && filteredSociedadesArray.forEach(
            element => {

                sociedadesTableRows.push(
                    <tr key={element.id}>
                        <td>{++counter}</td>
                        <td>{element.clientes && element.clientes.map(cliente => <li>{cliente.nombreCliente}</li>)}</td>
                        <td><Link to={`/sociedades/reportes/${element.id}`} >{
                            busqueda.length > 2 ?
                                parse(element.razonSocial.replace(busqueda, "<span style=\"color:blue;\">"
                                    + busqueda + "</span>")) :
                                element.razonSocial
                        }</Link></td>
                        <td><Link to={`/sociedades/reportesContables/${element.rfc}`} >{
                            busqueda.length > 2 ?
                                parse(element.rfc.replace(busqueda, "<span style=\"color:blue;\">"
                                    + busqueda + "</span>")) :
                                element.rfc
                        }</Link></td>
                        <td>{element.responsableContable}</td>
                        <td>{element.auxiliarContable}</td>
                        <td>{element.statusContabilidad}</td>
                        <td style={{ textTransform: 'uppercase' }}>{element.statusLegal}</td>
                        <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                        {AuthenticationService.haveAuthorization("DELETE_SOCIEDADES") &&
                            <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>}
                    </tr>
                )

                csvStr += counter + ";"

                if (element.clientes) {
                    element.clientes.forEach(cliente => { csvStr += cliente.nombreCliente }
                    )

                }
                csvStr += ";"
                csvStr += element.razonSocial + ";"
                csvStr += element.rfc + ";"
                csvStr += element.responsableContable + ";"
                csvStr += element.auxiliarContable + ";"
                csvStr += element.statusContabilidad + ";";
                csvStr += element.statusLegal && element.statusLegal.toUpperCase()
                csvStr += "\n"

            })



        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="toolSearch">
                            <span className="icon-search"></span>

                            <div className="container">
                                <TextField className="form-control"
                                    value={this.state.busqueda}
                                    label="Busqueda"
                                    placeholder="Buscar"
                                    name="busqueda"
                                    onChange={event => {
                                        const { value } = event.target;

                                        this.setState({ busqueda: value.toUpperCase() });

                                        if (value.toUpperCase().length > 2) {
                                            ElementsDataService.retrieveSearch(value.toUpperCase(), "sociedades")
                                                .then(
                                                    response => {
                                                        console.log("retrieveBusqueda", response.data);
                                                        this.setState({ elements: response.data })
                                                    }
                                                )
                                        } else if (value.length === 0) {
                                            ElementsDataService.retrieveAllElements("sociedades")
                                                .then(
                                                    response => {
                                                        //console.log("retrieveAllClientesResponse",response.data);
                                                        this.setState({ elements: this.sortByKeyArray('razonSocial', response.data) })
                                                    })
                                        }

                                    }}
                                />
                            </div>
                        </div>
                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button className="btn btn-agregar" onClick={this.props.addElementClicked}><span className="icon-add-solid"> </span><span className="icon-factory"></span></button>
                            </div>
                            <div className="tool-btn-agregar">
                                <button type="button" className="btn btn-success" onClick={() => {
                                    console.log("Descargando Archivo")
                                    const element = document.createElement("a");
                                    const file = new Blob([csvStr], { type: 'text/plain' });
                                    element.href = URL.createObjectURL(file);
                                    var d = new Date();
                                    element.download = "Reporte Sociedades " + d.toLocaleDateString() + "T" + d.toLocaleTimeString() + ".csv";
                                    document.body.appendChild(element); // Required for this to work in FireFox
                                    element.click();
                                }}><span className="icon-download"></span>  <span className="icon-news-paper"></span>   </button>
                            </div>
                            <h1>Sociedades</h1>
                        </div>
                        {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                        <div className="container">

                            <div className="cols">

                                <div className="col3">
                                    <Autocomplete
                                        multiple
                                        id="clientes"
                                        options={allClientes}
                                        getOptionLabel={(option) => option.nombreCliente}

                                        value={clientes}
                                        filterSelectedOptions
                                        onChange={(e, value) => {
                                            this.setState({ clientes: value })
                                            console.log("Cliente: ", value);

                                        }}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={variant}
                                                label="Proyectos"
                                                placeholder=""
                                                name="clientes"
                                            />
                                        )}
                                    /> </div>



                                <div className="col3 ">

                                    <FormControl variant={variant} className="form-control">
                                        <InputLabel >Estatus Contable</InputLabel>
                                        <Select

                                            value={statusContable}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ statusContable: value });
                                            }}
                                            label="Estatus Contable"

                                        >
                                            <option aria-label="None" value="" />
                                            <option value={""}>-----</option>
                                            <option value={"ACTIVA"}>ACTIVA</option>
                                            <option value={"SIN UTILIZAR"}>SIN UTILIZAR</option>
                                            <option value={"INACTIVA"}>INACTIVA</option>
                                            <option value={"PROCESO LIQUIDACION"}>PROCESO LIQUIDACION</option>
                                            <option value={"LIQUIDADA"}>LIQUIDADA</option>
                                            <option value={"PROCESO SUSPENSION"}>PROCESO SUSPENSION</option>
                                            <option value={"SUSPENDIDA"}>SUSPENDIDA</option>
                                            <option value={"VENDIDA"}>VENDIDA</option>
                                            <option value={"DEVUELTA"}>DEVUELTA</option>
                                            <option value={"NO REVISION"}>NO REVISION</option>
                                        </Select>
                                    </FormControl>

                                </div>

                                <div className="col3 ">

                                    <FormControl variant={variant} className="form-control">
                                        <InputLabel >Estatus Legal</InputLabel>
                                        <Select

                                            value={statusLegal}
                                            onChange={event => {
                                                const { value } = event.target;
                                                this.setState({ statusLegal: value });
                                            }}
                                            label="Estatus Legal"

                                        >
                                            <option aria-label="None" value="" />
                                            <option value={""}>-----</option>
                                            <option value={"ACTIVA"}>ACTIVA</option>
                                            <option value={"INACTIVA"}>INACTIVA</option>
                                            <option value={"SUSP>L..."}>SUSP{'>'}L...</option>
                                            <option value={"LIQX"}>LIQX</option>
                                            <option value={"P.E."}>P.E.</option>
                                            <option value={"REACT"}>REACT</option>
                                            <option value={"N/A"}>N/A</option>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div id="tablesContainer">
                                <div className="tableContainer">


                                    <table className="table" style={{ boxSizing: "border-box", width: "100%", fontSize: "0.8em" }}>
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th onClick={() => { this.sortBy('clientes') }}>Proyecto</th>
                                                <th onClick={() => { this.sortBy('razonSocial') }}>Razon Social</th>
                                                <th onClick={() => { this.sortBy('rfc') }}>RFC</th>
                                                <th onClick={() => { this.sortBy('responsableContable') }}>Resposable Contabilidad</th>
                                                <th onClick={() => { this.sortBy('auxiliarContable') }}>Auxiliar Contable</th>
                                                <th>Estatus Contable</th>
                                                <th>Estatus Legal</th>
                                                <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                                {AuthenticationService.haveAuthorization("DELETE_SOCIEDADES") &&
                                                    <th className="icoSize"><span className="icon-trash"></span></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sociedadesTableRows
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default SociedadComponent