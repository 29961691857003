import React, { Component } from 'react'

import AuthenticationService from './AuthenticationService.js'


class LogoutComponent extends Component {

    componentDidMount(){
        this.props.setUserLoggedIn(AuthenticationService.getLoggedInUserName())
    }

    render() {
        return (
            <div id="main">
                <div className="panel small">

                    <h1>Se ha desconectado correctamente</h1>
                    <div className="container">
                        <h2>Gracias por usar nuestra Aplicación</h2> 
                        <div className="buttons">

                            <button className="btn btn-success" onClick={() =>  this.props.history.push(`/login`)}>   Ingresar <span className="icon-arrow-right"></span></button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default LogoutComponent