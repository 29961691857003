import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AuthenticationService from '../general/AuthenticationService.js';


class DeclaracionProvisionalFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            nombreCliente: '',
            sociedades: [],
            allSociedades: [],
            content: []
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)

    }

    useStyles = makeStyles((theme) => ({
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
    }));

    componentDidMount() {
        console.log("DeclaracionProvisionalFormularioComponent componentDidMount")

        if (!AuthenticationService.haveAuthorization("WRITE_CFDIS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        const { match: { params } } = this.props;

        console.log("--params.id", params.id)


        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("cfdis/declaracion", this.state.id)
            .then(response => {
                console.log("response", response)

                var fieldValuesArray = response.data.content.split("|");

                var fieldsValues = [];

                fieldValuesArray.forEach(element => {
                    var fieldValueArray = element.split("~");

                    var fieldValue = {
                        field: fieldValueArray[0],
                        value: fieldValueArray[1]
                    }

                    fieldsValues.push(fieldValue);

                });


                this.setState({ content: fieldsValues })
            })

    }

    validate(values) {
        let errors = {}
        /*
        if (!values.description) {
            errors.description = 'Enter a Description'
        } else if (values.description.length < 5) {
            errors.description = 'Enter atleast 5 Characters in Description'
        }

        if (!moment(values.targetDate).isValid()) {
            errors.targetDate = 'Enter a valid Target Date'
        }*/

        return errors

    }

    onSubmit(values) {

        console.log("onSubmit")

        let cliente = {
            id: this.state.id,
            nombreCliente: values.nombreCliente,
            sociedades: this.state.sociedades
        }

        console.log("this.state.sociedades", this.state.sociedades)

        if (Number(this.state.id) === -1) {
            ElementsDataService.createElement("clientes", cliente)
                .then(() => this.props.history.push('/clientes'))
        } else {
            ElementsDataService.updateElement("clientes", this.state.id, cliente)
                .then(() => this.props.history.push('/clientes'))
        }

        console.log("values", values);
    }

    getSelectedItem() {
        const item = this.state.allSociedades.find((opt) => {
            if (opt.value == this.props.selectedValue)
                return opt;
        })
        return item || {};
    }

    render() {

        console.log("render DeclaracionProvisionalFormularioComponent")
        console.log("DeclaracionProvisionalFormularioComponent state", this.state)
        let { nombreCliente, allSociedades, sociedades, content } = this.state

        let variant = "standard";//'filled' | 'outlined' | 'standard'

        let formulario = [];

        content.forEach(element => {

            if (element.field === "EncabezadoH1") {
                formulario.push(<h1>{element.value}</h1>)
            } else if (element.field === "EncabezadoH2") {
                formulario.push(<h2>{element.value}</h2>)
            } else {
                formulario.push(<TextField className="form-control"
                    value={element.value}
                    variant={variant}
                    label={element.field}
                    multiline
                    name="comentario"
                    onChange={event => {
                        const { value } = event.target;
                        element.value = value;
                        this.setState({ element: element });
                    }}
                />)
            }

        });



        return (
            <div id="main">
                <div className="panel">

                    <h1>Verificar </h1>
                    <div className="container">

                        <form>
                            {formulario}

                            <button className="btn btn-success" type="submit"><span className="icon-checkmark"></span> Guardar</button>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default DeclaracionProvisionalFormularioComponent