import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import { Link } from 'react-router-dom'
import LoadingMask from "react-loadingmask";
import AuthenticationService from '../general/AuthenticationService.js'


class CfdiComponent extends Component {
    constructor(props) {
        console.log("CfdiComponent Constructor")
        super(props)

        this.state = {
            loading: false,
            elements: [],
            allSociedades: [],
            allClientes: [],
            ...props
        }

        console.log("this.state", this.state)

        this.goDeclaraciones = this.goDeclaraciones.bind(this)
        this.goRefreshCache = this.goRefreshCache.bind(this)
        this.goRefreshStatus = this.goRefreshStatus.bind(this)
        this.goUrl = this.goUrl.bind(this)
        this.expand = this.expand.bind(this)

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("READ_CFDIS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        let sociedadesArray = []

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {
                    console.log("retrieveAllSociedadesResponse", response.data);
                    console.log("Sociedades: " + response.data)

                    response.data.forEach((element) => {
                        sociedadesArray.push({
                            id: element.id,
                            razonSocial: element.razonSocial,
                            rfc: element.rfc,
                            clientes: element.clientes
                        })
                    })

                    this.setState({ allSociedades: sociedadesArray })

                    ElementsDataService.retrieveAllElements("clientes")
                        .then(
                            respuesta => {
                                console.log("retrieveClientesrespuesta", respuesta.data);
                                this.setState({ allClientes: respuesta.data })

                                if (AuthenticationService.haveAuthorization("READ_CONTABILIDAD")) {

                                    ElementsDataService.retrieveAllElements("alertas-contables")
                                        .then(
                                            respuesta => {
                                                console.log("retrieveAlertasrespuesta", respuesta.data);
                                                this.setState({ elements: respuesta.data, loading: false })


                                            }
                                        )
                                }


                            }
                        )


                }
            )



    }

    goDeclaraciones() {
        this.props.history.push('/declaraciones-provisionales/')
    }

    goRefreshCache() {
        this.props.history.push('/refresh-cache/')
    }

    goRefreshStatus() {

        this.setState({ loading: true });
        ElementsDataService.retrieveAllElements("generar-status-contable")
            .then(
                respuesta => {
                    console.log("retrieveAllElements generar-status-contable", respuesta);

                    alert(respuesta.data.message)
                    this.setState({ loading: false });

                }
            )
    }

    goUrl(url) {
        this.props.history.push(url)
    }

    expand(diccionario, mensaje) {

        let newString = ""

        let map = new Map(Object.entries(diccionario));

        //        console.log(map)



        for (let i = 0; i < mensaje.length; i++) {
            let c = mensaje[i];

            if (c === '^') {
                // console.log("Caret")
                c = mensaje[++i]
                // console.log(c)
                // console.log("Diccionario index ", map.get(c))
                newString = newString.concat(map.get(c));
            } else {
                // console.log(c);
                newString = newString.concat(c);
            }
        }

        //console.log(newString)

        return newString;


    }



    render() {

        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {


            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }


            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };

        console.log("render CFDI component", this.state)

        let { loading, elements, allClientes, allSociedades } = this.state;

        let authorizedLoadUsers = ["FC01", "FC04", "FC09", "FC10", "FC12", "FC13", "FCAux"]


        const isUserLoggedIn = AuthenticationService.isUserLoggedIn();

        if (isUserLoggedIn) {

            let mensajesAlertas = []

            let alertasTables = []

            let variant = "outlined";//'filled' | 'outlined' | 'standard'



            elements && elements.forEach(
                listadoAlertas => {
                    //console.log(listadoAlertas.encabezado)
                    //console.log(listadoAlertas.objectType)
                    //console.log("listadoAlertas.mensajes", listadoAlertas.mensajes)

                    if (listadoAlertas.mensajes) {
                        Object.values(listadoAlertas.mensajes).forEach(mensaje => {
                            mensajesAlertas.push(mensaje)
                        })
                    }


                    //console.log("mensajesAlertas", mensajesAlertas)
                    //console.log(listadoAlertas.alertas)

                    let tipoObjeto = ''
                    let nameHeader = ''
                    let url = ''

                    if (listadoAlertas.objectType === 'sociedad') {
                        tipoObjeto = "Sociedad"
                        nameHeader = "Razon Social"
                        url = "sociedades"
                    }

                    let header = ""

                    header += `"${listadoAlertas.encabezado}",,,\n`
                    header += `#,"${nameHeader}",Alerta,`
                    header += listadoAlertas.objectType === 'personaFisica' ? "Sociedades,Roles,Cliente,Propietario" : "Cliente,Propietario"
                    header += "\n"


                    let alertasRows = []
                    let counter = 0

                    listadoAlertas.alertas && listadoAlertas.alertas.forEach(alerta => {

                        let mensajeAlerta = this.expand(listadoAlertas.mensajes, alerta.mensaje)



                        alertasRows.push(
                            <tr>
                                <td>{++counter}</td>
                                <td><Link to={`/${url}/reportes/${alerta.objectId}`} >{alerta.name}</Link></td>
                                <td><p>{mensajeAlerta}</p><p>{formatMoney(alerta.cantidad)}</p>  </td>

                                {listadoAlertas.objectType === 'sociedad' && <td>
                                    {
                                        allSociedades && allSociedades.find(x => x.id === alerta.objectId) && allSociedades.find(x => x.id === alerta.objectId).clientes.map(function (clienteMini, index) {

                                            let cliente = allClientes.find(x => x.id === clienteMini.id)

                                            return <div>{cliente.nombreCliente}-{cliente.propietario}</div>
                                        })

                                    }
                                </td>
                                }
                            </tr>

                        )




                    })



                    alertasRows.length && alertasTables.push(
                        <div class='tableContainer'>

                            <h1>{listadoAlertas.encabezado}</h1>

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>{nameHeader}</th>
                                        <th>Alerta</th>
                                        {listadoAlertas.objectType === 'sociedad' && <th>Cliente-Propietario</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        alertasRows
                                    }
                                </tbody>
                            </table>
                        </div>)


                })





            return (
                <div id="main">
                    <div className="panel">

                        <h1>Administrar CFDIs</h1>
                        {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                        <div className="container">

                            {AuthenticationService.haveAuthorization("WRITE_CFDIS") && <div className="bigButtons">

                                <button className="btn btn-success" onClick={this.props.addElementClicked}><span className="icon-add-solid"></span> Agregar Metadata</button>
                                <button className="btn btn-success" onClick={this.goDeclaraciones}><span className="icon-add-solid"></span> Agregar Declaracion Provisional</button>
                                <button className="btn btn-success" onClick={() => this.goUrl('/cargar-opiniones-cumplimiento/')}> <span className="icon-add-solid"></span> Agregar Opinion Cumplimiento</button>
                            </div>}
                            <hr />
                            {AuthenticationService.haveAuthorization("READ_CONTABILIDAD") && <div className="bigButtons">

                                {<button className="btn btn-success" onClick={() => this.goUrl('/opiniones-cumplimiento/')}> <span className="icon-checkmark"></span> Opiniones Cumplimiento</button>}
                                {<button className="btn btn-success" onClick={() => this.goUrl('/revision-plaza-mensual/')}> <span className="icon-zoom-in"></span> Revisión Proyectos Mensual</button>}
                                {<button className="btn btn-success" onClick={() => this.goUrl('/reporte-sociedades-revisadas/')}> <span className="icon-document"></span>Reportes Sociedades Revisadas</button>}
                                {<button className="btn btn-success" onClick={() => this.goUrl('/relacion-declaraciones/')}> <span className="icon-document"></span>Relación Declaraciones Presentadas</button>}
                                {<button className="btn btn-success" onClick={() => this.goUrl('/get-cfdis/')}> <span className="icon-document"></span>Obtener CFDIs</button>}
                            </div>}

                        </div>

                        <div id="tablesContainer">

                            {
                                alertasTables
                            }
                        </div>

                    </div>
                </div>
            )
        } else {
            return ""
        }
    }
}

export default CfdiComponent