import React, { Component } from 'react'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { FilledInput } from '@material-ui/core';

function saveFile(formData){
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    return ElementsDataService.createElement("cfdis/opinion-cumplimiento",formData, config)
}

class OpinionCumplimientoFileUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            rfc: '',
            fecha: moment(new Date()).format('YYYY-MM-DD'),
            tipo:'',
            metadataFiles:[],
            loading:false,
            mensaje:[],
            buttonEnabled: true,
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)

    }

    componentDidMount() {
        console.log("OpinionCumplimientoFileUpload componentDidMount"  )


    }

    validate(values) {
        let errors = {}
        /*
        if (!values.description) {
            errors.description = 'Enter a Description'
        } else if (values.description.length < 5) {
            errors.description = 'Enter atleast 5 Characters in Description'
        }

        if (!moment(values.targetDate).isValid()) {
            errors.targetDate = 'Enter a valid Target Date'
        }*/

        return errors

    }


    async onSubmit(values) {

        console.log("onSubmit")

        this.setState({buttonEnabled: false})
    
     
        var ins = document.getElementById('fileToUpload').files.length;

      
        //this.setState({loading:true})

        console.log("ins",ins)

        let mensajeStr = [];

        let failures = '';

        let successCounter = 0;


        for (var x = 0; x < ins; x++) {
            const formData = new FormData();

            let file = document.getElementById('fileToUpload').files[x];

            formData.append("opinionCumplimientoFile", file);

           let response = await saveFile(formData);

           console.log("response",response);

          
           if(response && response.data.message == "Created"){

               successCounter++;

               console.log("Archivo " + (x+1) + " de " + ins + " " + file.name +  " procesado con éxito");

               mensajeStr.push(<p key= {x+1}>{"Archivo " + (x+1) + " de " + ins + " " + file.name +  " procesado con éxito"}</p>)

               this.setState({mensaje: mensajeStr})
               

           }else{

                console.log("Ha ocurrido un error con el archivo " + file.name );
                mensajeStr.push(<p key= {x+1}>{"Ha ocurrido un error con el archivo " + (x+1) + " de " + ins + " " + file.name }</p>)

                failures += file.name + ", ";

                this.setState({mensaje: mensajeStr})
           }
           
        }

        console.log("successCounter",successCounter)

        if(successCounter < ins){
            alert("A Ocurrido un error con los siguientes Archivos: " + failures)

        }else{
            alert("Todos los archivos se han cargado con exito");

        }

        this.setState({buttonEnabled: true})

      
    }

    render() {

        console.log("render OpinionCumplimientoFileUpload")
        console.log("OpinionCumplimientoFileUpload state", this.state)
        let { rfc, fecha, cantidad, tipo,loading,mensaje,buttonEnabled } = this.state

        return (
            <LoadingMask loading={loading} text={"loading..."}>
            <div id="main">
                <div className="panel">
                    <h1>Cargar Opiniones Cumplimiento</h1>
                    <div className="container">
                        <Formik
                            initialValues={{ rfc, fecha, cantidad, tipo }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={this.validate}
                            enableReinitialize={true}
                        >
                            {
                                (props) => (
                                    <Form>
                                        <ErrorMessage name="rfc" component="div"
                                            className="alert alert-warning" />
                                        <ErrorMessage name="fecha" component="div"
                                            className="alert alert-warning" />

                                   

                                        <div className="form-group">
                                            <label for="file"> <span className="icon-attachment"></span> Archivo Metadata</label>
                                            <input id="fileToUpload" name="fileToUpload" type="file" multiple onChange={(event) => {
                                                this.setState({"metadataFiles": event.currentTarget.files});
                                            }} className="form-control" />
                                        </div>

                                        <button className="btn btn-success" type="submit" disabled={!buttonEnabled} ><span className="icon-checkmark"></span> Guardar</button>
                                        <div id="mensaje">{mensaje}</div>

                                    </Form>

                                )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
            </LoadingMask>
        )
    }
}

export default OpinionCumplimientoFileUpload