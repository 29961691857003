import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CurrencyInput from 'react-currency-input-field';



class GastosDomicilioFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            calle: '',
            gastoId:'',
            tipoGasto: '',
            colonia: '',
            cp:'',
            estado:'',
            municipio: '',
            numeroExt:'',
            numeroInterior: '',
            fecha: '',
            cantidad: '',
            notas: '',
            reciboFile: '',
            reciboFileUrl: '',
            loading:false,

        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)

    }

    componentDidMount() {
        console.log("GastosDomicilioFormularioComponent componentDidMount"  )

        const { match: { params } } = this.props;

        //console.log("--params",params)

        console.log("--params.id",params.id)

        const query = new URLSearchParams(this.props.location.search);
        
        const gastoId = query.get('gasto')
              

        console.log("--gasto id: ", gastoId)

       

        ElementsDataService.retrieveElement("domicilios",this.state.id)
            .then(response => this.setState({
                calle: response.data.calle,
                colonia: response.data.colonia,
                estado:response.data.estado,
                municipio: response.data.municipio,
                numeroExt:response.data.numeroExt,
                numeroInterior: response.data.numeroInterior,
                gastoId:gastoId
            }))

        if (Number(gastoId) != -1) {
            ElementsDataService.retrieveElement("domicilios/gasto",gastoId)
            .then(response => this.setState({
                tipoGasto: response.data.tipoGasto,
                cantidad: response.data.cantidad,
                fecha:response.data.fecha,
                notas: response.data.notas,
                reciboFileUrl:response.data.reciboFileUrl,
            }))
        }

    }

    validate(values) {
        let errors = {}
        return errors

    }

    onSubmit(values) {

        console.log("onSubmit")

        this.setState({loading:true})

        let messageStr = ""

        if (!this.state.tipoGasto) {
            messageStr += "No se ha especificado el tipo de Gasto.\n"
        }

        if (!this.state.cantidad) {
            messageStr += "No se ha especificado una cantidad.\n"
        }

        if (!this.state.fecha) {
            messageStr += "No se ha establecido la fecha.\n"
        } else {//adding year validation
            console.log("Fecha: ", this.state.fecha)
            let date = new Date(this.state.fecha);
            console.log("date.getFullYear", date.getFullYear());
            if (date.getFullYear() < 1900 || date.getFullYear() > 2100
                || isNaN(date.getFullYear())) {
                messageStr += "El año " + date.getFullYear() + " no es un valor válido.\n";
            }
        }

        if(messageStr){
            alert(messageStr)
            this.setState({loading:false})
            return
        }

        const formData = new FormData();
       
        formData.append('gastoId', this.state.gastoId);
        formData.append('fecha', this.state.fecha);
        formData.append('domicilioId', this.state.id);
        formData.append('tipoGasto', this.state.tipoGasto);
        formData.append('cantidad', this.state.cantidad);
        formData.append('notas', this.state.notas);
        formData.append('reciboFile', this.state.reciboFile);


        ElementsDataService.createElement("domicilios/gastos",formData)
            .then(response => {

                console.log("response",response)

                if(response.status == 200){
                    this.props.history.push(`/domicilios/reportes/${this.state.id}`)
                }


                //
                this.setState({"loading":false})
            }
                )

        console.log(values);
    }

    render() {

        console.log("render GastosDomicilioFormularioComponent")
        console.log("GastosDomicilioFormularioComponent state", this.state)
        let {id,
            calle,            
            colonia,
            estado,
            municipio,
            numeroExt,
            numeroInterior,
            tipoGasto,
            fecha,
            cantidad,
            notas,
            reciboFile,
            reciboFileUrl,
            loading
        } = this.state

        console.log(cantidad)

        return (
            <div id="main">
                <div className="panel">
                <LoadingMask loading={loading} text={"loading..."}>
                    <div className="titles">
                        <h1>Agregar Gasto </h1>
                    </div>
                    <br />
                    <div className="container">

                    <h2>{calle + " " + numeroExt + (numeroInterior?" - " + numeroInterior:"")}</h2>
                        <h2>{colonia + ", " + municipio + ", " + estado}</h2>
                        <h2>{ municipio + ", " + estado}</h2>
                        <br />

                        <Formik
                            initialValues={{  
                                id,
                                tipoGasto,
                                notas,
                                cantidad
                            }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={this.validate}
                            enableReinitialize={true}
                        >
                            {
                                (props) => (
                                    <Form  autoComplete="off" >

                                    <div className="cols">
                                        <div className="col1">

                                        <CurrencyInput
                                            id="cantidad"
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            prefix={'$'}
                                            value={cantidad}
                                            decimalsLimit={2}
                                            onValueChange={(value) => this.setState({ cantidad: value })}
                                            />  

                                        </div>
                                        </div>
                                        

                                    <div className="cols">

                                        <TextField className="form-control"
                                                value={fecha}
                                                id="date"
                                                label="Fecha"
                                                type="date"
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.setState({ fecha: value });
                                                }}
                                            />

                                             <FormControl   className="form-control">
                                        <InputLabel >Tipo de Gasto</InputLabel>
                                            <Select                                            
                                            
                                            value={tipoGasto}
                                            onChange={event => {
                                              const { value } = event.target;
                                              this.setState({ tipoGasto: value });
                                            }}
                                            
                                            >
                                               <option aria-label="None" value="" />
                                              <option value={"ARRENDAMIENTO"}>ARRENDAMIENTO</option>
                                              <option value={"ELECTRICIDAD"}>ELECTRICIDAD</option>
                                              <option value={"AGUA"}>AGUA</option>
                                              <option value={"TELEFONIA E INTERNET"}>TELEFONIA E INTERNET</option>
                                              <option value={"MANTENIMIENTO"}>MANTENIMIENTO</option>
                                              <option value={"OTROS"}>OTROS</option>
                                            </Select>
                                          </FormControl>
                                          
                                    </div>

                                   
                                        <div className="cols">
                                        <div className="col1">
                                    <fieldset className="form-group">
                                                <label>Notas</label>
                                                <Field onChange={e => {
                                                    let value = e.target.value || "";
                                                    value = value.toUpperCase();
                                                    this.setState({"notas": value})
                                                }} 
                                                className="form-control" type="text" name="notas" />
                                            </fieldset>
                                        </div>
                                                          
                                    </div>
                                   
                                    <div className="cols">
                                    <div className="col1">

                                            <div className="form-group">
                                                <label for="file"><span className="icon-attachment"></span> Cargar Recibo: {reciboFileUrl?
                                                reciboFileUrl:""} </label>
                                                <input id="file" name="file" type="file" onChange={(event) => {
                                                    this.setState({"reciboFile": event.currentTarget.files[0]});
                                                    //console.log("reciboFileUrl",contratoArrendamientoFile)
                                                }} className="form-control" />
                                            </div>
                                            </div>
                                       

                                    </div>
                                    <div className="buttons">

                                        <button className="btn btn-success" type="submit"><span className="icon-checkmark"></span> Guardar</button>
                                    </div>
                                    </Form>
                                )
                            }
                        </Formik>

                    </div>
                </LoadingMask>
                </div>
            </div>
        )
    }
}

export default GastosDomicilioFormularioComponent