import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js';
import AuthenticationService from '../general/AuthenticationService.js';

class RelacionDeclaracionesComponent extends Component {
    constructor(props) {
        console.log("RelacionDeclaracionesComponent Constructor")
        super(props)


        this.state = {
            description: '',
            elements: [],
            count: '',
            pagenumber: '',
            allSociedades: [],
            entidadesArray: [],
            loading: true,
            busqueda: "",
            ...props
        }



    }


    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_CONTABILIDAD")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }


        let sociedadesArray = [];

        ElementsDataService.retrieveAllElements("sociedades")
            .then(
                response => {

                    // console.log("retrieveAllSociedadesResponse", response.data);
                    //console.log("Sociedades: " + response.data)

                    response.data.map((element) => {
                        sociedadesArray.push({
                            id: element.id,
                            razonSocial: element.razonSocial,
                            rfc: element.rfc
                        })
                    })

                    this.setState({ allSociedades: sociedadesArray, loading: false })


                }
            )

    }






    render() {

        console.log("render RelacionDeclaracionesComponent", this.state)

        let counter = 0;
        let { loading, allSociedades, elements } = this.state

        function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {


            if (isNaN(amount)) {//Caso en que no es un numero!!!
                return "-";
            }


            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                //console.log(e)
            }
        };


        let finalTableContent = []
        let declarionIndex = 1;

        elements.forEach(declaracion => {

            console.log("Declaracion RFC ", declaracion && declaracion.rfc);
            console.log("declaracion: " + declaracion)


            if (declaracion) {

                let rowData = [];


                rowData.push(<td>{declarionIndex++}</td>);
                rowData.push(<td>{declaracion ? declaracion.fechaPresentacionPPM : "-"}</td>);
                rowData.push(<td><b>{declaracion && declaracion.periodo + "/" + declaracion.ejercicio}</b><br />
                    {declaracion ? declaracion.fechaUltimaPresentacionPeriodoEjercicio : "-"}
                </td>);

                let fechasPresentacionesComplementarias = [];

                declaracion && declaracion.declaracionesProvisonalesDelPeriodoEjercicio.forEach(declaracionProvisional => {
                    // console.log(declaracionProvisional)
                    fechasPresentacionesComplementarias.push(<p>{declaracionProvisional.tipoDeclaracion}</p>)
                    fechasPresentacionesComplementarias.push(<p>{declaracionProvisional.fechaHora}</p>)
                    fechasPresentacionesComplementarias.push(<p>{declaracionProvisional.documentFileUrl
                        ? <button type="button" className="btn btn-success" onClick={() => {
                            console.log("Descargando Archivo")

                            ElementsDataService.downloadS3File(`?fileName=${declaracionProvisional.documentFileUrl}`,
                                `${declaracion.rfc}_DeclaracionProvisional_${declaracionProvisional.tipoDeclaracion +
                                "_" + declaracion.periodo + "_" + declaracion.ejercicio}.pdf`)

                        }}><span className="icon-download"></span> Descargar</button>
                        : "No hay un archivo cargado todavía"}</p>)



                })

                rowData.push(<td>{fechasPresentacionesComplementarias}</td>);

                //INFORMACION GENERAL CONTABLE

                rowData.push(<td>{declaracion ? formatMoney(declaracion.ingresosNominalesMesDeclarado) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.ingresosNominalesFacturados) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.ingresosNominalesaDisminuir) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.cfdisIngresosMetadadata) : "-"}</td>);

                rowData.push(<td>{declaracion ? formatMoney(declaracion.totalIngresosNominales) : "-"}</td>);
                //Data Metadata
                rowData.push(<td>{declaracion ? formatMoney(declaracion.cfdisIngresosAcumuladosMetadadata) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.cfdisNominaMetadadata) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.cfdisNominaAcumuladaMetadadata) : "-"}</td>);


                //R1 ISR
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r1Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r1Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r1CantidadAPagar) : "-"}</td>);

                //R12 Sueldos y Salarios
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r12Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r12Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r12Subsidio) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r12CantidadAPagar) : "-"}</td>);


                //R13 ISR IAS (ISR RETENCIONES POR ASIMILADOS A SALARIOS)
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r13Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r13Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r13Subsidio) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r13CantidadAPagar) : "-"}</td>);


                //R14 ISR RETENCIONES POR SERVICIOS PROFESIONALES
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r14Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r14Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r14CantidadAPagar) : "-"}</td>);


                //R15 ISR POR PAGOS POR CUENTA DE TERCEROS O RETENCIONES POR ARRENDAMIENTO DE INMUEBLES
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r15Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r15Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r15CantidadAPagar) : "-"}</td>);


                //R21 IMPUESTO AL VALOR AGREGADO
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21Afavor) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21CantidadAPagar) : "-"}</td>);


                //R21 IMPUESTO AL VALOR AGREGADO (RFN)
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21AcargoRFN) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21AfavorRFN) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21RecargosRFN) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r21CantidadAPagarRFN) : "-"}</td>);

                //R24 IVA RETENCIONES
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r24Acargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r24Recargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.r24CantidadAPagar) : "-"}</td>);

                //IVA SIMPLIFICADO DE CONFIANZA
                rowData.push(<td>{declaracion ? formatMoney(declaracion.ivaSimplificadoConfianzaAcargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.ivaSimplificadoConfianzaAfavor) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.ivaSimplificadoConfianzaRecargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.ivaSimplificadoConfianzaCantidadAPagar) : "-"}</td>);

                //IVA SIMPLIFICADO DE CONFIANZA
                rowData.push(<td>{declaracion ? formatMoney(declaracion.isrSimplificadoConfianzaAcargo) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.isrSimplificadoConfianzaRecargos) : "-"}</td>);
                rowData.push(<td>{declaracion ? formatMoney(declaracion.isrSimplificadoConfianzaCantidadAPagar) : "-"}</td>);



                finalTableContent.push(<tr >{rowData}</tr>)
            }



        })



        return (
            <LoadingMask loading={loading} text={"loading..."}>

                <div id="main">

                    <div className="panel">

                        <Autocomplete
                            id="sociedadesSelector"
                            options={allSociedades}
                            getOptionLabel={(option) => option.razonSocial + " " + option.rfc}

                            filterSelectedOptions
                            onChange={(e, value) => {
                                console.log(value);

                                this.setState({ loading: true, elements: [] })
                                if (value) {
                                    ElementsDataService.retrieveElement("cfdis/declaraciones", value.rfc)
                                        .then(
                                            response => {
                                                console.log(response);
                                                this.setState({ elements: response.data, loading: false })

                                            }
                                        )
                                } else {
                                    this.setState({ elements: [], loading: false })
                                }
                            }}
                            getOptionSelected={(option, value) => option.rfc === value.rfc}
                            //value={this.getSelectedItem()}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Sociedades"
                                    placeholder=""
                                    name="sociedades"
                                />
                            )}
                        />

                        <div className="titles">
                            <h1>Declaraciones</h1>
                        </div>
                        {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                        <div className="container">

                            <div className="scrolled">

                                <table className="table big-table">
                                    <thead>
                                        <tr style={{ position: "stycky", textAlign: "center" }}>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff" }}>FECHAS</th>
                                            <th colspan="8" style={{ backgroundColor: "#3aaaff" }}>INFORMACION GENERAL CONTABLE</th>
                                            <th colspan="3" style={{ backgroundColor: "#3abbff" }}>R1 ISR</th>
                                            <th colspan="4" style={{ backgroundColor: "#3aaaff" }}>R12 ISR SUELDOS Y SALARIOS</th>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff" }}>R13 ISR IAS</th>
                                            <th colspan="3" style={{ backgroundColor: "#3aaaff" }}>R14 ISR RETENCIONES SERVICIOS PROFESIONALES</th>
                                            <th colspan="3" style={{ backgroundColor: "#3abbff" }}>R15 ISR RETENCIONES ARRENDAMIENTO</th>
                                            <th colspan="4" style={{ backgroundColor: "#3aaaff" }}>R21 IVA</th>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff" }}>R21 IVA RFN</th>
                                            <th colspan="3" style={{ backgroundColor: "#3aaaff" }}>R24 IVA RETENCIONES</th>
                                            <th colspan="4" style={{ backgroundColor: "#3abbff" }}>IVA SIMPLIFICADO DE CONFIANZA</th>
                                            <th colspan="3" style={{ backgroundColor: "#3aaaff" }}>ISR SIMPLIFICADO DE CONFIANZA</th>
                                        </tr>
                                        <tr>
                                            <th>N°</th>
                                            <th>FECHA PRESENTACION PPM</th>
                                            <th>PERIODO EJERCICIO</th>
                                            <th>FECHAS PRESENTACIONES COMPLEMENTARIAS</th>
                                            <th>INGRESOS DECLARACION SAT </th>
                                            <th>INGRESOS NOMINALES FACTURADOS SAT </th>
                                            <th>INGRESOS NOMINALES A DISMINUIR SAT </th>
                                            <th>CFDI'S TIPO "I"  METADATA CON IVA</th>
                                            <th>INGRESOS ACUM SAT </th>
                                            <th>ACUMULADO CFDI'S TIPO "I" METADATA </th>
                                            <th>CFDI'S TIPO "N"  METADATA</th>
                                            <th>ACUMULADO CFDI'S TIPO "N" METADATA </th>
                                            <th>ISR PERSONA MORAL</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR SUELDOS Y SALARIOS</th>
                                            <th>RECARGOS</th>
                                            <th>SUBSIDIO APLICADO</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR IAS</th>
                                            <th>RECARGOS</th>
                                            <th>SUBSIDIO APLICADO</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR RETENCIONES SERVICIOS PROFESIONALES</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR RETENCIONES ARRENDAMIENTO</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA A CARGO </th>
                                            <th>IVA A FAVOR</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA A CARGO RFN </th>
                                            <th>IVA A FAVOR RFN</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA RETENCIONES</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>IVA SIMPLIFICADO A CARGO </th>
                                            <th>IVA A FAVOR</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                            <th>ISR SIMPLIFICADO</th>
                                            <th>RECARGOS</th>
                                            <th>TOTAL A PAGAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {finalTableContent}

                                    </tbody>
                                </table >
                            </div>

                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default RelacionDeclaracionesComponent