import React, { Component } from 'react';
import AdminSoftApp from './components/general/AdminSoftApp'
// import './App.css';
// import './bootstrap.css';
import './assets/css/main.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <AdminSoftApp />
      </div>
    );
  }
}

export default App;