import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import AuthenticationService from '../general/AuthenticationService.js'



class BusquedaGeneralComponent extends Component {

    constructor(props) {
        console.log("CfdiComponent Constructor")
        super(props)

        this.state = {
            ...props,
            sociedades: [],
            personasFisicas: [],
            clientes: [],
            domicilios: [],
            documentos: [],
            loadingCount: 0,
            loading: false

        }

    }

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_BUSQUEDA")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }
    }





    render() {

        //console.log("renderBusquedaGeneralComponent", this.state)

        let counter = 0;

        let { loading,
            loadingCount,
            sociedades,
            domicilios,
            personasFisicas,
            documentos,
            clientes
        } = this.state

        if (loadingCount >= 5) {
            loading = false;
        }

        return (

            <div id="main">
                <div className="panel">
                    <div className="toolSearch">
                        <span className="icon-search"></span>

                        <div className="container">
                            <TextField className="form-control"
                                value={this.state.busqueda}
                                label="Busqueda"
                                placeholder="Buscar"
                                name="busqueda"
                                onChange={event => {
                                    const { value } = event.target;

                                    this.setState({ busqueda: value.toUpperCase() });

                                    if (value.toUpperCase().length > 2) {

                                        this.setState({ loading: true, loadingCount: 0 });

                                        ElementsDataService.retrieveSearch(value.toUpperCase(), "sociedades")
                                            .then(
                                                response => {
                                                    this.setState({
                                                        sociedades: response.data,
                                                        loadingCount: ++loadingCount
                                                    });

                                                }
                                            )

                                        ElementsDataService.retrieveSearch(value.toUpperCase(), "clientes")
                                            .then(
                                                response => {
                                                    this.setState({
                                                        clientes: response.data,
                                                        loadingCount: ++loadingCount
                                                    });

                                                }
                                            )

                                        ElementsDataService.retrieveSearch(value.toUpperCase(), "personasFisicas")
                                            .then(
                                                response => {
                                                    this.setState({
                                                        personasFisicas: response.data,
                                                        loadingCount: ++loadingCount
                                                    });

                                                }
                                            )

                                        ElementsDataService.retrieveSearch(value.toUpperCase(), "domicilios")
                                            .then(
                                                response => {
                                                    this.setState({
                                                        domicilios: response.data,
                                                        loadingCount: ++loadingCount
                                                    });

                                                }
                                            )

                                        ElementsDataService.retrieveSearch(value.toUpperCase(), "documentos")
                                            .then(
                                                response => {
                                                    this.setState({
                                                        documentos: response.data,
                                                        loadingCount: ++loadingCount
                                                    });

                                                }
                                            )


                                    } else {
                                        this.setState({
                                            documentos: null,
                                            domicilios: null,
                                            personasFisicas: null,
                                            sociedades: null,
                                            clientes: null
                                        })

                                    }

                                }}
                            />
                        </div>
                    </div>
                    <div className="titles">
                        <h1>Resultados Búsqueda</h1>
                    </div>
                    {this.state.message && <div class="alert alert-success">{this.state.message}</div>}

                    <LoadingMask loading={loading} text={"loading..."}>

                        <div className="container">

                            <div id="tablesContainer">
                                <div className="titles">
                                    <h1>Sociedades</h1>
                                </div>
                                {sociedades && sociedades.length > 0 ? <div className="tableContainer"> <table className="table big-table ">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Proyecto</th>
                                            <th>Razon Social</th>
                                            <th>RFC</th>
                                            <th>Resposable Contabilidad</th>
                                            <th>Auxiliar Contable</th>
                                            <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                            <th className="icoSize"><span className="icon-trash"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sociedades.map(
                                                element =>
                                                    <tr key={counter}>
                                                        <td>{++counter}</td>
                                                        <td>{element.clientes && element.clientes.map(cliente => <li>{cliente.nombreCliente}</li>)}</td>
                                                        <td><Link to={`/sociedades/reportes/${element.id}`} >{element.razonSocial}</Link></td>
                                                        <td><Link to={`/sociedades/reportesContables/${element.rfc}`} >{element.rfc}</Link></td>
                                                        <td>{element.responsableContable}</td>
                                                        <td>{element.auxiliarContable}</td>
                                                        <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                                                        <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                </table> </div> : <div className=""><p>NO HAY RESULTADOS</p> </div>}

                                <div className="titles">
                                    <h1>Proyectos</h1>
                                </div>
                                {clientes && clientes.length > 0 ? <div className="tableContainer"> <table className="table big-table">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Nombre Proyecto</th>
                                            <th>Propietario</th>
                                            <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                            <th className="icoSize"><span className="icon-trash"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            clientes.map(
                                                element =>
                                                    <tr key={counter}>
                                                        <td>{++counter}</td>
                                                        <td><Link to={`/clientes/reportes/${element.id}`} >{element.nombreCliente}</Link></td>
                                                        <td>{element.propietario}</td>
                                                        <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil"></span>    </button></td>
                                                        <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                </table> </div> : <div className=""><p>NO HAY RESULTADOS</p> </div>}

                                <div className="titles">
                                    <h1>Personas Fisicas</h1>
                                </div>

                                {personasFisicas && personasFisicas.length > 0 ? <div className="tableContainer"> <table className="table big-table">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Apellido Paterno</th>
                                            <th>Apellido Materno</th>
                                            <th>Nombre(s)</th>
                                            <th>RFC</th>
                                            <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                            <th className="icoSize"><span className="icon-trash"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            personasFisicas.map(
                                                element =>
                                                    <tr key={counter}>
                                                        <td>{++counter}</td>
                                                        <td>{element.apellidoPaterno}</td>
                                                        <td>{element.apellidoMaterno}</td>
                                                        <td>{element.nombre}</td>
                                                        <td><Link to={`/personas-fisicas/reportes/${element.id}`}> {element.rfc}</Link></td>
                                                        <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                                                        <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                </table> </div> : <div className=""><p>NO HAY RESULTADOS</p> </div>}

                                <div className="titles">
                                    <h1>Domicilios</h1>
                                </div>

                                {domicilios && domicilios.length > 0 ? <div className="tableContainer"><table className="table big-table">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Calle</th>
                                            <th className="right">N. Ext</th>
                                            <th className="right">N. Int</th>
                                            <th>Colonia</th>
                                            <th>Municipio</th>
                                            <th>Estado</th>
                                            <th>Telefono</th>
                                            <th>Estatus</th>
                                            <th>Tipo</th>
                                            <th>Sociedades</th>
                                            <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                            <th className="icoSize"><span className="icon-trash"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            domicilios.map(
                                                element => {
                                                    return <tr key={counter}>
                                                        <td>{++counter}</td>
                                                        <td><Link to={`/domicilios/reportes/${element.id}`}>{element.calle} </Link></td>
                                                        <td className="right"><Link to={`/domicilios/reportes/${element.id}`}>{element.numeroExt} </Link></td>
                                                        <td className="right"><Link to={`/domicilios/reportes/${element.id}`}>{element.numeroInterior} </Link></td>
                                                        <td>{element.colonia}</td>
                                                        <td>{element.municipio}</td>
                                                        <td>{element.estado}</td>
                                                        <td>{element.telefono}</td>
                                                        <td>{element.estatus}</td>
                                                        <td>{element.tipo}</td>
                                                        <td>{element.sociedadesNames && element.sociedadesNames.map(sociedad => <li>{sociedad}</li>)}</td>
                                                        <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                                                        <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </table> </div> : <div className=""><p>NO HAY RESULTADOS</p> </div>}

                                <div className="titles">
                                    <h1>Documentos</h1>
                                </div>
                                {documentos && documentos.length > 0 ? <div className="tableContainer"> <table className="table big-table">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Sociedades</th>
                                            <th>Tipo Documento</th>
                                            <th>Asuntos</th>
                                            <th>Fecha</th>
                                            <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                            <th className="icoSize"><span className="icon-trash"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            documentos.map(
                                                element =>
                                                    <tr key={element.id}>
                                                        <td>{++counter}</td>
                                                        <td>{
                                                            element.sociedades ? element.sociedades.map(sociedad => <p> {sociedad.razonSocial}</p>) : ""
                                                        }</td>
                                                        <td><Link to={`/documentos/reportes/${element.id}`}>{element.tipoDocumento}</Link></td>
                                                        <td><Link to={`/documentos/reportes/${element.id}`}>{element.asuntos ? element.asuntos : element.tipoDocumento}</Link></td>

                                                        <td>{element.fecha}</td>

                                                        <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                                                        <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>
                                                    </tr>
                                            )
                                        }
                                    </tbody>
                                </table></div> : <div className=""><p>NO HAY RESULTADOS</p> </div>}

                            </div>
                        </div>
                    </LoadingMask>
                </div>
            </div>
        )
    }
}

export default BusquedaGeneralComponent