import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ThumbnailAdminComponent from './ThumbnailAdminComponent'
import { Sync } from '@material-ui/icons';


class GaleriaDomicilioFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            portadaFile: [],
            portadaData: '',
            fotografiasGalleryFileArray:[],
            fotografiasGalleryDataArray:[],
            loading:false,
            imageCount:''

        }

        this.onSubmit = this.onSubmit.bind(this);

    }

    fotografiasFileArray=[];

    componentDidMount() {
        console.log("GaleriaDomicilioFormularioComponent componentDidMount"  )

        const { match: { params } } = this.props;

        console.log("--params.id",params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("domicilios/galeria",this.state.id)
            .then(response => {

                console.log(response);

                let imageCount = response.data.imageCount;

                if(imageCount){

                    this.setState({imageCount:imageCount})

                    //portada

                    if(imageCount > 0){

                        ElementsDataService.getImageFile(`?fileName=Domicilios/domicilio_${this.state.id}/galeria/0.jpg`)
                            .then(response =>{

                                console.log(response);

                                this.setState({
                                    portadaFile:response,
                                    portadaData: URL.createObjectURL(response)
                                })
                            
                            })
                        
                    }

                    //all other images
                    if(imageCount > 1){
                        for(let i = 1; i < imageCount; i++){
                            ElementsDataService.getImageFile(`?fileName=Domicilios/domicilio_${this.state.id}/galeria/${i}.jpg`)
                            .then(response =>{

                                //console.log("image " + i + "received" + response);

                                let fotografiasGalleryFileArray = this.state.fotografiasGalleryFileArray; 

                                let fotografiasGalleryDataArray = this.state.fotografiasGalleryDataArray; 

                                fotografiasGalleryFileArray[i-1] = response;

                                fotografiasGalleryDataArray[i-1] = URL.createObjectURL(response)

                                this.setState({
                                    fotografiasGalleryFileArray,
                                    fotografiasGalleryDataArray
                                })
                            
                            })
                        }
                    }



                }

  
            })  


    }

    

    onSubmit(values) {

        console.log("onSubmit")

        this.setState({loading:true})

        const formData = new FormData();


        formData.append('galleryFiles', this.state.portadaFile);

        for(let i = 0; i< this.state.fotografiasGalleryFileArray.length; i++) {
            formData.append('galleryFiles', this.state.fotografiasGalleryFileArray[i])
        }

        ElementsDataService.createElement("domicilios/galeria/"+this.state.id,formData)
        .then(response =>{
            console.log(response);
            this.setState({loading:false})
            this.props.history.push(`/domicilios/reportes/${this.state.id}`)
            
        })

    }

    onFilePortadaChangeHandler = (e) => {
        e.preventDefault();

        console.log(e.target.files[0])

        this.setState({            
            portadaFile: e.target.files[0],
            portadaData: URL.createObjectURL(e.target.files[0])
        });

    }

    onGalleryFilesChangeHandler = (e) => {
        e.preventDefault();

        console.log(e.target.files)

        let newFotografiasGalleryFileArray = Array.from(e.target.files);

        let fotografiasGalleryFileArray = this.state.fotografiasGalleryFileArray;

        fotografiasGalleryFileArray = fotografiasGalleryFileArray.concat(newFotografiasGalleryFileArray);

        let fotografiasGalleryDataArray = [];

        console.log("fotografiasGalleryFileArray",fotografiasGalleryFileArray);

        for(let i = 0; i<fotografiasGalleryFileArray.length; i++){
            fotografiasGalleryDataArray[i] = URL.createObjectURL(fotografiasGalleryFileArray[i]);
        }

        this.setState({            
            fotografiasGalleryFileArray,
            fotografiasGalleryDataArray
        });
    }

    portadaDeleteHandler = () => {
        alert("Siempre debe haber una Fachada");
    }
    

    deleteHandler = (index) => {
      
        console.log("deleteHandler: " + index)

        let fotografiasGalleryFileArray = this.state.fotografiasGalleryFileArray;
        
       // console.log("fotografiasGalleryFileArray length",fotografiasGalleryFileArray.length)
       // console.log("fotografiasGalleryFileArray",fotografiasGalleryFileArray)


        fotografiasGalleryFileArray.splice(index,1);

       // console.log("fotografiasGalleryFileArray length",fotografiasGalleryFileArray.length)
       // console.log("fotografiasGalleryFileArray ",fotografiasGalleryFileArray)
        

        let fotografiasGalleryDataArray = [];
        

        for(let i = 0; i<fotografiasGalleryFileArray.length; i++){
            fotografiasGalleryDataArray[i] = URL.createObjectURL(fotografiasGalleryFileArray[i]);
        }
        
       // console.log("fotografiasGalleryFileDataArray length",fotografiasGalleryDataArray.length)

        this.setState({            
            fotografiasGalleryFileArray,
            fotografiasGalleryDataArray
        });


    }

    render() {

        console.log("render GaleriaDomicilioFormularioComponent")
        //console.log("GaleriaDomicilioFormularioComponent state", this.state)
        let {id,
            direccion,
            portadaFile,
            fotografiasFile,
            portadaData,
            loading,
            fotografiasGalleryDataArray
        } = this.state

        return (
            <div id="main">
                <div className="panel">
                <LoadingMask loading={loading} text={"loading..."}>
                    <div className="titles">

                        <h1>{direccion?direccion:"Nueva Galeria"}</h1>
                    </div>
                    <br />
                    <div className="container">
                        <Formik
                            initialValues={{  id,
                                 }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={this.validate}
                            enableReinitialize={true}
                        >
                            {
                                (props) => (
                                    <Form key="1" autoComplete="off" >
                                        
                                    
                                    <div className="cols">
                                        <div className="col1">

                                            <div className="form-group">
                                                <label for="file"><span className="icon-attachment"></span> Fotografía de fachada: </label>
                                                <input id="file" className="form-control" name="file" type="file" onChange={this.onFilePortadaChangeHandler}/>
                                            </div>

                                            <ThumbnailAdminComponent deleteHandler={this.portadaDeleteHandler}  img={portadaData} />
                                        </div>
                                    </div>
                                            
                                    </Form>
                                )}
                        </Formik>
                        <Formik
                            initialValues={{  id,  }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={this.validate}
                            enableReinitialize={true}>

    
                            {
                                (props) => (           
                                    <Form key="2" autoComplete="off">
                                        
                                     <div className="cols">
                                        <div className="col1">

                                            <div className="form-group">
                                                <label for="file"><span className="icon-attachment"></span> Agregar fotografia: </label>
                                                <input id="file" name="file" type="file" multiple onChange={this.onGalleryFilesChangeHandler} className="form-control" />
                                        </div>
                                    </div>
                                            

                                    </div>
                                    <div className="grid">

                                        {                       
                                        fotografiasGalleryDataArray.map((image,index) => (

                                            <ThumbnailAdminComponent index = {index} deleteHandler={this.deleteHandler} img={image} />
                                        ))}
                                        
                                        
                                    </div>
                                    <div className="buttons">

                                        <button className="btn btn-success" type="submit"><span className="icon-checkmark"></span> Guardar</button>
                                    </div>
                                    </Form>
                                )
                            }
                        </Formik>

                    </div>
                </LoadingMask>
                </div>
            </div>
        )
    }
}

export default GaleriaDomicilioFormularioComponent