import React, { Component } from 'react'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'

class CfdiReporteComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            rfc: '',
            fecha: moment(new Date()).format('YYYY-MM-DD'),
            tipo: ''
        }

    }


    render() {

        let { rfc, fecha, cantidad, tipo } = this.state

        return (
            <div id="main">
                <div className="panel">

                    <h1>Reporte CFDI</h1>
                    <div className="container">


                    </div>
                </div>
            </div>
        )
    }
}

export default CfdiReporteComponent