import React, { Component } from 'react'
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import { Link } from 'react-router-dom'
import AuthenticationService from '../general/AuthenticationService.js'

class DocumentoReporteComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            tipoDocumento: '',
            resumen: '',
            personasFisicas: [],
            sociedades: [],
            documentFileUrl: '',
            asuntos: '',
            entidadesRolesComentarios: [],
            entidades: [{
                nombres: [],
                roles: [],
                comentarios: "",
                id: 1
            },],
            fecha: ''

        }

    }

    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_DOCUMENTOS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }


        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("documentos", this.state.id)
            .then(response => {

                console.log("response", response)

                this.setState({
                    tipoDocumento: response.data.tipoDocumento,
                    asuntos: response.data.asuntos,
                    resumen: response.data.resumen,
                    documentFileUrl: response.data.documentFileUrl,
                    personasFisicas: response.data.personasFisicas,
                    sociedades: response.data.sociedades,
                    entidadesRolesComentarios: response.data.entidadesRolesComentarios,
                    entidades: JSON.parse(response.data.entidadesRolDocumento).data,
                    fecha: response.data.fecha,
                })

            })

    }


    render() {

        console.log("render DocumentoReporteComponent")
        console.log("DocumentoReporteComponent state", this.state)
        let { id,
            tipoDocumento,
            entidades,
            asuntos,
            resumen,
            documentFileUrl,
            personasFisicas,
            sociedades,
            entidadesRolesComentarios,
            fecha
        } = this.state




        /*
                   
                const listaPersonasFisicas = personasFisicas?personasFisicas.map((personaFisica) => <li key={personaFisica.id}>
                <Link  to={`/personas-fisicas/reportes/${personaFisica.id}`}>
                    {personaFisica.apellidoPaterno + " " + personaFisica.apellidoMaterno + " " + personaFisica.nombre}
                </Link>
                </li>) : "";
        
                const listaSociedades = sociedades?sociedades.map((sociedad) => <li key={sociedad.id}>
                <Link  to={`/sociedades/reportes/${sociedad.id}`}>
                    {sociedad.razonSocial}
                </Link>
                </li>):"";
        
               
                */


        let sociedadesStr = "";
        let sociedadesArray = [];

        if (sociedades) {
            sociedades.forEach(sociedad => {
                sociedadesStr += sociedad.razonSocial + "_";
                sociedadesArray.push(<p><Link to={`/sociedades/reportes/${sociedad.id}`}>{sociedad.razonSocial}</Link></p>)
            });

            sociedadesStr = sociedadesStr.slice(0, -1)
        }

        const listaEntidadesRolesComentarios = new Array()

        if (entidadesRolesComentarios) {
            for (let index = 0; index < entidadesRolesComentarios.length; index++) {
                const element = entidadesRolesComentarios[index];
                //console.log(element)
                listaEntidadesRolesComentarios.push(
                    <tr>
                        <td><Link to={`/${element.tipo === "sociedad" ? "sociedades" : "personas-fisicas"}/reportes/${element.entidadId}`}>{element.razonSocial}</Link></td>
                        <td>{element.rol}</td>
                        <td style={{ whiteSpace: "pre-wrap" }} >{element.comentarios}</td>
                    </tr>
                )

            }
        }

        const listaEntidadesRoles = new Array()


        if (entidades) {
            console.log("entidades", entidades);
            this.state.entidades.map(
                element =>
                    listaEntidadesRoles.push(
                        <tr key={element.id}>
                            <td>{element.nombres.map(item =>
                                <p>
                                    <Link to={`/${item.tipo === "sociedad" ? "sociedades" : "personas-fisicas"}/reportes/${item.id}`}>{item.nombre}</Link>
                                </p>
                            )}</td>
                            <td>{element.roles.map(item => item.nombre + ", ")}</td>
                            <td>{element.comentarios}</td>
                        </tr>
                    )
            )
        }





        console.log("entidadesRolesComentarios", entidadesRolesComentarios)


        return (
            <div id="main">
                <div className="panel">
                    <div className="titles">
                        <div className="tool-btn-agregar">
                            <button className="btn btn-edit" onClick={() => this.props.history.push(`/documentos/${id}`)}><span className="icon-edit-pencil"></span> <span className="icon-document"></span> </button>

                        </div>
                        <div className="tool-btn-agregar">
                            <button className="btn btn-agregar" onClick={() => this.props.history.push(`/documentos/-1`)}><span className="icon-add-solid"> </span><span className="icon-document"></span></button>
                        </div>
                        <h1>Reporte Documento</h1>
                    </div>


                    <div className="container">
                        <table className="table simple">
                            <tr>
                                <td>Tipo de Documento</td><td>{tipoDocumento}</td>
                            </tr>
                            <tr>
                                <td>Sociedades</td><td>{sociedadesArray}</td>
                            </tr>
                            <tr>
                                <td>Asuntos</td><td>{asuntos}</td>
                            </tr>
                            <tr>
                                <td>Fecha</td><td>{fecha}</td>
                            </tr>
                            <tr>
                                <td>Resumen</td><td style={{ whiteSpace: "pre-wrap" }} >{resumen}</td>
                            </tr>
                            <tr>
                                <td>PDF</td><td>{documentFileUrl
                                    ? <button type="button" className="btn btn-success" onClick={() => {
                                        console.log("Descargando Archivo")

                                        ElementsDataService.downloadS3File(`?fileName=${documentFileUrl}`,
                                            `${sociedadesStr}_${asuntos.replace(",", "_")}.pdf`)

                                    }}><span className="icon-download"></span> Descargar</button>
                                    : "No hay un archivo cargado todavía"}</td>
                            </tr>

                        </table>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Entes</th>
                                    <th>Roles</th>
                                    <th>Comentarios </th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaEntidadesRoles}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        )
    }
}

export default DocumentoReporteComponent