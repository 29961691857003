import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import AuthenticationService from '../general/AuthenticationService.js'




class ClienteComponent extends Component {
    constructor(props) {
        console.log("ClienteComponent Constructor")
        super(props)

        this.state = {
            ...props,
        }

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("READ_CLIENTES")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }

        this.props.refreshElements();
    }

    direccionASC = true;
    actualKey = "";

    compareBy(key, ascending) {
        let reverse = ascending ? 1 : -1;
        return function (a, b) {
            if (!a[key]) {
                return 1;
            }
            else if (!b[key]) {
                return -1;
            }
            if (a[key] < b[key]) return -1 * reverse;
            if (a[key] > b[key]) return 1 * reverse;
            return 0;
        };
    }

    sortBy(key) {

        if (!this.actualKey) {
            this.actualKey = key;
            this.direccionASC = 1;
        } else if (this.actualKey != key) {
            this.direccionASC = 1;
            this.actualKey = key;
        } else {
            this.direccionASC = !this.direccionASC;
        }

        let arrayCopy = [...this.state.elements];

        arrayCopy.sort(this.compareBy(key, this.direccionASC));

        this.setState({ elements: arrayCopy });
    }


    render() {

        console.log("render ClienteComponent", this.state)
        let counter = 0;

        return (
            <div id="main">
                {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                <div className="panel">
                    <div className="toolSearch">
                        <span className="icon-search"></span>

                        <TextField className="form-control"
                            value={this.state.busqueda}
                            label="Busqueda"
                            placeholder="Buscar"
                            name="busqueda"
                            onChange={event => {
                                const { value } = event.target;

                                this.setState({ busqueda: value.toUpperCase() });

                                if (value.toUpperCase().length > 2) {
                                    ElementsDataService.retrieveSearch(value.toUpperCase(), "clientes")
                                        .then(
                                            response => {
                                                console.log("retrieveBusqueda", response.data);
                                                this.setState({ elements: response.data })
                                            }
                                        )
                                }

                            }}
                        />
                    </div>
                    <div className="titles">
                        <div className="tool-btn-agregar">
                            <button className="btn btn-agregar" onClick={this.props.addElementClicked}><span className="icon-add-solid"></span> <span className="icon-user"></span></button>
                        </div>
                        <h1>Proyectos</h1>
                    </div>

                    <div className="container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th onClick={() => { this.sortBy('nombreCliente') }}>Nombre Proyecto</th>
                                    <th onClick={() => { this.sortBy('propietario') }}>Propietario</th>
                                    <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                    {AuthenticationService.haveAuthorization("DELETE_CLIENTES") && <th className="icoSize"><span className="icon-trash"></span></th>}
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={0}>
                                    <td>0</td>
                                    <td><Link to={`/clientes/reportes/0`} >TODOS</Link></td>
                                    <td></td>
                                    <td></td>
                                    {AuthenticationService.haveAuthorization("DELETE_CLIENTES") && <td></td>}
                                </tr>
                                {
                                    this.state.elements && this.state.elements.map(
                                        element =>
                                            <tr key={element.id}>
                                                <td>{++counter}</td>
                                                <td><Link to={`/clientes/reportes/${element.id}`} >{element.nombreCliente}</Link></td>
                                                <td>{element.propietario}</td>
                                                <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil"></span>    </button></td>
                                                {AuthenticationService.haveAuthorization("DELETE_CLIENTES") && <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>}
                                            </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClienteComponent