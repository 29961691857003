import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage, } from 'formik';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import LoadingMask from "react-loadingmask";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import AuthenticationService from '../general/AuthenticationService.js'

class PersonaFisicaFormularioComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            apellidoMaterno: '',
            apellidoPaterno: '',
            curp: null,
            nombre: '',
            fileCURP: '',
            fileINE: '',
            fileCif: '',
            rfc: '',
            vigenciaIne: '',
            noVigente: '',
            documentos: [],
            loading: false,
            fileImpresionPantallaIne: '',
            vigenciaImpresionPantallaIne: '',
            rfcDesconocido: false,

        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)

    }

    componentDidMount() {
        if (!AuthenticationService.haveAuthorization("WRITE_PERSONAS_FISICAS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }


        const { match: { params } } = this.props;

        console.log("--params.id", params.id)

        if (Number(params.id) === -1) {
            return
        }

        ElementsDataService.retrieveElement("personas-fisicas", this.state.id)
            .then(response => {
                console.log("response", response)

                this.setState({
                    apellidoMaterno: response.data.apellidoMaterno,
                    apellidoPaterno: response.data.apellidoPaterno,
                    curp: response.data.curp,
                    nombre: response.data.nombre,
                    rfc: response.data.rfc,
                    documentos: response.data.documentos,
                    fileCURP: response.data.curpFileUrl,
                    fileCif: response.data.cifFileUrl,
                    fileINE: response.data.ineFileUrl,
                    vigenciaIne: response.data.vigenciaIne == "1900-01-01" ? "" : response.data.vigenciaIne,
                    noVigente: response.data.vigenciaIne == "1900-01-01" ? true : false,
                    fileImpresionPantallaIne: response.data.impresionPantallaIneFileUrl,
                    vigenciaImpresionPantallaIne: response.data.vigenciaImpresionPantallaIne,
                })

            })

    }

    validate(values) {

        let errors = {}

        return errors

    }

    onSubmit(values) {

        console.log("onSubmit")

        this.setState({ loading: true })

        let messageStr = ""

        if (!this.state.apellidoPaterno) {
            messageStr += "Debe ingresar el apellido Paterno\n"
        }

        if (!this.state.nombre) {
            messageStr += "Debe ingresar el Nombre\n"
        }

        if (!this.state.rfc) {
            messageStr += "Debe ingresar el RFC\n"
        }

        if (messageStr) {
            alert(messageStr)
            this.setState({ loading: false })
            return
        }


        const formData = new FormData();
        formData.append('fileCURP', this.state.fileCURP);
        formData.append('fileINE', this.state.fileINE);
        formData.append('fileCif', this.state.fileCif);
        formData.append('fileImpresionPantallaIne', this.state.fileImpresionPantallaIne);
        formData.append('apellidoMaterno', values.apellidoMaterno ? values.apellidoMaterno : '-');
        formData.append('apellidoPaterno', values.apellidoPaterno);
        formData.append('curp', values.curp);
        formData.append('rfc', values.rfc);
        formData.append('nombre', values.nombre);
        formData.append('vigenciaImpresionPantallaIne', this.state.vigenciaImpresionPantallaIne);

        if (this.state.noVigente && !this.state.vigenciaIne) {
            formData.append('vigenciaIne', "1900-01-01");
        } else {
            formData.append('vigenciaIne', this.state.vigenciaIne);
        }



        console.log("values curp " + values.curp);
        console.log("values rfc " + values.rfc);

        if (Number(this.state.id) === -1) {
            ElementsDataService.createElement("personas-fisicas", formData)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "CREATED") {
                        this.props.history.push(`/personas-fisicas/reportes/${response.data.id}`)
                    } else {
                        alert(response.data.message);
                        this.setState({ loading: false })
                    }
                })
        } else {
            ElementsDataService.updateElement("personas-fisicas", this.state.id, formData)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "CREATED") {
                        this.props.history.push(`/personas-fisicas/reportes/${response.data.id}`)
                    } else {
                        alert(response.data.message);
                        this.setState({ loading: false })
                    }
                })
        }


    }

    render() {



        console.log("render personaFisicaFormularioComponent")
        console.log("PersonaFisicaFormularioComponent state", this.state)
        let {
            apellidoMaterno,
            apellidoPaterno,
            curp,
            nombre,
            rfc,
            fileCURP,
            fileINE,
            fileCif,
            vigenciaIne,
            noVigente,
            loading,
            fileImpresionPantallaIne,
            vigenciaImpresionPantallaIne,
            rfcDesconocido
        } = this.state

        return (
            <div id="main">
                <div className="panel">
                    <LoadingMask loading={loading} text={"loading..."}>

                        <h1>Persona Fisica</h1>
                        <div className="container">
                            <Formik
                                initialValues={{
                                    apellidoMaterno,
                                    apellidoPaterno,
                                    curp,
                                    nombre,
                                    rfc
                                }}
                                onSubmit={this.onSubmit}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validate={this.validate}
                                enableReinitialize={true}
                            >
                                {
                                    (props) => (
                                        <Form autoComplete="off" enctype="multipart/form-data">

                                            <ErrorMessage name="curp" component="div"
                                                className="alert alert-warning" />
                                            <ErrorMessage name="rfc" component="div"
                                                className="alert alert-warning" />
                                            <ErrorMessage name="nombre" component="div"
                                                className="alert alert-warning" />
                                            <ErrorMessage name="apellidoPaterno" component="div"
                                                className="alert alert-warning" />
                                            <ErrorMessage name="apellidoMaterno" component="div"
                                                className="alert alert-warning" />

                                            <div className="cols">
                                                <div className="col3">
                                                    <fieldset className="form-group">
                                                        <label>Apellido Paterno </label>
                                                        <Field onChange={e => {
                                                            let value = e.target.value || "";
                                                            value = value.toUpperCase();
                                                            this.setState({ "apellidoPaterno": value })
                                                        }}
                                                            className="form-control" type="text" name="apellidoPaterno" />
                                                    </fieldset>
                                                </div>
                                                <div className="col3">
                                                    <fieldset className="form-group">
                                                        <label>Apellido Materno </label>
                                                        <Field onChange={e => {
                                                            let value = e.target.value || "";
                                                            value = value.toUpperCase();
                                                            this.setState({ "apellidoMaterno": value })
                                                        }}
                                                            className="form-control" type="text" name="apellidoMaterno" />
                                                    </fieldset>
                                                </div>
                                                <div className="col3">
                                                    <fieldset className="form-group">
                                                        <label>Nombre </label>
                                                        <Field onChange={e => {
                                                            let value = e.target.value || "";
                                                            value = value.toUpperCase();
                                                            this.setState({ "nombre": value })
                                                        }}
                                                            className="form-control" type="text" name="nombre" />
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="cols">
                                                <div className="form-group">
                                                    <label>No se conoce el RFC</label>
                                                    <Checkbox
                                                        checked={rfcDesconocido}
                                                        color="primary"
                                                        onChange={event => {

                                                            if (event.target.checked) {

                                                                this.setState({
                                                                    rfcDesconocido: true,
                                                                    rfc: "XXXXXXXXXXXXX"
                                                                });

                                                            } else {
                                                                this.setState({
                                                                    rfcDesconocido: false,
                                                                    rfc: ""
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="cols">
                                                <div className="col2">
                                                    <fieldset className="form-group">
                                                        <label>RFC</label>
                                                        <Field
                                                            onChange={e => {
                                                                let value = e.target.value || "";
                                                                value = value.toUpperCase();
                                                                this.setState({ "rfc": value })
                                                            }}
                                                            className="form-control" type="text" name="rfc" />
                                                    </fieldset>

                                                </div>
                                                <div className="col2">
                                                    <fieldset className="form-group">
                                                        <label>CURP</label>
                                                        <Field
                                                            onChange={e => {
                                                                let value = e.target.value || "";
                                                                value = value.toUpperCase();
                                                                this.setState({ "curp": value })
                                                            }}
                                                            className="form-control" type="text" name="curp" />
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Archivo CURP: {fileCURP ?
                                                            fileCURP.name ? "" : fileCURP : ""}</label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "fileCURP": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Archivo INE: {fileINE ?
                                                            fileINE.name ? "" : fileINE : ""}</label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "fileINE": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>

                                                </div>
                                            </div>



                                            <div className="form-group">
                                                <label>INE No Vigente</label>
                                                <Checkbox
                                                    checked={noVigente}
                                                    color="primary"
                                                    onChange={event => {
                                                        this.setState({
                                                            noVigente: event.target.checked,
                                                            vigenciaIne: event.target.checked ? "" : vigenciaIne
                                                        });
                                                    }}
                                                />
                                            </div>


                                            <TextField className="form-control"
                                                value={vigenciaIne}
                                                id="date"
                                                label="Vigencia INE"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.setState({ vigenciaIne: value });
                                                }}
                                            />

                                            <br />    <br />    <br />

                                            <div className="cols">
                                                <div className="col1">

                                                    <div className="form-group">
                                                        <label for="file"><span className="icon-attachment"></span> Archivo Impresion Pantalla INE: {fileImpresionPantallaIne ?
                                                            fileImpresionPantallaIne.name ? "" : fileImpresionPantallaIne : ""}</label>
                                                        <input id="file" name="file" type="file" onChange={(event) => {
                                                            this.setState({ "fileImpresionPantallaIne": event.currentTarget.files[0] });
                                                        }} className="form-control" />
                                                    </div>

                                                </div>
                                            </div>


                                            <TextField className="form-control"
                                                value={vigenciaImpresionPantallaIne}
                                                id="date"
                                                label="Vigencia Impresion Pantalla INE"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    this.setState({ vigenciaImpresionPantallaIne: value });
                                                }}
                                            />
                                            <br />    <br />    <br />

                                            <div className="form-group">
                                                <label for="file"><span className="icon-attachment"></span> Archivo CIF: {fileCif ?
                                                    fileCif.name ? "" : fileCif : ""}</label>
                                                <input id="file" name="file" type="file" onChange={(event) => {
                                                    this.setState({ "fileCif": event.currentTarget.files[0] });
                                                }} className="form-control" />
                                            </div>
                                            <div className="buttons">

                                                <button className="btn btn-success" type="submit"><span className="icon-checkmark"></span> Guardar</button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>

                        </div>
                    </LoadingMask>
                </div>

            </div>
        )
    }
}


export default PersonaFisicaFormularioComponent