import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute.jsx'
import LoginComponent from './LoginComponent.jsx'
import ErrorComponent from './ErrorComponent.jsx'
import LogoutComponent from './LogoutComponent.jsx'
import WelcomeComponent from './WelcomeComponent.jsx'
import HeaderComponent from './HeaderComponent.jsx'
import ListElementsComponent from './ListElementsComponent.jsx'
import CfdiFormularioComponent from '../entidades/CfdiFormularioComponent.jsx'
import ClienteFormularioComponent from '../entidades/ClienteFormularioComponent.jsx'
import SociedadFormularioComponent from '../entidades/SociedadFormularioComponent.jsx'
import DocumentoFormularioComponent from '../entidades/DocumentoFormularioComponent.jsx'
import PersonaFisicaFormularioComponent from '../entidades/PersonaFisicaFormularioComponent.jsx'
import DomicilioFormularioComponent from '../entidades/DomicilioFormularioComponent.jsx'
import SociedadReporteContableComponent from '../entidades/SociedadReporteContableComponent.jsx'
import SociedadReporteComponent from '../entidades/SociedadReporteComponent.jsx'
import ClienteReporteComponent from '../entidades/ClienteReporteComponent.jsx'
import PersonaFisicaReporteComponent from '../entidades/PersonaFisicaReporteComponent.jsx'
import DomicilioReporteComponent from '../entidades/DomicilioReporteComponent.jsx'
import DocumentoReporteComponent from '../entidades/DocumentoReporteComponent.jsx'
import CfdiReporteComponent from '../entidades/CfdiReporteComponent.jsx'
import DeclaracionProvisionalComponent from '../entidades/DeclaracionProvisionalComponent.jsx'
import DeclaracionProvisionalFormularioComponent from '../entidades/DeclaracionProvisionalFormularioComponent.jsx'
import CfdiRefreshDashboardComponent from '../entidades/CfdiRefreshDashboardComponent.jsx'
import HistoriaComponent from '../entidades/HistoriaComponent.jsx'
import OpinionesCumplimientoReporte from '../entidades/OpinionesCumplimientoReporte.jsx'
import OpinionCumplimientoFileUpload from '../entidades/OpinionCumplimientoFileUpload.jsx'
import RevisionPlazaMensual from '../entidades/RevisionPlazaMensual.jsx'
import ReporteSociedadesRevisadas from '../entidades/ReporteSociedadesRevisadas.jsx'
import GastosDomicilioFormularioComponent from '../entidades/GastosDomicilioFormularioComponent.jsx'
import GaleriaDomicilioComponent from '../entidades/GaleriaDomicilioComponent.jsx'
import GaleriaDomicilioFormularioComponent from '../entidades/GaleriaDomicilioFormularioComponent.jsx'
import DomiciliosReporteCostoSociedadesCliente from '../entidades/DomiciliosReporteCostoSociedadesCliente.jsx'
import BusquedaGeneralComponent from '../entidades/BusquedaGeneralComponent.jsx'
import AlertasComponent from '../entidades/AlertasComponent.jsx'
import RelacionDeclaracionesComponent from '../entidades/RelacionDeclaracionesComponent.jsx'
import AddFile from './AddFile.jsx'
import getCFDIs from '../entidades/getCFDIs.jsx'
import UserFormularioComponent from '../entidades/UserFormularioComponent.jsx'


class AdminSoftApp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      userLoggedIn: '',
    }

  }


  render() {
    return (
      <div className="TodoApp">
        <Router>
          <div>
            <HeaderComponent />
            <Switch>
              <Route path="/" exact component={LoginComponent} />
              <Route path="/login" component={LoginComponent} />

              <AuthenticatedRoute path="/welcome/:name"
                render={(props) => (
                  <WelcomeComponent {...props}
                    setUserLoggedIn={userLoggedIn => this.setState({ userLoggedIn: userLoggedIn })}
                  />
                )}
              />

              <AuthenticatedRoute path="/alertas" component={AlertasComponent} />
              <AuthenticatedRoute path="/historial" component={HistoriaComponent} />
              <AuthenticatedRoute path="/busqueda" component={BusquedaGeneralComponent} />
              <AuthenticatedRoute path="/cargar-opiniones-cumplimiento" component={OpinionCumplimientoFileUpload} />
              <AuthenticatedRoute path="/opiniones-cumplimiento" component={OpinionesCumplimientoReporte} />
              <AuthenticatedRoute path="/revision-plaza-mensual" component={RevisionPlazaMensual} />
              <AuthenticatedRoute path="/reporte-sociedades-revisadas" component={ReporteSociedadesRevisadas} />
              <AuthenticatedRoute path="/relacion-declaraciones" component={RelacionDeclaracionesComponent} />
              <AuthenticatedRoute path="/get-cfdis" component={getCFDIs} />


              <AuthenticatedRoute path="/declaraciones-provisionales/:id" component={DeclaracionProvisionalFormularioComponent} />
              <AuthenticatedRoute path="/declaraciones-provisionales" component={DeclaracionProvisionalComponent} />
              <AuthenticatedRoute path="/refresh-cache" component={CfdiRefreshDashboardComponent} />

              <AuthenticatedRoute path="/cfdis/reportes/:id" component={CfdiReporteComponent} />
              <AuthenticatedRoute path="/cfdis/:id" component={CfdiFormularioComponent} />
              <AuthenticatedRoute path="/cfdis"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="cfdis" />
                )}
              />

              <AuthenticatedRoute path="/clientes/reportes/:id" component={ClienteReporteComponent} />
              <AuthenticatedRoute path="/clientes/:id" component={ClienteFormularioComponent} />
              <AuthenticatedRoute path="/clientes"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="clientes" />
                )}
              />

              <AuthenticatedRoute path="/documentos/reportes/:id" component={DocumentoReporteComponent} />
              <AuthenticatedRoute path="/documentos/:id" component={DocumentoFormularioComponent} />
              <AuthenticatedRoute path="/documentos"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="documentos" />
                )}
              />

              <AuthenticatedRoute path="/domicilios/reportes/costos-sociedades-cliente:id" component={DomiciliosReporteCostoSociedadesCliente} />
              <AuthenticatedRoute path="/domicilios/reportes/:id" component={DomicilioReporteComponent} />
              <AuthenticatedRoute path="/domicilios/galeria/editar/:id" component={GaleriaDomicilioFormularioComponent} />
              <AuthenticatedRoute path="/domicilios/galeria/:id" component={GaleriaDomicilioComponent} />
              <AuthenticatedRoute path="/domicilios/gastos/:id" component={GastosDomicilioFormularioComponent} />
              <AuthenticatedRoute path="/domicilios/:id" component={DomicilioFormularioComponent} />
              <AuthenticatedRoute path="/domicilios"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="domicilios" />
                )}
              />

              <AuthenticatedRoute path="/personas-fisicas/reportes/:id" component={PersonaFisicaReporteComponent} />
              <AuthenticatedRoute path="/personas-fisicas/:id" component={PersonaFisicaFormularioComponent} />
              <AuthenticatedRoute path="/personas-fisicas"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="personas-fisicas" />
                )}
              />


              <AuthenticatedRoute path="/sociedades/reportesContables/:rfc" component={SociedadReporteContableComponent} />
              <AuthenticatedRoute path="/sociedades/reportes/:id" render={(props) => <SociedadReporteComponent {...props} key={Date.now()} />} />
              <AuthenticatedRoute path="/sociedades/:id" component={SociedadFormularioComponent} />
              <AuthenticatedRoute path="/sociedades"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="sociedades" />
                )}

              />

              <AuthenticatedRoute path="/users/:id" render={(props) => <UserFormularioComponent {...props} key={Date.now()} />} />
              <AuthenticatedRoute path="/users"
                render={(props) => (
                  <ListElementsComponent {...props} ruta="users" />
                )}

              />

              <AuthenticatedRoute path="/uploadFile" component={AddFile} />

              <Route path="/logout"
                render={(props) => (
                  <LogoutComponent {...props}
                    setUserLoggedIn={userLoggedIn => this.setState({ userLoggedIn: userLoggedIn })}
                  />
                )}
              />
              <Route component={ErrorComponent} />
            </Switch>

          </div>
        </Router>

      </div>
    )
  }
}

export default AdminSoftApp