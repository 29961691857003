import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import ElementsDataService from '../../api/entitiesDataServices/ElementsDataService.js'
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'html-react-parser';
import LoadingMask from "react-loadingmask";
import { MdPersonSearch } from 'react-icons/md'
import AuthenticationService from '../general/AuthenticationService.js'

class PersonaFisicaComponent extends Component {
    constructor(props) {
        console.log("PersonaFisicaComponent Constructor")
        super(props)

        this.state = {
            ...props,
            allPersonasFisicas: [],
            entidadesArray: [],
            busqueda: '',
        }

        this.goBusqueda = this.goBusqueda.bind(this)


    }


    goBusqueda() {

        console.log("goBusqueda")
        this.setState({ loading: true })

        if (this.state.busqueda.toUpperCase().length > 2) {
            ElementsDataService.retrieveSearch(this.state.busqueda.toUpperCase(), "personasFisicas")
                .then(
                    response => {
                        console.log("retrieveBusqueda", response.data);
                        if (response.data.length == 0) {
                            alert("Esta búsqueda no arroja resultados.")
                        }
                        this.setState({ elements: response.data, loading: false })
                    }
                )
        } else {
            alert("Debe escribir al menos tres caracteres para realizar una búsqueda.")
            this.setState({ elements: [], loading: false })
        }

    }


    componentDidMount() {

        if (!AuthenticationService.haveAuthorization("READ_PERSONAS_FISICAS")) {
            alert("Usuario no autorizado")
            this.props.history.push(`/login`)
            return;
        }


        this.setState({ elements: [], loading: true })
        let entidadesArray = [];

        ElementsDataService.retrieveAllElements("personas-fisicas")
            .then(
                respuesta => {
                    this.setState({ allPersonasFisicas: respuesta.data, elements: respuesta.data })

                    this.state.allPersonasFisicas.map((element) => {

                        entidadesArray.push({
                            id: element.id,
                            nombre: element.nombre + " " + element.apellidoPaterno + " " + element.apellidoMaterno,
                            rfc: element.rfc,
                            tipo: "personaFisica"
                        })
                    })

                    this.setState({ entidadesArray: entidadesArray, loading: false })
                }
            )
    }

    direccionASC = true;
    actualKey = "";

    compareBy(key, ascending) {
        let reverse = ascending ? 1 : -1;
        return function (a, b) {
            if (!a[key]) {
                return 1;
            }
            else if (!b[key]) {
                return -1;
            }
            if (a[key] < b[key]) return -1 * reverse;
            if (a[key] > b[key]) return 1 * reverse;
            return 0;
        };
    }

    sortBy(key) {

        if (!this.actualKey) {
            this.actualKey = key;
            this.direccionASC = 1;
        } else if (this.actualKey != key) {
            this.direccionASC = 1;
            this.actualKey = key;
        } else {
            this.direccionASC = !this.direccionASC;
        }

        let arrayCopy = [...this.state.elements];

        arrayCopy.sort(this.compareBy(key, this.direccionASC));

        this.setState({ elements: arrayCopy });
    }

    render() {

        console.log("render PersonaFisicaComponent", this.state)


        let counter = 0;

        let { entidadesArray, busqueda, loading } = this.state


        let personasFisicasTableRows = []



        this.state.elements && this.state.elements.forEach(
            element => {

                personasFisicasTableRows.push(<tr key={element.id}>
                    <td>{++counter}</td>
                    <td>{busqueda.length > 2 ?
                        parse(element.apellidoPaterno.replace(busqueda, "<span style=\"color:blue;\">" + busqueda + "</span>")) :
                        element.apellidoPaterno}</td>
                    <td>{busqueda.length > 2 ?
                        parse(element.apellidoMaterno.replace(busqueda, "<span style=\"color:blue;\">" + busqueda + "</span>")) :
                        element.apellidoMaterno}</td>
                    <td>{busqueda.length > 2 ?
                        parse(element.nombre.replace(busqueda, "<span style=\"color:blue;\">" + busqueda + "</span>")) :
                        element.nombre}</td>
                    <td><Link to={`/personas-fisicas/reportes/${element.id}`}> {
                        busqueda.length > 2 ?
                            parse(element.rfc.replace(busqueda, "<span style=\"color:blue;\">" + busqueda + "</span>")) :
                            element.rfc
                    }</Link></td>
                    <td>{element.id}</td>
                    <td className="icoSize"><button className="btn btn-edit" onClick={() => this.props.updateElementClicked(element.id)}><span className="icon-edit-pencil" ></span>  </button></td>
                    {AuthenticationService.haveAuthorization("DELETE_PERSONAS_FISICAS") && <td className="icoSize"><button className="btn btn-delete" onClick={() => this.props.deleteElementClicked(element.id)}><span className="icon-trash"></span></button></td>}
                </tr>)



            })





        return (
            <LoadingMask loading={loading} text={"loading..."}>
                <div id="main">
                    <div className="panel">
                        <div className="cols">
                            <div className="col2">

                                <div className="toolSearch">
                                    <span className="icon-search"></span>

                                    <div className="container">
                                        <TextField className="form-control"
                                            value={this.state.busqueda}
                                            label="Busqueda"
                                            placeholder="Buscar"
                                            name="busqueda"
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    this.goBusqueda();
                                                }
                                            }}
                                            onChange={event => {
                                                const { value } = event.target;

                                                if (value) {

                                                    this.setState({ elements: [], busqueda: value.toUpperCase() })

                                                } else {

                                                    this.setState({
                                                        busqueda: value.toUpperCase(),
                                                        elements: this.state.allPersonasFisicas
                                                    })

                                                }



                                            }}
                                        />
                                    </div>
                                    <div className="tool-btn-agregar float-right">
                                        <button className="btn btn-agregar" onClick={this.goBusqueda}><MdPersonSearch /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col2">
                                <Autocomplete
                                    id="personasFisicasSelector"
                                    options={entidadesArray}
                                    getOptionLabel={(option) => option.nombre + " " + option.rfc + " : " + option.id}
                                    filterSelectedOptions
                                    onChange={(e, value) => {
                                        console.log(value);
                                        value && this.props.history.push(`/personas-fisicas/reportes/${value.id}`)
                                    }}
                                    getOptionSelected={(option, value) => option.rfc === value.rfc}
                                    //value={this.getSelectedItem()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Persona Fisica"
                                            placeholder=""
                                            name="personasFisicas"
                                        />
                                    )}
                                />
                            </div>


                        </div>

                        <div className="titles">
                            <div className="tool-btn-agregar">
                                <button className="btn btn-agregar" onClick={this.props.addElementClicked}><span className="icon-add-solid"> </span><span className="icon-user"></span></button>
                            </div>
                            <h1>Personas Físicas</h1>
                        </div>

                        {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                        <div className="container">

                            <div id="tablesContainer">
                                <div className="tableContainer">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th onClick={() => { this.sortBy('apellidoPaterno') }}>Apellido Paterno</th>
                                                <th onClick={() => { this.sortBy('apellidoMaterno') }}>Apellido Materno</th>
                                                <th onClick={() => { this.sortBy('nombre') }}>Nombre(s)</th>
                                                <th onClick={() => { this.sortBy('rfc') }}>RFC</th>
                                                <th>id</th>
                                                <th className="icoSize"><span className="icon-edit-pencil"></span></th>
                                                {AuthenticationService.haveAuthorization("DELETE_PERSONAS_FISICAS") && <th className="icoSize"><span className="icon-trash"></span></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                personasFisicasTableRows
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingMask>
        )
    }
}

export default PersonaFisicaComponent