import React, { Component } from 'react'
import '../../assets/css/thumbnail.scss';
function ThumbnailAdminComponent(props) {
    
    return(
        <div className="thumnail">
           {props.deleteHandler&&<a onClick={()=>props.deleteHandler(props.index)} className="eliminar"> X </a>} 
            <img src={props.img} alt="" />
        </div>
    );


}

export default ThumbnailAdminComponent