import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthenticationService from './AuthenticationService.js'
import { ImMenu, ImCross } from "react-icons/im";


class HeaderComponent extends Component {

    constructor(props) {
        super(props)

        this.state = { showSmallMenu: false }

        this.toogleAltMenu = this.toogleAltMenu.bind(this)
    }

    toogleAltMenu(e) {
        this.setState({ showSmallMenu: !this.state.showSmallMenu });
    }

    render() {
        const isUserLoggedIn = AuthenticationService.isUserLoggedIn();

        if (isUserLoggedIn) {
            let userName = AuthenticationService.getLoggedInUserName()

            return (
                <>
                    {this.state.showSmallMenu &&
                        <header id='header'>
                            <nav className="navbar navbar-expand-md navbar-dark bg-dark no-print">
                                <ul className="altMenu">
                                    {<li id="cross" onClick={this.toogleAltMenu}><ImCross /></li>}

                                </ul>
                                <ul className="navbar-nav">
                                    {AuthenticationService.haveAuthorization("READ_ALERTAS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to='/alertas'>Alertas</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_SOCIEDADES") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/sociedades">Sociedades</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_CLIENTES") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/clientes">Proyectos</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_DOMICILIOS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/domicilios">Domicilios</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_DOCUMENTOS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/documentos">Documentos</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_PERSONAS_FISICAS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/personas-fisicas">Personas Fisicas</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_CFDIS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/cfdis">CFDIs</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_HISTORIA") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/historial">Historial</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_BUSQUEDA") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/busqueda"><span className="icon-search"></span></Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_USUARIOS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/users">Usuarios</Link></li>}
                                </ul>
                                <ul className="navbar-nav nav-user">
                                    {!isUserLoggedIn && <li><Link className="nav-link" to="/login">Ingresar</Link></li>}
                                    {isUserLoggedIn && <li><Link className="nav-link logged" to="/logout" onClick={AuthenticationService.logout}><span className="icon-user"></span> {userName} <span className="icon-close"></span></Link></li>}
                                </ul>

                            </nav>
                        </header>
                    }
                    {!this.state.showSmallMenu &&
                        <header id='altHeader'>
                            <nav className="navbar navbar-expand-md navbar-dark bg-dark no-print">
                                <ul className="altMenu">
                                    {<li id="hamburguer" onClick={this.toogleAltMenu}><ImMenu /></li>}
                                </ul>
                                <ul className="navbar-nav">
                                    {AuthenticationService.haveAuthorization("READ_ALERTAS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to='/alertas'>Alertas</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_SOCIEDADES") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/sociedades">Sociedades</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_CLIENTES") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/clientes">Proyectos</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_DOMICILIOS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/domicilios">Domicilios</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_DOCUMENTOS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/documentos">Documentos</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_PERSONAS_FISICAS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/personas-fisicas">Personas Fisicas</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_CFDIS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/cfdis">CFDIs</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_HISTORIA") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/historial">Historial</Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_BUSQUEDA") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/busqueda"><span className="icon-search"></span></Link></li>}
                                    {AuthenticationService.haveAuthorization("READ_USUARIOS") && <li onClick={this.toogleAltMenu}><Link className="nav-link" to="/users">Usuarios</Link></li>}
                                </ul>
                                <ul className="navbar-nav nav-user">
                                    {!isUserLoggedIn && <li><Link className="nav-link" to="/login">Ingresar</Link></li>}
                                    {isUserLoggedIn && <li><Link className="nav-link logged" to="/logout" onClick={AuthenticationService.logout}><span className="icon-user"></span> {userName} <span className="icon-close"></span></Link></li>}
                                </ul>

                            </nav>
                        </header>
                    }
                </>
            )
        } else {
            return ('')
        }

    }
}

export default HeaderComponent